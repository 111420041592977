import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import Text, { TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IntegrationCallout } from "@components/IntegrationCallout";
import useSlackIntegration from "@rollup-api/hooks/useSlackIntegration";
import { LinkChannelDto, SlackChannel } from "@rollup-api/models/integrations/slack.model";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";
import { rollupClient } from "src/core/api";

import ConnectIntegrationCard from "../ConnectIntegrationCard";

import SlackSettingsAddNewLink from "./SlackSettingsAddNewLink";
import SlackSubscriptionsList from "./SlackSubscriptionsList";

import "./SlackSettingsInternal.scss";

const SlackSettingsInternal = () => {
  const { integrationId } = useParams();
  const [channelsList, setChannelsList] = useState<Array<SlackChannel>>([]);
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const slackIntegrationConfig: IIntegration | undefined = appStore.settingsModel?.integrationsMap.get(integrationId || "");
  const { linkChannel, subscriptions, unlinkChannel, updateChannelEvents } = useSlackIntegration(slackIntegrationConfig);

  useEffect(() => {
    if (slackIntegrationConfig?.isAuthorized) {
      setLoading(true);
      rollupClient.integrations
        .listSlackChannels()
        .then(response => setChannelsList(response.data?.filter(c => !c.is_archived) || []))
        .finally(() => setLoading(false));
    }
  }, [slackIntegrationConfig?.isAuthorized]);

  if (!slackIntegrationConfig) {
    return null;
  }

  const toggleAdd = () => setAdd(!add);

  const handleCreateSubscription = (channelLink: LinkChannelDto) => {
    linkChannel(channelLink.id, channelLink.entityTypes);
    setAdd(false);
  };

  return (
    <>
      <ConnectIntegrationCard integration={slackIntegrationConfig} description="Send notifications from channels to Rollup models" />
      {slackIntegrationConfig.connection && (
        <>
          <IntegrationCallout integration={slackIntegrationConfig.connection} />
          <div className="slack-integration--channel-subscription">
            <div className="slack-integration--title">
              <Text variant={TextVariant.H2}>Channel notification subscription</Text>
              <Button intent={Intent.PRIMARY} large onClick={toggleAdd} e2eIdentifiers="add-sibscription" icon="add">
                Create subscription
              </Button>
            </div>
            {add && (
              <SlackSettingsAddNewLink
                subscriptions={subscriptions}
                onSave={handleCreateSubscription}
                onCancel={toggleAdd}
                loading={loading}
                channels={channelsList}
              />
            )}
            <SlackSubscriptionsList
              onUpdate={updateChannelEvents}
              onUnlink={unlinkChannel}
              subscriptions={subscriptions}
              channels={channelsList}
            />
          </div>
        </>
      )}
    </>
  );
};

export default observer(SlackSettingsInternal);
