import { observer } from "mobx-react";

import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

import { LinkedBlockButton } from "../../LinkedBlockButton";

import styles from "./LinkedBlockCell.module.scss";

type Props = {
  requirementBlock: IRequirementBlock;
};

const LinkedBlockCell = (props: Props) => {
  const { requirementBlock } = props;

  return (
    <div className={styles.linkedBlockCell}>
      <LinkedBlockButton className={styles.linkedBlockCellButton} noneClassName={styles.linkedBlockCellNone} reqBlock={requirementBlock} />
    </div>
  );
};

export default observer(LinkedBlockCell);
