import { Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { ExecutionTypeIcon } from "@components/Analysis";
import { TitledTooltip } from "@components/Shared";
import { useAppNavigate } from "@router/hooks";
import { IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";

import styles from "./AnalysisOutputTag.module.scss";

interface IBlockTagProps {
  analysisOutput: IAnalysisOutput;
  showValue?: boolean;
  className?: string;
  labelClassName?: string;
}

const AnalysisOutputTag = (props: IBlockTagProps) => {
  const { analysisOutput, showValue, className, labelClassName } = props;
  const { analysis } = analysisOutput;
  const { navigateToCodeBlock } = useAppNavigate();

  const handleBlockClicked = () => {
    if (analysis) {
      navigateToCodeBlock(analysis.id);
    }
  };

  const renderLabel = () => {
    if (showValue) {
      return `${analysisOutput.value} ${analysisOutput.unit}`;
    }

    const codeBlockLabel = analysis ? `${analysis.label}:` : "";

    return `${codeBlockLabel}${analysisOutput.label}`;
  };

  const tooltipMessage = showValue ? "" : `Value: ${analysisOutput.value} ${analysisOutput.unit}`;

  return (
    <TitledTooltip placement={Position.BOTTOM} title={analysis?.label} content={tooltipMessage}>
      <div className={className} onClick={handleBlockClicked}>
        {analysis && <ExecutionTypeIcon className={styles.analysisOutputTagIcon} type={analysis.type} />}
        <span className={labelClassName}>{renderLabel()}</span>
      </div>
    </TitledTooltip>
  );
};

export default observer(AnalysisOutputTag);
