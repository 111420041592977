import { AgGridReact } from "ag-grid-react";
import { cast, getParentOfType, IAnyModelType, Instance, types } from "mobx-state-tree";

import { AnnotationMarkupManager } from "@components/Modeling/ModelingFrame/HoopsViewer/UI/HoopsCustomOperators/AnnotationOperator/AnnotationMarkupManager";
import { TSearchResultItem } from "@components/SearchResults/SearchResults";
import { trackSegmentEvent } from "src/lib/Segment";

export enum CustomOperatorNames {
  AnnotationOperator = "Smart Annotation Operator",
  ContextMenuOperator = "Context Menu Operator",
  KeyPressListenerOperator = "Key Press Listener Operator",
}

export const CustomOperators = types.model("CustomOperators", {
  hoopsId: types.number,
  operatorName: types.string,
});

export const HoopsNodeStore = types
  .model("HoopsNode", {
    id: types.identifierNumber,
    label: types.maybeNull(types.frozen<string>()),
    visible: types.optional(types.boolean, true),
    filteredIn: types.optional(types.boolean, true),
    isolated: types.optional(types.boolean, false),
    transparencySet: types.optional(types.boolean, false),
    parentNode: types.maybeNull(types.safeReference(types.late((): IAnyModelType => HoopsNodeStore))),
    children: types.maybeNull(types.array(types.safeReference(types.late((): IAnyModelType => HoopsNodeStore)))),
  })
  .views(self => ({
    get isRoot() {
      return !self.parentNode;
    },
    get hasChildren() {
      return !!self.children?.length;
    },
    get path() {
      const label = self.label ?? `Node ${self.id}`;
      if (self.parentNode) {
        return [...self.parentNode.path, label];
      } else {
        return [label];
      }
    },
    get pathIds() {
      const pathId = self.id;
      if (self.parentNode) {
        return [...self.parentNode.pathIds, pathId];
      } else {
        return [pathId];
      }
    },
    get pathNodes() {
      if (self.parentNode) {
        return [...self.parentNode.pathNodes, self];
      } else {
        return [self];
      }
    },
  }))
  .actions(self => ({
    setParent(parentNode: IHoopsNode) {
      self.parentNode = parentNode;
    },
    setChildren(children: number[]) {
      self.children = cast(children);
    },
    setNodeVisibility(visible: boolean) {
      parentViewer(self)?.setNodeVisibility(self as IHoopsNode, visible);
    },
    toggleVisibility() {
      this.setNodeVisibility(!self.visible);
    },
    setSelected() {
      parentViewer(self)?.selectNode(self as IHoopsNode);
    },
    setIsolated(isolated: boolean) {
      self.isolated = isolated;
    },
  }));

export const HoopsViewerStore = types
  .model("HoopsViewer", {
    viewerInstance: types.frozen<Communicator.WebViewer>(),
    nodes: types.array(HoopsNodeStore),
    nodeCount: 0,
    drawMode: types.optional(types.number, Communicator.DrawMode.WireframeOnShaded),
    presetView: types.optional(types.number, -1),
    parentNode: types.maybeNull(types.safeReference(HoopsNodeStore)),
    selectedNode: types.maybeNull(types.safeReference(HoopsNodeStore)),
    allExpanded: types.optional(types.boolean, false),
    allCollapsed: types.optional(types.boolean, false),
    isolatedNode: types.maybeNull(types.safeReference(HoopsNodeStore)),
    customOperators: types.array(CustomOperators),
    activeCustomOperatorId: types.maybeNull(types.number),
    standardOperatorIds: types.array(types.number),
    annotationsVisible: types.optional(types.boolean, true),
    annotationOperatorActive: types.optional(types.boolean, false),
    previousViewId: types.maybeNull(types.string),
    activeSavedViewId: types.maybeNull(types.string),
    isExplodeActive: types.optional(types.boolean, false),
    annotationMarkupManager: types.maybeNull(types.frozen<AnnotationMarkupManager>()),
    hack_hasAnyRecreatedAnnotationViewButtonClickedBefore: types.optional(types.boolean, false),
    areMeasurementsHidden: types.optional(types.boolean, false),
    isAnnotationModeActive: types.optional(types.boolean, false),
    isContextMenuOpen: types.optional(types.boolean, false),
    contextMenuX: types.optional(types.number, 0),
    contextMenuY: types.optional(types.number, 0),
    contextMenuNode: types.maybeNull(types.safeReference(HoopsNodeStore)),
  })
  .actions(self => ({
    getNodeById(id: number) {
      return self.nodes?.find(n => n.id === id);
    },
    unisolateNodes() {
      // show all nodes
      self.isolatedNode = null;
      self.nodes?.forEach(n => (n.visible = true));
      self.nodes?.forEach(n => n.setIsolated(false));
      self.viewerInstance?.model?.setNodesVisibility(
        self.nodes.map(n => n.id),
        true
      );
    },
  }))
  .actions(self => ({
    activateContextMenu(nodeId: number, x: number, y: number) {
      const _contextMenuNode = self.getNodeById(nodeId);
      if (_contextMenuNode) {
        self.contextMenuNode = _contextMenuNode;
        self.contextMenuX = x;
        self.contextMenuY = y;
        self.isContextMenuOpen = true;
      }
    },
    deactivateContextMenu() {
      self.isContextMenuOpen = false;
      self.selectedNode = null;
    },
  }))
  .actions(self => ({
    setActiveCustomOperator(operatorId: number) {
      if (operatorId !== 0) {
        self.activeCustomOperatorId = operatorId;
      } else {
        self.activeCustomOperatorId = null;
      }
    },
    isolateNode(node: IHoopsNode) {
      // show all nodes
      self.unisolateNodes();
      // isolate this node
      node.setIsolated(true);
      self.isolatedNode = node;
      self.viewerInstance?.view?.isolateNodes([node.id]);
    },
    zoomToNode(node: IHoopsNode) {
      self.viewerInstance?.view?.fitNodes([node.id]);
    },
  }))
  .actions(self => ({
    setHack_hasAnyRecreatedAnnotationViewButtonClickedBefore(value: boolean) {
      self.hack_hasAnyRecreatedAnnotationViewButtonClickedBefore = value;
    },
    setAnnotationMarkupManager(markupManager: AnnotationMarkupManager) {
      self.annotationMarkupManager = markupManager;
    },
    setAreMeasurementsHidden(areMeasurementsHidden: boolean) {
      self.areMeasurementsHidden = areMeasurementsHidden;
    },
    setIsExplodeActive(isExplodeActive: boolean) {
      self.isExplodeActive = isExplodeActive;
    },
    setPreviousViewId(viewId: string) {
      self.previousViewId = viewId;
    },
    setActiveSavedViewId(viewId: string | null) {
      self.activeSavedViewId = viewId;
    },
    activateAnnotationMode(viewer: Communicator.WebViewer, annotationOperatorId: number) {
      trackSegmentEvent("annotation:activate");
      // empty the operators in the operator manager
      viewer.operatorManager.clear();
      // push the navigate operator to the operator manager followed by the annotation operator
      viewer.operatorManager.push(Communicator.OperatorId.Navigate);
      viewer.operatorManager.push(annotationOperatorId);

      self.setActiveCustomOperator(annotationOperatorId);
      self.isAnnotationModeActive = true;
    },
    deactivateAnnotationMode(viewer: Communicator.WebViewer) {
      // empty the operators in the operator manager
      viewer.operatorManager.clear();
      // reset all the operators to the standard ones
      self.standardOperatorIds.forEach(op => viewer.operatorManager.push(op));

      self.setActiveCustomOperator(0);
      self.isAnnotationModeActive = false;
    },
    setAnnotationsVisible(visible: boolean) {
      self.annotationsVisible = visible;
    },
    addCustomOperator(operatorId: number, operatorName: string) {
      self.customOperators.push({ hoopsId: operatorId, operatorName });
    },
    getCustomOperatorIdByName(name: string) {
      const operatorId = self.customOperators.find(o => o.operatorName === name)?.hoopsId;
      return operatorId ?? null;
    },
    setStandardOperatorIds(ids: number[]) {
      self.standardOperatorIds = cast(ids);
    },
    toggleNodeIsolation(node: IHoopsNode) {
      if (node.isolated) {
        self.unisolateNodes();
      } else {
        self.isolateNode(node);
      }
    },
    subIsolateNode(node: IHoopsNode) {
      self.nodes?.forEach(n => n.setIsolated(false));
      node.setIsolated(true);
      self.viewerInstance?.view?.isolateNodes([node.id], 0);
    },
    toggleNodeVisibility(node: IHoopsNode) {
      node.toggleVisibility();
      self.viewerInstance?.model?.setNodesVisibility([node.id], node.visible);
    },
    setNodeVisibility(node: IHoopsNode, visible: boolean) {
      node.visible = visible;
      self.viewerInstance?.model?.setNodesVisibility([node.id], visible);
    },
    selectNode(node: IHoopsNode) {
      self.selectedNode = node;
      self.viewerInstance?.selectionManager.selectNode(node.id);
    },
    selectNodeById(id: number, navigate?: boolean) {
      self.selectedNode = self.nodes?.find(n => n.id === id);
      if (navigate) {
        self.viewerInstance?.selectionManager.selectNode(id);
      }
    },
    clearSelectedNode() {
      if (self.selectedNode) {
        self.selectedNode = undefined;
        self.viewerInstance.selectionManager.clear();
      }
    },
    setNodeVisibilityById(nodeId: number, visible: boolean) {
      const node: IHoopsNode = self.nodes?.find(n => n.id === nodeId) as IHoopsNode;
      this.setNodeVisibility(node, visible);
    },
    toggleNodeVisibilityById(nodeId: number) {
      const node: IHoopsNode = self.nodes?.find(n => n.id === nodeId) as IHoopsNode;
      this.toggleNodeVisibility(node);
    },
    toggleNodeTransparency(node: IHoopsNode) {
      if (node.transparencySet) {
        self.viewerInstance?.model?.resetNodesOpacity([node.id]);
        node.transparencySet = false;
      } else {
        self.viewerInstance?.model?.setNodesOpacity([node.id], 0.5);
        node.transparencySet = true;
      }
    },
    setRenderMode(drawMode: Communicator.DrawMode) {
      trackSegmentEvent("hoops:draw-mode-update", { drawMode });
      self.drawMode = drawMode;
    },
    setViewOrientation(presetView: Communicator.ViewOrientation | -1) {
      trackSegmentEvent("hoops:orientation-update", { presetView });
      self.presetView = presetView;
    },
    showAllNodes() {
      self.isolatedNode = null;
      self.nodes?.forEach(n => (n.visible = true));
      self.nodes?.forEach(n => n.setIsolated(false));
      self.viewerInstance?.model?.setNodesVisibility(
        self.nodes.map(n => n.id),
        true
      );
    },
    isolateNodeById(nodeId: number) {
      const node: IHoopsNode = self.nodes?.find(n => n.id === nodeId) as IHoopsNode;
      this.toggleNodeIsolation(node);
    },
    updateTree() {
      const model = self.viewerInstance?.model;
      if (!model) {
        return false;
      }

      const nodes = new Array<IHoopsNode>();
      const rootNodeId = model.getAbsoluteRootNode();
      const parentNode = HoopsNodeStore.create({
        id: rootNodeId,
        label: model.getNodeName(rootNodeId),
        children: model.getNodeChildren(rootNodeId),
      });

      if (!parentNode) {
        self.nodes = cast(nodes);
        return false;
      }
      nodes.push(parentNode);

      // Recursively fill the tree
      const fillNodeChildren = (node: IHoopsNode) => {
        const children = model.getNodeChildren(node.id);
        if (children?.length) {
          for (const id of children) {
            const childNode = HoopsNodeStore.create({
              id,
              label: model.getNodeName(id),
            });
            nodes.push(childNode);
            childNode.setParent(node);
            fillNodeChildren(childNode);
          }
          node.setChildren(children);
        }
      };

      fillNodeChildren(parentNode);

      self.parentNode = parentNode;
      self.nodeCount = nodes.length;
      self.nodes = cast(nodes);
      return true;
    },
    clearTree() {
      self.parentNode = undefined;
      self.nodes = cast([]);
    },
    filterTreeForText(text: string): TSearchResultItem[] {
      if (!text) {
        return [];
      }

      const nodes = self.nodes?.filter(n => n.label?.toLowerCase().includes(text.toLowerCase()));
      return nodes.map(node => ({ path: node.path, id: `${node.id}`, label: `${node.label}` }));
    },
    setAllExpanded(expanded: boolean, tableRef: React.MutableRefObject<AgGridReact<IHoopsNode> | null>) {
      self.allExpanded = expanded;
      if (expanded) {
        tableRef?.current?.api.expandAll();
        this.setAllCollapsed(false, tableRef);
      }
    },
    setAllCollapsed(collapsed: boolean, tableRef: React.MutableRefObject<AgGridReact<IHoopsNode> | null>) {
      self.allCollapsed = collapsed;
      if (collapsed) {
        tableRef?.current?.api.collapseAll();
        this.setAllExpanded(false, tableRef);
      }
    },
  }))
  .views(self => ({
    get hasValidTree() {
      return !!(self.parentNode && self.viewerInstance);
    },
    get customOperatorIds() {
      return self.customOperators.map(o => o.hoopsId);
    },
  }));

export function parentViewer(node: any): IHoopsViewer | undefined {
  try {
    return getParentOfType(node, HoopsViewerStore) as IHoopsViewer;
  } catch (e) {
    return undefined;
  }
}

export interface IHoopsViewer extends Instance<typeof HoopsViewerStore> {}

export interface IHoopsNode extends Instance<typeof HoopsNodeStore> {}
