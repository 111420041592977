import { observer } from "mobx-react";

import { Button } from "@components/Button";

interface ICollapseButtonProps {
  isExpanded: boolean;
  onClick(): void;
}

const CollapseButton = (props: ICollapseButtonProps) => {
  const { isExpanded, onClick } = props;

  return (
    <Button onClick={onClick} e2eIdentifiers={isExpanded ? "collapse-all" : "expand-all"} underline minimal small>
      {isExpanded ? "Collapse" : "Expand"}
    </Button>
  );
};

export default observer(CollapseButton);
