import classNames from "classnames";
import { observer } from "mobx-react";

import { EmojiContainer } from "@components/EmojiContainer";
import { Icon } from "@components/Icon";
import { IIcon } from "@rollup-types/icons";
import { StoreType } from "@store/types";
import { isCustomIcon, isIcon } from "@utilities/Icon";

import styles from "./EventParentIcon.module.scss";

interface IEventParentIconProps {
  type: StoreType;
  icon: IIcon | string;
}

const EventParentIcon = (props: IEventParentIconProps) => {
  const { type, icon } = props;
  if (isIcon(icon)) {
    return <Icon className={classNames({ [styles.eventParentIconCustom]: isCustomIcon(icon) })} icon={icon} />;
  }

  switch (type) {
    case StoreType.ReportBlock:
    case StoreType.Report:
      return <EmojiContainer className={styles.eventParentIconEmoji} emoji={icon} />;
    default:
      return icon;
  }
};

export default observer(EventParentIcon);
