import { ReactNode, useEffect, useState } from "react";
import { InputGroup, Intent, PopoverPosition, Tooltip } from "@blueprintjs/core";
import { useInputHandlers } from "@hooks/useInputHandlers";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import classNames from "classnames";
import { observer } from "mobx-react";

import PropertyDefinitionDescription from "@components/Block/PropertyDefinitionDescription";
import { Button } from "@components/Button";
import { EntityAnnotationListPopover } from "@components/EntityAnnotationListPopover";
import { FormGroup } from "@components/FormGroup";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { StoreType } from "@store/types";
import { getValidationErrorMsg } from "@utilities";

import styles from "./BlockPropertyListRow.module.scss";

interface IBlockPropertyListRowProps {
  propertyInstance: IPropertyInstance;
  block: IBlock;
  isEditingDescription: boolean;
  pinnedMode?: boolean;
  showDescription?: boolean;
  onStartEditing(): void;
  onFinishEditing(): void;
  renderInput(): ReactNode;
}

const BlockPropertyListRow = (props: IBlockPropertyListRowProps) => {
  const { propertyInstance, block, pinnedMode } = props;
  const { isEditingDescription } = props;
  const { onStartEditing, onFinishEditing, renderInput } = props;
  const [isCommentPopoverOpen, setIsCommentPopoverOpen] = useState(false);
  const { propertyDefinition, annotationList, lastFocusedCommentId } = propertyInstance;
  const { annotations, isPopulatingAnnotations } = annotationList;
  const hasComments = annotations.length > 0 && annotations.some(annotation => !!annotation.commentCount);
  const [annotationId] = useSearchParam("annId");
  const [commentId] = useSearchParam("commId");
  const hasValidAnnotation = !!annotationId && annotationList.hasAnnotation(annotationId);
  const hasFocusedOnComment = commentId === lastFocusedCommentId;

  useEffect(() => {
    if (hasValidAnnotation && !isPopulatingAnnotations && !hasFocusedOnComment) {
      setIsCommentPopoverOpen(true);
    }
  }, [isPopulatingAnnotations, hasValidAnnotation, hasFocusedOnComment]);

  const handleCommit = (value: string) => {
    const saveText = value.replace(/ +$/, "");
    propertyInstance.propertyDefinition?.setLabel(saveText);
  };

  const { value, error, ref, handleChange, handleKeyDown, handleBlur } = useInputHandlers({
    value: propertyDefinition?.label ?? "",
    getValidationErrorMessage: value => getValidationErrorMsg(value, propertyInstance),
    onCommit: handleCommit,
  });

  if (!propertyDefinition) {
    return null;
  }

  const handleDescriptionChange = (value: string) => {
    if (appStore.userModel) {
      propertyDefinition.setDescription(value, appStore.userModel.id);
    }
  };
  const descriptionLastUpdatedBy = propertyDefinition.descriptionLastUpdatedBy
    ? appStore.orgModel.info.userWithId(propertyDefinition.descriptionLastUpdatedBy)
    : undefined;

  const showDescription = (props.showDescription && propertyDefinition.description) || isEditingDescription;

  // TODO extract to separate component (https://linear.app/rollup/issue/ENG-3167/extract-component-for-property-label)
  const renderLabel = () => {
    if (pinnedMode) {
      return (
        <div className={styles.blockPropertyListRowPinnedLabel}>
          <Tooltip content={propertyInstance.path} position="top" hoverOpenDelay={500}>
            <span>
              {block.label}:{propertyDefinition.label}
            </span>
          </Tooltip>
        </div>
      );
    }

    const handleNameClick = () => {
      // need to close the popover first, otherwise focus won't work
      if (isCommentPopoverOpen) {
        setIsCommentPopoverOpen(false);
      }
      ref.current?.focus();
    };

    return (
      <EntityAnnotationListPopover
        className={styles.blockPropertyListRowAnnotationPopoverTarget}
        popoverClassName={styles.blockPropertyListRowAnnotationPopover}
        entityId={propertyInstance.id}
        entityIdsWithComments={block.orderedPropertyInstances.filter(pi => pi.hasComment).map(pi => pi.id)}
        isOpen={isCommentPopoverOpen}
        popoverProps={{ position: PopoverPosition.BOTTOM_LEFT, modifiers: { offset: { enabled: true, options: { offset: [0, 10] } } } }}
        annotationList={annotationList}
        storeType={StoreType.PropertyInstance}
        lastFocusedCommentId={propertyInstance.lastFocusedCommentId}
        onFocusComment={propertyInstance.setLastFocusedCommentId}
        onInteraction={setIsCommentPopoverOpen}
        disableOpenOnTargetClick
      >
        <div className={styles.blockPropertyListRowPopoverContent}>
          <FormGroup helperText={error} intent={Intent.DANGER}>
            <InputGroup
              inputRef={ref}
              className={classNames(styles.blockPropertyListRowInput, {
                [styles.blockPropertyListRowInputHighlighted]: isCommentPopoverOpen || hasComments,
              })}
              maxLength={36}
              placeholder="Name"
              value={value}
              intent={propertyDefinition.labelIsValid && !error ? Intent.NONE : Intent.DANGER} // TODO: automate validation
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={() => appStore.env.setActivePropertyInstance(propertyInstance)}
              onBlur={handleBlur}
              onClick={handleNameClick}
            />
          </FormGroup>
          {/* No need to show comment button if the prop has comments. The popover will show on hover anyway */}
          {!hasComments && (
            <Button
              onClick={() => setIsCommentPopoverOpen(true)}
              icon="comment"
              className={styles.blockPropertyListRowCommentButton}
              e2eIdentifiers="open-comments-popover"
              tooltip="Add comment"
              tooltipProps={{ disabled: isCommentPopoverOpen }}
              minimal
            />
          )}
        </div>
      </EntityAnnotationListPopover>
    );
  };

  const renderRightButtons = () => {
    return (
      <div className={styles.blockPropertyListRowRightButtonsContainer}>
        <Button onClick={onStartEditing} tooltip="Edit description" icon="citation" e2eIdentifiers="show-description" minimal />
        <Button
          onClick={() => appStore.env.showPropertyDetailsViewer(propertyInstance)}
          tooltip="Show property details"
          icon="detection"
          e2eIdentifiers="show-property-details"
          minimal
        />
      </div>
    );
  };

  return (
    <div className={styles.blockPropertyListRow}>
      {renderLabel()}
      <div className={styles.blockPropertyListRowInputWrapper}>
        <div className={styles.blockPropertyListRowSmartInput}>
          {renderInput()}
          {!pinnedMode && renderRightButtons()}
        </div>
        {showDescription && (
          <PropertyDefinitionDescription
            description={propertyDefinition.description}
            lastModifiedBy={descriptionLastUpdatedBy}
            isEditing={isEditingDescription}
            onStartEditing={onStartEditing}
            onFinishEditing={onFinishEditing}
            onChange={handleDescriptionChange}
          />
        )}
      </div>
    </div>
  );
};

export default observer(BlockPropertyListRow);
