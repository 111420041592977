import { useState } from "react";
import { createGrid } from "ag-grid-community";

import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";
import { sanitizeFilename } from "@utilities/File";
import { isDefined } from "@utilities/TypeGuards";

import { defaultColDef, ERequirementsTableColumn } from "./constants";
import { mapReqTableColumnToColumnDef } from "./useReqTableColumnDefs";

const useReqTableExport = (requirementsPage: IRequirementsPage) => {
  const [exporting, setExporting] = useState(false);

  const handleExport = async () => {
    const fileName = `${sanitizeFilename(requirementsPage.label)}.csv`;

    if (appStore.env.requirementsTableGridApi) {
      const columnKeys = appStore.env.requirementsTableGridApi.getColumns()?.filter(column => {
        const colId = column.getColId();
        return colId !== ERequirementsTableColumn.ADD_NEW && colId !== ERequirementsTableColumn.ACTIONS;
      });
      appStore.env.requirementsTableGridApi.exportDataAsCsv({ fileName, columnKeys });
      return;
    }

    setExporting(true);
    if (!requirementsPage.columns.length) {
      await requirementsPage.loadColumns();
    }
    const table = createGrid<IRequirementBlock>(document.createElement("div"), {
      defaultColDef,
      columnDefs: requirementsPage.orderedColumns
        .map(column => mapReqTableColumnToColumnDef({ column, reqPage: requirementsPage, simplified: true }))
        .filter(isDefined),
      rowData: requirementsPage.validatedBlocks,
    });
    table.exportDataAsCsv({ fileName });
    setExporting(false);
  };

  return {
    handleExport,
    exporting,
  };
};

export default useReqTableExport;
