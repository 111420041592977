import assignIn from "lodash/assignIn";
import { IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { TIPTAP_EMPTY_CONTENT } from "@components/Reports/Editor/constants";
import { CreatedReportBlockDto, CreateReportBlocksDto, DeleteReportBlockDto, ReportBlockUpdateDto } from "@rollup-api/models/reportBlocks";
import { RollupEditorType } from "@rollup-types/editor";
import { AnnotationListStore } from "@store/AnnotationListStore";
import appStore from "@store/AppStore";
import { IReportBlockMetadata, isChecklistMetadata, ReportBlockMetadata } from "@store/Report/Report.types";
import { ReportStore } from "@store/ReportsStore";
import { StoreType } from "@store/types";
import { calculateOrderListItemNumber } from "@utilities/ReportBlocks";
import { rollupClient } from "src/core/api";

export const ReportBlockStore = types
  .model(StoreType.ReportBlock, {
    id: types.identifier,
    label: types.string,
    metadata: types.maybe(ReportBlockMetadata),
    processedLabel: types.optional(types.string, ""),
    type: types.enumeration("DataType", [...Object.values(RollupEditorType)]),
    parentReport: types.safeReference(types.late((): IAnyModelType => ReportStore)),
    orderIndex: types.optional(types.number, 0),
    annotationList: types.optional(AnnotationListStore, {}),
  })
  .views(self => ({
    getText(focused: boolean) {
      if (focused) {
        return self.label;
      } else return self.processedLabel;
    },
    orderListNumber(): number {
      return calculateOrderListItemNumber(self as IReportBlock);
    },
    get checked(): boolean {
      return isChecklistMetadata(self.metadata) && self.metadata.checked;
    },
    get hasComment(): boolean {
      return self.annotationList.hasComment;
    },
  }))
  .actions(self => ({
    patch(update: ReportBlockUpdateDto) {
      const invalidFields = ["id"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    updateText(text = "", notify = true) {
      if (!appStore.workspaceModel) {
        return;
      }

      const newText = text === TIPTAP_EMPTY_CONTENT ? "" : text;
      if (newText === self.label) {
        return;
      }

      self.label = newText;
      if (notify) {
        rollupClient.reportBlocks.update(self.id, { label: newText });
      }
    },
    setMetadata(metadata: IReportBlockMetadata, disableNotify?: boolean) {
      if (metadata.type !== self.type) {
        console.error(`Invalid metadata type: ${metadata.type} for block type: ${self.type}`);
        return;
      }
      self.metadata = metadata;
      if (!disableNotify) {
        rollupClient.reportBlocks.update(self.id, { metadata });
      }
    },
    setChecked(checked: boolean, disableNotify?: boolean) {
      this.setMetadata({ type: RollupEditorType.checklist, checked }, disableNotify);
    },
    updateType(type: RollupEditorType, notify = true) {
      if (type === self.type) {
        return;
      }

      self.type = type;

      if (notify) {
        rollupClient.reportBlocks.update(self.id, { type });
      }
    },
  }));

export interface IReportBlock extends Instance<typeof ReportBlockStore> {}
interface IReportBlockSnapshotIn extends SnapshotIn<typeof ReportBlockStore> {}
interface IReportBlockSnapshotOut extends SnapshotOut<typeof ReportBlockStore> {}
export interface IReportBlockMobxType extends IType<IReportBlockSnapshotIn, IReportBlockSnapshotOut, IReportBlock> {}

export function subscribeToReportBlockEvents(socket: Socket) {
  socket.on("createReportBlocks", (data: { workspaceId: string; createReportBlocksDto: CreateReportBlocksDto }) => {
    if (data.workspaceId === appStore.workspaceModel?.id && data.createReportBlocksDto?.reportBlocks?.length) {
      const { reportBlocks, destinationId } = data.createReportBlocksDto;
      const report = appStore.workspaceModel.reportsMap.get(reportBlocks[0].parentReportId);
      if (report) {
        const orderIndex = destinationId ? report.reportBlocks.findIndex(b => b?.id === destinationId) : undefined;
        appStore.workspaceModel.addReportBlocks(report, reportBlocks, orderIndex, false);
      }
    }
  });

  socket.on("createReportBlock", (data: { workspaceId: string; createReportBlockDto: CreatedReportBlockDto }) => {
    if (data.createReportBlockDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const { id, parentReportId, type, label, orderIndex, destinationId } = data.createReportBlockDto;
      const report = appStore.workspaceModel.reportsMap.get(parentReportId);
      if (report) {
        const destinationIndex = destinationId ? report.reportBlocks.findIndex(b => b?.id === destinationId) : undefined;
        appStore.workspaceModel.addReportBlock(report, type, label, destinationIndex ?? orderIndex, id, false);
      }
    }
  });

  socket.on("deleteReportBlock", (data: { workspaceId: string; id: string; deleteReportBlockDto: DeleteReportBlockDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const reportBlock = appStore.workspaceModel.reportBlocksMap.get(data.id);
      if (reportBlock) {
        appStore.workspaceModel.deleteReportBlock(reportBlock, false);
      }
    }
  });

  socket.on("updateReportBlock", (data: { workspaceId: string; id: string; updateReportBlockDto: ReportBlockUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const reportBlock = appStore.workspaceModel.reportBlocksMap.get(data.id);
      if (reportBlock) {
        reportBlock?.patch(data.updateReportBlockDto);
      }
    }
  });
}
