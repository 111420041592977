import { forwardRef, ReactElement } from "react";
import { Classes } from "@blueprintjs/core";
import type { DraggableSyntheticListeners } from "@dnd-kit/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import "./item.scss";

export type TItemRendererArgs = {
  selected?: boolean;
  dragging?: boolean;
  isOver?: boolean;
  listeners: DraggableSyntheticListeners;
  handleProps: any;
  value: string;
  index?: number;
  onSelect?: () => void;
};

export type TItemRendererCallBack<T extends object | undefined = {}> = (args: T & TItemRendererArgs) => ReactElement | null;

export interface Props {
  dragOverlay?: boolean;
  dragging?: boolean;
  isOver?: boolean;
  newItemAbove?: boolean;
  handleProps?: any;
  listeners?: DraggableSyntheticListeners;
  value: any;
  selected?: boolean;
  itemComponent: TItemRendererCallBack;
  itemComponentCustomProps?: any;
}

const Item = forwardRef(function Item(props: Props, ref: any) {
  const { newItemAbove, isOver, dragOverlay, dragging, listeners, handleProps } = props;
  const { selected, itemComponent: ItemComponent, itemComponentCustomProps, value } = props;

  return (
    <div data-id={value} className="rollup-dnd-item--wrapper" ref={ref}>
      <div
        className={classNames("rollup-dnd-item", {
          [Classes.DARK]: appStore.env.themeIsDark,
          ["rollup-dnd-item--dragging"]: dragging,
          ["rollup-dnd-item--over-above"]: isOver && newItemAbove,
          ["rollup-dnd-item--over-below"]: isOver && !newItemAbove,
          ["rollup-dnd-item--dragOverlay"]: dragOverlay,
        })}
      >
        <ItemComponent
          selected={selected}
          dragging={dragging}
          isOver={isOver}
          listeners={listeners}
          handleProps={handleProps}
          value={value}
          {...itemComponentCustomProps}
        />
      </div>
    </div>
  );
});

Item.displayName = "Item";

export default observer(Item);
