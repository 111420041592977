import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react-lite";

import { DocumentIcon } from "@components/DocumentIcon";
import { MenuItem } from "@components/MenuItem";
import { ReferenceLinkData } from "@rollup-api/models/references/references.model";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";
import { EntityType } from "@store/types";

import InternalAttachmentFilterableMenu from "./InternalAttachmentFilterableMenu";

interface IAddAttachmentMenuRequirementsProps {
  onAttach(data: ReferenceLinkData): void;
}

const RequirementsFilterableMenu = (props: IAddAttachmentMenuRequirementsProps) => {
  const workspace = useWorkspace();
  const onAttachReqPage = (reqPage: IRequirementsPage) => {
    props.onAttach({ entityId: reqPage.id, entityType: EntityType.RequirementsDocument, label: reqPage.label, workspaceId: workspace.id });
  };

  return (
    <MenuItem
      icon={<DocumentIcon entityType={EntityType.RequirementsDocument} />}
      text="Add from Requirements"
      e2eIdentifiers="requirements"
    >
      <InternalAttachmentFilterableMenu<IRequirementsPage>
        itemName="Requirements Document"
        collection={workspace.requirementsModule.values}
        placeholder="Search for requirements documents"
        onAttachDocument={onAttachReqPage}
      />
    </MenuItem>
  );
};

export default observer(RequirementsFilterableMenu);
