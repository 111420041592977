import { useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { PopupMenu } from "@components/Shared/PopupMenu";
import { RequirementBlockType } from "@rollup-api/models";

import { RequirementAddButtonMenu } from "../RequirementsDocumentView/RequirementAddButtonMenu";

import "./RequirementPageNewRowButton.module.scss";
import styles from "./RequirementPageNewRowButton.module.scss";
interface IRequirementPageNewRowButtonProps {
  className?: string;
  onAddRequirementsBlock(type: RequirementBlockType): void;
}

const RequirementPageNewRowButton = (props: IRequirementPageNewRowButtonProps) => {
  const { className, onAddRequirementsBlock } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames(styles.requirementPageNewRowButton, className)}>
      <PopupMenu
        className={styles.requirementPageNewRowButtonPopupMenu}
        popoverProps={{ isOpen, onInteraction: setIsOpen }}
        buttonProps={{
          className: styles.requirementPageNewRowButtonButton,
          icon: "add",
          text: "New row",
          minimal: true,
          rightIcon: isOpen ? "caret-up" : "caret-down",
        }}
        content={<RequirementAddButtonMenu onAddBlock={onAddRequirementsBlock} />}
        e2eIdentifiers="new-row"
      />
    </div>
  );
};

export default observer(RequirementPageNewRowButton);
