import { useCallback, useEffect } from "react";
import { GridReadyEvent } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { catalogItemsTableGridOptions } from "@components/CatalogItems/Table/CatalogItemsTableGridOptions";
import { CatalogItemNode, CatalogItemTableColumn } from "@components/CatalogItems/Table/types";
import Table from "@components/Table";
import appStore from "@store/AppStore";

import { colDefs, defaultColDef } from "./pdmColumnDefs";
import { catalogItemNodeList, getActiveRowStyles, getDataPath } from "./utils";

import styles from "./CatalogItemsTable.module.scss";

// Tried moving this one into gridOptions, but it wasn't getting called
const handleGridReady = (e: GridReadyEvent<CatalogItemNode>) => {
  appStore.env.setCatalogItemTableGridApi(e.api);
  e.api.applyColumnState({ state: appStore.env.columnsSize.getCatalogItemsColumnsState });
  e.api.moveColumns([CatalogItemTableColumn.PartNumber], 2);
};
const handleColumnResize = (column: string, width: number) => {
  appStore.env.columnsSize.updateCatalogItemTableColumnSize(column as CatalogItemTableColumn, width);
};

interface ICatalogItemsTableProps {
  isExpanded: boolean;
}

const CatalogItemsTable = (props: ICatalogItemsTableProps) => {
  const { isExpanded } = props;
  const { showPdmThumbnail } = appStore.env;
  const handleResetApiState = useCallback(() => {
    appStore.env.setCatalogItemTableGridApi();
    appStore.env.setCatalogItemTableGridApi();
  }, []);

  useEffect(() => {
    if (isExpanded) {
      appStore.env.catalogItemTableGridApi?.expandAll();
    } else {
      appStore.env.catalogItemTableGridApi?.collapseAll();
    }
    appStore.env.catalogItemTableGridApi?.redrawRows();
  }, [isExpanded]);

  useEffect(() => {
    appStore.env.catalogItemTableGridApi?.resetRowHeights();
  }, [showPdmThumbnail]);

  useEffect(() => {
    return () => {
      handleResetApiState();
    };
  }, [handleResetApiState]);

  useEffect(() => {
    // force reset data to prevent UI glitch of duplicated rows
    appStore.env.catalogItemTableGridApi?.setGridOption("rowData", []);
    // assign fresh data after reset
    appStore.env.catalogItemTableGridApi?.setGridOption("rowData", catalogItemNodeList(appStore.orgModel.catalogItems.parentlessItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appStore.orgModel.catalogItems.catalogItemsMap.size,
    appStore.ui.showPdmFlatten,
    appStore.orgModel.catalogItems.catalogItemWorkspaceLevel,
  ]);

  return (
    <div
      className={classNames(styles.catalogItemsTableWrap, {
        [styles.catalogItemsTableEmpty]: !appStore.orgModel.catalogItems.catalogItems.length,
      })}
    >
      <style>{getActiveRowStyles()}</style>
      <Table<CatalogItemNode>
        rowData={catalogItemNodeList(appStore.orgModel.catalogItems.parentlessItems)}
        loading={false}
        suppressRowClickSelection
        columnDefs={colDefs}
        headerHeight={25}
        gridOptions={catalogItemsTableGridOptions}
        columnHoverHighlight
        onColumnResize={handleColumnResize}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        className={styles.catalogItemsTable}
        onGridReady={handleGridReady}
        disableAutoHeight
        getDataPath={getDataPath}
        onChartDestroyed={handleResetApiState}
        // If we enable this prop, PartNumberCellEditor stops working
        stopEditingWhenCellsLoseFocus={false}
        treeData
      />
    </div>
  );
};

export default observer(CatalogItemsTable);
