import assignIn from "lodash/assignIn";
import { flow, getParent, IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import {
  Analysis,
  AnalysisInput,
  AnalysisOutput,
  ExecutionResult,
  UpdateAnalysisInputDto,
  UpdateAnalysisOutputDto,
} from "@rollup-api/models/analysis";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";
import { updateAnalysis, updateExecutionResult } from "@rollup-api/utils";
import { AnalysisInputStore, IAnalysisInput } from "@store/Analysis/AnalysisInputStore";
import { IAnalysisModule } from "@store/Analysis/AnalysisModuleStore";
import { AnalysisOutputStore, IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";
import appStore from "@store/AppStore";
import { convertTimestamp, sortByCreated } from "@utilities";

export interface UpdateAnalysisDto {
  label?: string;
  type?: ExecutionEnvironmentType;
  code?: string; // TODO: rename together with BE to something that can also imply spreadsheet data
  autoRun?: boolean;
  executionEnvironmentId?: string;
  updatedAt?: number;
  updatedBy?: string;
}

export enum AnalysisType {
  CodeBlock = "codeBlock",
  Spreadsheet = "spreadsheet",
}

export const AnalysisStore = types
  .model("AnalysisStore", {
    id: types.identifier,
    label: types.optional(types.string, "Untitled analysis"),
    comment: types.optional(types.string, ""),
    type: types.optional(
      types.enumeration("ExecutionEnvironmentType", [...Object.values(ExecutionEnvironmentType)]),
      ExecutionEnvironmentType.Python
    ),
    inputs: types.array(types.safeReference(types.late((): IAnyModelType => AnalysisInputStore))),
    outputs: types.array(types.safeReference(types.late((): IAnyModelType => AnalysisOutputStore))),
    autoRun: types.optional(types.boolean, false), // also known as autoCalculate in the case of spreadsheets
    executionEnvironmentId: types.maybeNull(types.string),
    analysisType: types.optional(types.enumeration("AnalysisType", Object.values(AnalysisType)), AnalysisType.CodeBlock),
    latestExecution: types.maybeNull(types.frozen<ExecutionResult>()),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.optional(types.string, ""),
  })
  .volatile(() => ({
    // Code is not stored by default, only when users are viewing the block itself
    code: null as string | null,
  }))
  .actions(self => ({
    patch(update: UpdateAnalysisDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "inputs", "outputs"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setLabel(label: string) {
      self.label = label;
      updateAnalysis(self.id, { label });
    },
    setLatestExecution(result: ExecutionResult) {
      self.latestExecution = result;
    },
    setExecutionEnvironmentId(id: string | null) {
      self.executionEnvironmentId = id;
      updateAnalysis(self.id, { executionEnvironmentId: id });
    },
    setCode: flow(function* setCode(code: string, notify = true) {
      self.code = code;
      if (notify) {
        yield updateAnalysis(self.id, { code });
      }
    }),
    clearCode() {
      self.code = null;
    },
    setType(type: ExecutionEnvironmentType) {
      self.type = type;
      updateAnalysis(self.id, { type });
    },
    setAutorun(autorun: boolean) {
      self.autoRun = autorun;
    },
    addNewInput() {
      const analysisModule = getParent(self, 2) as IAnalysisModule;
      analysisModule.createAnalysisInput(self as IAnalysis);
    },
    deleteInput(analysisInput: IAnalysisInput): boolean {
      if (self.inputs.includes(analysisInput)) {
        self.inputs.remove(analysisInput);
        // Nasty MST typings workaround
        return (getParent(self, 2) as any)?.deleteAnalysisInput(analysisInput.id);
      } else {
        return false;
      }
    },
    addNewOutput() {
      const analysisModule = getParent(self, 2) as IAnalysisModule;
      analysisModule.createAnalysisOutput(self as IAnalysis);
    },
    deleteOutput(analysisOutput: IAnalysisOutput): boolean {
      if (self.outputs.includes(analysisOutput)) {
        self.outputs.remove(analysisOutput);
        // Nasty MST typings workaround
        return (getParent(self, 2) as any)?.deleteAnalysisOutput(analysisOutput.id);
      } else {
        return false;
      }
    },
  }))
  .views(self => ({
    get sortedInputs(): IAnalysisInput[] {
      return self.inputs.slice().sort(sortByCreated).toReversed();
    },
    get sortedOutputs(): IAnalysisOutput[] {
      return self.outputs.slice().sort(sortByCreated);
    },
    get inputIds(): string[] | undefined {
      return self.inputs?.map(input => input?.id)?.filter(id => id);
    },
    get outputIds(): string[] | undefined {
      return self.outputs?.map(output => output?.id)?.filter(id => id);
    },
    get connections(): (IAnalysisInput | IAnalysisOutput)[] {
      return [...self.inputs, ...self.outputs];
    },
  }));

export function subscribeToAnalysisEvents(socket: Socket) {
  socket.on("createAnalysis", (data: { workspaceId: string; result: Analysis }) => {
    if (data.result?.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.analysis.addExistingAnalysis(data.result);
    }
  });

  socket.on("updateAnalysis", (data: { id: string; dto: UpdateAnalysisDto; result: Analysis }) => {
    const id = data.result?.id;
    if (!id) {
      return;
    }

    const dto = { ...data.dto, updatedAt: convertTimestamp(data.result.updatedAt), updatedBy: data.result.updatedBy };
    const block = appStore.workspaceModel?.analysis.analysisMap?.get(id);
    block?.patch(dto);
  });

  socket.on("deleteCodeBlock", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const codeBlock = appStore.workspaceModel.analysis.analysisMap.get(data.id);
      if (codeBlock) {
        appStore.workspaceModel.analysis.deleteAnalysis(codeBlock.id, false);
      }
    }
  });

  socket.on("codeBlockExecutionStatusChanged", (data: { workspaceId: string; analysisId: string; executionResult: ExecutionResult }) => {
    if (data.analysisId && data.workspaceId === appStore.workspaceModel?.id) {
      updateExecutionResult(data.analysisId, data.executionResult);
    }
  });

  socket.on("codeBlockExecutionComplete", (data: { workspaceId: string; executionResult: ExecutionResult }) => {
    if (data.executionResult?.analysisId && data.workspaceId === appStore.workspaceModel?.id) {
      updateExecutionResult(data.executionResult.analysisId, data.executionResult);
    }
  });

  socket.on("createAnalysisInput", (data: { workspaceId: string; result: AnalysisInput }) => {
    if (data.result?.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.analysis.addExistingAnalysisInput(data.result);
    }
  });

  socket.on("updateAnalysisInput", (data: { id: string; dto: UpdateAnalysisInputDto; result: AnalysisInput }) => {
    const id = data.result?.id;
    if (!id) {
      return;
    }

    const dto = { ...data.dto, updatedAt: convertTimestamp(data.result.updatedAt) };
    const input = appStore.workspaceModel?.analysis.analysisInputMap?.get(id);
    input?.patch(dto);
  });

  socket.on("deleteAnalysisInput", ({ id, workspaceId }: { workspaceId: string; id: string }) => {
    if (id && workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.analysis.deleteAnalysisInput(id, false);
    }
  });

  socket.on("createAnalysisOutput", (data: { workspaceId: string; result: AnalysisOutput }) => {
    if (data.result?.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.analysis.addExistingAnalysisOutput(data.result);
    }
  });

  socket.on("updateAnalysisOutput", (data: { id: string; dto: UpdateAnalysisOutputDto; result: AnalysisOutput }) => {
    const id = data.result?.id;
    if (!id) {
      return;
    }

    const dto = { ...data.dto, updatedAt: convertTimestamp(data.result.updatedAt) };
    const output = appStore.workspaceModel?.analysis.analysisOutputMap?.get(id);
    output?.patch(dto);
  });

  socket.on("deleteAnalysisOutput", ({ id, workspaceId }: { workspaceId: string; id: string }) => {
    if (id && workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.analysis.deleteAnalysisOutput(id, false);
    }
  });
}

export interface IAnalysis extends Instance<typeof AnalysisStore> {}

export interface IAnalysisSnapshotIn extends SnapshotIn<typeof AnalysisStore> {}

interface IAnalysisSnapshotOut extends SnapshotOut<typeof AnalysisStore> {}

export interface IAnalysisMobxType extends IType<IAnalysisSnapshotIn, IAnalysisSnapshotOut, IAnalysis> {}
