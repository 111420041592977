import { RefObject, useEffect, useState } from "react";
import { useResizeObserver } from "usehooks-ts";

type UseHiddenElementsProps = {
  itemsContainerRef: RefObject<HTMLDivElement>;
  itemsResultRef: RefObject<HTMLDivElement>;
  items: Array<{ id: string }>;
};

const useHiddenCellElements = (props: UseHiddenElementsProps) => {
  const { items, itemsContainerRef, itemsResultRef } = props;
  const [hiddenElements, setHiddenElements] = useState<string[]>([]);
  const { width: containerWidth } = useResizeObserver({ ref: itemsContainerRef });
  const containerRightPadding = hiddenElements.length ? 65 : 45;
  const singleItemVisibleLeft = items.length - hiddenElements.length === 1;

  useEffect(() => {
    if (itemsContainerRef.current && itemsResultRef.current) {
      const parentRect = itemsContainerRef.current.getBoundingClientRect();
      const childNodes = itemsResultRef.current.childNodes as unknown as HTMLElement[];

      let cumulativeWidth = 0;
      const newHiddenElementsState: string[] = [];

      childNodes.forEach((child: HTMLElement, index: number) => {
        cumulativeWidth += child.getBoundingClientRect().width;

        if (cumulativeWidth > parentRect.width - containerRightPadding && index) {
          newHiddenElementsState.push(items[index].id);
        }
      });

      setHiddenElements(newHiddenElementsState);
    }
    // we prevent dependency from the refs to avoid re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRightPadding, containerWidth, items]);

  return {
    hiddenElements,
    singleItemVisibleLeft,
  };
};

export default useHiddenCellElements;
