import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Checkbox, InputGroup, Intent } from "@blueprintjs/core";
import { Column, DisplayedColumnsChangedEvent, GridApi } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { GenericSortableList } from "@components/Shared";

import { DEFAULT_SORT_INDEX } from "../constants";
import { parseGridColumnsToState } from "../utils";

import styles from "./ColumnTogglePanel.module.scss";

type Props = {
  api: GridApi;
  columnsState: TStateColumn[];
  disableSave?: boolean;
  onCancel(): void;
  onSave(): void;
  onColumnsStateChange(columns: TStateColumn[]): void;
};

export type TStateColumn = { id: string; colId: string; title: string; hide: boolean; width: number };

const ColumnTogglePanel = (props: Props) => {
  const { api, columnsState, disableSave, onCancel, onSave, onColumnsStateChange } = props;
  const [searchQuery, serSearchQuery] = useState("");

  const handleColumnChanged = useCallback(
    (event: DisplayedColumnsChangedEvent) => {
      onColumnsStateChange(parseGridColumnsToState(event.api));
    },
    [onColumnsStateChange]
  );

  useEffect(() => {
    api.addEventListener("displayedColumnsChanged", handleColumnChanged);

    return () => {
      api.removeEventListener("displayedColumnsChanged", handleColumnChanged);
    };
  }, [api, handleColumnChanged]);

  useEffect(() => {
    onColumnsStateChange(parseGridColumnsToState(api));
  }, [api, onColumnsStateChange]);

  const handleMove = (srcId: string, targetId: string) => {
    const srcColumn = api.getColumn(srcId);
    const targetIndex = columnsState.findIndex(i => i.colId === targetId) + DEFAULT_SORT_INDEX;

    if (srcColumn) {
      api.moveColumns([srcColumn], targetIndex);
      onColumnsStateChange(parseGridColumnsToState(api));
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => serSearchQuery(e.target.value);

  const toggleColumn = (col: Column) => {
    const isVisible = col.isVisible();
    api.setColumnsVisible([col.getColId()], !isVisible);
    if (col.isVisible()) {
      api.ensureColumnVisible(col, "end");
    }
  };

  const handleRenderItem = (item: TStateColumn, _dragging: boolean, dragListeners: any) => {
    const column = api.getColumn(item.colId);

    if (!column) {
      return null;
    }

    return (
      <div key={`${item.colId}${item.title}`} className={classNames(styles.columnTogglePanelItem)}>
        <div className={styles.columnTogglePanelTitle}>
          <Button {...dragListeners} minimal intent={Intent.NONE} icon="drag-handle-vertical" e2eIdentifiers="drag" />
          {item.title}
        </div>
        <Checkbox alignIndicator="right" checked={!item.hide} onChange={() => toggleColumn(column)} />
      </div>
    );
  };

  const filteredBySearch = searchQuery ? columnsState.filter(c => c.title.toLowerCase().includes(searchQuery.toLowerCase())) : columnsState;
  const allChecked = columnsState.every(c => !c.hide);

  const handleCheckAll = () => {
    api.setColumnsVisible(
      api.getAllGridColumns().filter(i => !i.getColDef().suppressColumnsToolPanel),
      !allChecked
    );
  };

  return (
    <div className={styles.columnTogglePanel}>
      <div className={styles.columnTogglePanelButtons}>
        <Button onClick={onCancel} minimal e2eIdentifiers="cancel">
          Cancel
        </Button>
        <Button onClick={onSave} intent="primary" e2eIdentifiers="save" disabled={disableSave}>
          Save
        </Button>
      </div>
      <div className={styles.columnTogglePanelSearch}>
        <InputGroup className={styles.columnTogglePanelInput} placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
        <Checkbox
          alignIndicator="right"
          onChange={handleCheckAll}
          checked={allChecked}
          indeterminate={!allChecked && !columnsState.every(c => c.hide)}
        />
      </div>
      <div className={styles.columnTogglePanelList}>
        <GenericSortableList items={filteredBySearch} onDragEnd={handleMove} defaultHeight={25} renderItem={handleRenderItem} />
      </div>
    </div>
  );
};

export default observer(ColumnTogglePanel);
