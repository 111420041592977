import { MouseEvent, ReactElement, ReactNode, useEffect, useMemo, useState } from "react";
import { Checkbox, InputGroup, Menu, MenuDivider } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { Text, TextColor, TextVariant } from "@ui/Text";
import { useDebounceValue } from "usehooks-ts";

import { MenuItem } from "@components/MenuItem";
import { isBPIcon } from "@utilities/Icon";

import { ItemInfo } from "./ItemInfo";

import styles from "./BaseSelectorMenu.module.scss";

interface BaseSelectorMenuProps {
  title?: string;
  showSearchBox?: boolean;
  searchBoxPlaceholder?: string;
  maxHeight?: number;
  searchTerm?: string;
  singleSelect?: boolean;
  hideTickOnSelected?: boolean;
  items?: ItemInfo[];
  selectedItemIds?: string[];
  disableFilter?: boolean;
  header?: ReactElement;
  footer?: ReactElement;
  onSearchTermChange?: (searchTerm: string) => void;
  itemRenderer: (itemInfo: ItemInfo) => ReactNode;
  onChange?: (selectedItems: string[]) => void;
}

const BaseSelectorMenu = ({
  title,
  maxHeight,
  showSearchBox = true,
  searchBoxPlaceholder,
  searchTerm: searchTermProp,
  singleSelect,
  hideTickOnSelected,
  items = [],
  selectedItemIds: selectedItemIdsProp = [],
  disableFilter,
  header,
  footer,
  onSearchTermChange,
  itemRenderer,
  onChange,
}: BaseSelectorMenuProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(selectedItemIdsProp);
  const [searchTerm, setSearchTerm] = useState<string>(searchTermProp ?? "");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 400);

  useEffect(() => {
    if (searchTermProp !== searchTerm) {
      setSearchTerm(searchTermProp ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermProp]);

  const filteredItems = useMemo(() => {
    if (disableFilter) {
      return items;
    }
    return items.filter(item => item.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()));
  }, [debouncedSearchTerm, items, disableFilter]);

  useEffect(() => {
    onSearchTermChange?.(debouncedSearchTerm);
  }, [debouncedSearchTerm, items, onSearchTermChange]);

  useEffect(() => {
    onChange?.(selectedItems);
  }, [selectedItems, onChange]);

  const handleSelection = (item: ItemInfo) => {
    if (singleSelect) {
      setSelectedItems([item.id]);
    } else {
      if (selectedItems.includes(item.id)) {
        setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item.id));
      } else {
        setSelectedItems([...selectedItems, item.id]);
      }
    }
  };

  const isSelected = (item: ItemInfo) => {
    return selectedItems.includes(item.id);
  };

  const renderIcon = (item: ItemInfo) => {
    if (!item.icon) {
      return null;
    }
    if (singleSelect) {
      return isBPIcon(item.icon) ? <BlueprintIcon icon={item.icon} color={item.iconColor ?? undefined} /> : <>{item.icon}</>;
    } else {
      return (
        <div className={styles.baseSelectorMenuIconMultiselect}>
          <Checkbox
            checked={isSelected(item)}
            onClick={e => {
              e.stopPropagation();
            }}
          />
          {isBPIcon(item.icon) ? <BlueprintIcon icon={item.icon} color={item.iconColor ?? undefined} /> : <>{item.icon}</>}
        </div>
      );
    }
  };

  const renderItemRow = (item: ItemInfo) => (
    <MenuItem
      key={item.id}
      icon={renderIcon(item)}
      onClick={(e: MouseEvent<HTMLElement>) => {
        !singleSelect && e.stopPropagation();
        handleSelection(item);
      }}
      text={<div className={styles.baseSelectorMenuOption}>{itemRenderer(item)}</div>}
      active={isSelected(item)}
      labelElement={singleSelect && isSelected(item) && !hideTickOnSelected ? <BlueprintIcon icon="tick" /> : undefined}
      e2eIdentifiers={["filter-menu", "menu-item"]}
    />
  );

  return (
    <Menu className={styles.baseSelectorMenu} style={{ maxHeight }}>
      {title && (
        <div className={styles.baseSelectorMenuTitle}>
          <Text variant={TextVariant.Label} color={TextColor.Secondary}>
            {title}
          </Text>
        </div>
      )}
      {header && (
        <>
          <MenuDivider />
          {header}
          <MenuDivider />
        </>
      )}
      {showSearchBox && (
        <InputGroup
          leftIcon="search"
          placeholder={searchBoxPlaceholder ?? "Search"}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      )}
      {filteredItems?.map(item => renderItemRow(item))}
      {footer && (
        <>
          <MenuDivider />
          {footer}
        </>
      )}
    </Menu>
  );
};

export default BaseSelectorMenu;
