import { RefObject, useLayoutEffect, useState } from "react";

export const useIsOverflowing = (ref: RefObject<HTMLElement>) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      const isOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
      setIsOverflowing(isOverflowing);
    }
  }, [ref]);

  return isOverflowing;
};
