import { ModuleMonochromeIcon } from "@components/CustomIcon";
import { IconSource, IIcon } from "@rollup-types/icons";
import { StoreType } from "@store/types";
import {
  getKnowledgebaseBlockByIdAsync,
  getKnowledgebasePageByIdAsync,
  getPropertyInstanceByIdAsync,
  getRequirementBlockByIdAsync,
  getRequirementsPageByIdAsync,
} from "@utilities";
import { getAttachmentById } from "@utilities/Attachment";
import { getBlockByIdWithWorkspace } from "@utilities/Block";
import { createBpIcon } from "@utilities/Icon";

export const getParentNameAndIcon = async (
  parentType: StoreType,
  parentId: string,
  workspaceId: string
): Promise<{ parentName: string; parentIcon?: IIcon | string }> => {
  switch (parentType) {
    case StoreType.Block: {
      const block = await getBlockByIdWithWorkspace(parentId, workspaceId);
      return {
        parentName: block?.label ?? "",
        parentIcon: block?.iconView,
      };
    }
    case StoreType.Attachment: {
      const attachment = await getAttachmentById(parentId, workspaceId);
      return {
        parentName: attachment?.label ?? "",
        parentIcon: createBpIcon("comment"),
      };
    }
    case StoreType.PropertyInstance: {
      const propertyInstance = await getPropertyInstanceByIdAsync(parentId, workspaceId);
      return {
        parentName: propertyInstance?.label ?? "",
        parentIcon: createBpIcon("property"),
      };
    }
    case StoreType.Requirement: {
      const reqPage = await getRequirementsPageByIdAsync(parentId, workspaceId);
      return {
        parentName: reqPage?.label ?? "",
        parentIcon: { source: IconSource.Custom, name: ModuleMonochromeIcon.RequirementsBw },
      };
    }
    case StoreType.RequirementBlock: {
      const reqBlock = await getRequirementBlockByIdAsync(parentId, workspaceId);
      return {
        parentName: reqBlock?.parentPage?.label ?? "",
        parentIcon: { source: IconSource.Custom, name: ModuleMonochromeIcon.RequirementsBw },
      };
    }
    case StoreType.ReportBlock: {
      const kbBlock = await getKnowledgebaseBlockByIdAsync(parentId, workspaceId);
      const kbPage = await getKnowledgebasePageByIdAsync(kbBlock?.parentReport?.id ?? "", workspaceId);
      return {
        parentName: kbPage?.label ?? "",
        parentIcon: kbPage?.displayedIcon,
      };
    }
    case StoreType.Report: {
      const kbPage = await getKnowledgebasePageByIdAsync(parentId, workspaceId);
      return {
        parentName: kbPage?.label ?? "",
        parentIcon: kbPage?.displayedIcon,
      };
    }
    // TODO: implement
    case StoreType.BOM:
    default:
      console.warn("Unknown parent type", parentType);
      return { parentName: "" };
  }
};
