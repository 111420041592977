import { ProcessCellForExportParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";

import { LeadPeriod } from "@components/CreateCatalogItemDialog/types";
import { periodToText } from "@components/CreateCatalogItemDialog/utils";
import appStore from "@store/AppStore";
import { ICatalogItemReference } from "@store/CatalogItem/CatalogItemReferenceStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";

import { CatalogItemEditableVersionColumn, CatalogItemNode, CatalogItemTableColumn } from "./types";

export const getDataPath = (data: CatalogItemNode) => data.path;

export const getActiveRowStyles = () => {
  // this is a hacky solution to prevent table re-render and get a dynamic row styling
  return `.ag-row[row-id="${appStore.ui.catalogItemPreviewId}"]{background-color: var(--bg-color-level3) !important}`;
};

export const versionCellValueSetter = (node: ValueSetterParams<CatalogItemNode>, field: CatalogItemEditableVersionColumn) => {
  appStore.ui.updatePdmItemVersionProgress({
    catalogItemId: node.data.catalogItem.id!,
    ...appStore.ui.pdmItemVersionProgress,
    [field]: node.newValue,
  });
  return true;
};

export const versionCellLeadTimeValueSetter = (
  node: ValueSetterParams<CatalogItemNode>,
  column: CatalogItemTableColumn.LeadTimePeriod | CatalogItemTableColumn.LeadTime
) => {
  const changedValue = appStore.ui.pdmItemVersionProgress?.["leadTime"];
  const leadTimeString = changedValue ? periodToText(changedValue) : node.data?.catalogItem.leadTime;
  let leadPeriod = LeadPeriod.Days;
  let leadPeriodAmount = 1;

  if (leadTimeString) {
    const duration = leadTimeString.split(" ");
    leadPeriodAmount = Number(duration[0]);
    leadPeriod = (duration[1]?.at(0)?.toUpperCase() || LeadPeriod.Days) as LeadPeriod;
  }

  if (column === CatalogItemTableColumn.LeadTimePeriod) {
    leadPeriod = node.newValue as LeadPeriod;
  } else {
    leadPeriodAmount = Number(node.newValue);
  }

  appStore.ui.updatePdmItemVersionProgress({
    catalogItemId: node.data.catalogItem.id!,
    ...appStore.ui.pdmItemVersionProgress,
    leadTime: `P${leadPeriodAmount}${leadPeriod}`,
  });
  return true;
};

export const versionCellLeadTimeValueGetter = (
  node: ValueGetterParams<CatalogItemNode>,
  column: CatalogItemTableColumn.LeadTimePeriod | CatalogItemTableColumn.LeadTime
) => {
  const catalogItemId = node.data?.catalogItem.id;
  const versionUpdateInProgress = appStore.ui.pdmItemVersionProgress?.catalogItemId === catalogItemId;
  const leadTime = appStore.ui.pdmItemVersionProgress?.["leadTime"];
  const changedValue = leadTime ? periodToText(leadTime) : undefined;
  const leadTimeString = versionUpdateInProgress ? changedValue : node.data?.catalogItem.leadTime;
  let leadPeriod = LeadPeriod.Days;
  let leadPeriodAmount = 1;

  if (leadTimeString) {
    const duration = leadTimeString.split(" ");
    leadPeriodAmount = Number(duration[0]);
    leadPeriod = (duration[1]?.at(0)?.toUpperCase() || LeadPeriod.Days) as LeadPeriod;
  }

  if (column === CatalogItemTableColumn.LeadTimePeriod) {
    return leadPeriod;
  }

  return leadPeriodAmount;
};

export const versionCellValueGetter = (params: ValueGetterParams<CatalogItemNode>, field: CatalogItemEditableVersionColumn) => {
  const catalogItemId = params.data?.catalogItem.id;
  const versionUpdateInProgress = appStore.ui.pdmItemVersionProgress?.catalogItemId === catalogItemId;
  const changedValue = appStore.ui.pdmItemVersionProgress?.[field];

  if (versionUpdateInProgress && changedValue !== undefined) {
    return changedValue;
  }

  return params.data?.catalogItem[field] ?? "";
};

const fillBlockList = (catalogItem: ICatalogItem, list: Array<CatalogItemNode>, path: string[]) => {
  const newPath = [...path, catalogItem.id];
  list.push({ catalogItem, path: newPath });

  for (const childNode of catalogItem.validatedChildren) {
    fillBlockList(childNode, list, newPath);
  }

  if (!appStore.ui.showPdmFlatten) {
    const childReferences: ICatalogItemReference[] = catalogItem.childReferences;
    for (const reference of childReferences) {
      const refPath = [...newPath, reference.id];

      if (reference.referencedCatalogItem) {
        list.push({ catalogItem: reference.referencedCatalogItem, path: refPath, reference });
      }
    }
  }
};

export const catalogItemNodeList = (items: ICatalogItem[]): CatalogItemNode[] => {
  const list = new Array<CatalogItemNode>();

  items.forEach(catalogItem => fillBlockList(catalogItem, list, []));

  return list;
};

export const processCellCallback = (params: ProcessCellForExportParams<CatalogItemNode>) => {
  const column = params.column.getColId() as CatalogItemTableColumn;
  const catalogItem = params.node?.data?.catalogItem;

  if (!catalogItem) {
    return params.value;
  }

  switch (column) {
    case CatalogItemTableColumn.AutoGroupColumn:
      return catalogItem.name;
    case CatalogItemTableColumn.PartNumber:
      return catalogItem.partNumber;
    case CatalogItemTableColumn.Thumbnail:
      return catalogItem.imageUrl;
    case CatalogItemTableColumn.PartType:
      return catalogItem.type;
    case CatalogItemTableColumn.Status:
      return catalogItem.status;
    case CatalogItemTableColumn.Description:
      return catalogItem.description;
    case CatalogItemTableColumn.Material:
      return catalogItem.material;
    case CatalogItemTableColumn.Weight:
      return catalogItem.weight;
    case CatalogItemTableColumn.Supplier:
      return catalogItem.supplier;
    case CatalogItemTableColumn.Index:
      return catalogItem.version;
    case CatalogItemTableColumn.Quantity:
      return catalogItem.quantity;
    case CatalogItemTableColumn.Cost:
      return catalogItem.cost;
    case CatalogItemTableColumn.LeadTime:
      return catalogItem.leadTime;
    default:
      return params.value;
  }
};

export const getRowId = (node: CatalogItemNode) => {
  return node.reference ? node.reference.id : node.catalogItem.id;
};
