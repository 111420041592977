import { JSX, useState } from "react";
import { Position } from "@blueprintjs/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";

import styles from "./DocumentActionNav.module.scss";

type Props = {
  dragListeners: SyntheticListenerMap;
  dragButtonMenuElement: JSX.Element;
  hasSingleBlock: boolean;
  className?: string;
  addButtonMenuElement?: JSX.Element;
  onAdd?(): void;
};

const DocumentActionsNav = (props: Props) => {
  const { dragListeners, className, hasSingleBlock } = props;
  const { addButtonMenuElement, onAdd, dragButtonMenuElement } = props;
  const [isAddBtnPopoverOpen, setIsAddBtnPopoverOpen] = useState(false);
  const [isDragBtnPopoverOpen, setIsDragBtnPopoverOpen] = useState(false);

  return (
    <div
      className={classNames(styles.documentActionsNav, className, {
        [styles.documentActionsNavOpen]: isDragBtnPopoverOpen || isAddBtnPopoverOpen,
        [styles.visible]: hasSingleBlock,
      })}
    >
      <Popover
        className={classNames(styles.documentActionsNavPopoverTarget, styles.addButtonPopover, {
          [styles.addButtonPopoverOpen]: isAddBtnPopoverOpen,
        })}
        onClosing={() => setIsAddBtnPopoverOpen(false)}
        onOpening={() => setIsAddBtnPopoverOpen(true)}
        content={addButtonMenuElement}
        disabled={!addButtonMenuElement}
      >
        <Button minimal icon="plus" onClick={addButtonMenuElement ? undefined : onAdd} e2eIdentifiers={["document-actions-nav", "add"]} />
      </Popover>
      <div className={styles.documentActionsNavDragHandle} {...dragListeners}>
        <Popover
          className={classNames(styles.dragButtonPopover, { [styles.dragButtonPopoverOpen]: isDragBtnPopoverOpen })}
          onClosing={() => setIsDragBtnPopoverOpen(false)}
          onOpening={() => setIsDragBtnPopoverOpen(true)}
          content={dragButtonMenuElement}
          position={Position.LEFT_TOP}
        >
          <Button minimal icon="drag-handle-vertical" e2eIdentifiers={["document-actions-nav", "drag"]} />
        </Popover>
      </div>
    </div>
  );
};

export default observer(DocumentActionsNav);
