import TaskBacklogIcon from "@assets/icons/pm/pm-backlog.svg?react";
import TaskBlockedIcon from "@assets/icons/pm/pm-blocked.svg?react";
import TaskDoneIcon from "@assets/icons/pm/pm-done.svg?react";
import TaskInProgressIcon from "@assets/icons/pm/pm-in-progress.svg?react";
import TaskInReviewIcon from "@assets/icons/pm/pm-in-review.svg?react";
import CustomIcon from "@components/Shared/LegacyCustomIcon";

export enum TaskStatus {
  Backlog = "Open",
  InProgress = "In progress",
  InReview = "In review",
  Blocked = "Blocked",
  Done = "Done",
}

export const taskStatusIcons: { [key in TaskStatus]: React.ReactElement } = {
  [TaskStatus.Backlog]: <CustomIcon icon={<TaskBacklogIcon />} />,
  [TaskStatus.InProgress]: <CustomIcon icon={<TaskInProgressIcon />} />,
  [TaskStatus.InReview]: <CustomIcon icon={<TaskInReviewIcon />} />,
  [TaskStatus.Blocked]: <CustomIcon icon={<TaskBlockedIcon />} />,
  [TaskStatus.Done]: <CustomIcon icon={<TaskDoneIcon />} />,
};
