import { resolveEntity } from "@rollup-io/engineering";

import { CatalogItemStatus } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { RevisionCodeType, SemanticRevisionCodeType } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";

export const getStatusLabel = (status?: CatalogItemStatus) => {
  switch (status) {
    case CatalogItemStatus.Suspended:
      return "Suspended";
    case CatalogItemStatus.Active:
      return "Active";
    case CatalogItemStatus.Pending:
      return "Pending";
    case CatalogItemStatus.Archived:
      return "Archived";
    default:
      return "In Review";
  }
};

// this function converts number to a letter representation of the revision code
// A, B, C ... Z, AA, AB AC ...
const numberToRevisionCode = (count: number): string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let code = "";

  while (count > 0) {
    const index = (count - 1) % 26;
    code = characters[index] + code;
    count = Math.floor((count - 1) / 26);
  }

  return code;
};

const getNextSemanticRevisionCode = (
  revisionsCount: number,
  currenRevisionCode: string,
  semanticUpdateType?: SemanticRevisionCodeType
): string => {
  if (!currenRevisionCode) {
    switch (semanticUpdateType) {
      case SemanticRevisionCodeType.Patch:
        return "0.0.1";
      case SemanticRevisionCodeType.Minor:
        return "0.1.0";
      default:
        return "1.0.0";
    }
  }

  const [major, minor, patch] = currenRevisionCode.split(".").map(Number);

  if ([major, minor, patch].some(isNaN)) {
    switch (semanticUpdateType) {
      case SemanticRevisionCodeType.Patch:
        return `${revisionsCount}.0.1`;
      case SemanticRevisionCodeType.Minor:
        return `${revisionsCount}.1.0`;
      default:
        return `${revisionsCount + 1}.0.0`;
    }
  }

  switch (semanticUpdateType) {
    case SemanticRevisionCodeType.Major:
      return `${major + 1}.0.0`;
    case SemanticRevisionCodeType.Minor:
      return `${major}.${minor + 1}.0`;
    default:
      return `${major}.${minor}.${patch + 1}`;
  }
};

export const getNextRevisionCode = (
  revisionsNumber: number,
  codeType: RevisionCodeType,
  currentRevisionCode = "",
  semanticUpdateType?: SemanticRevisionCodeType
): string => {
  switch (codeType) {
    case RevisionCodeType.Semantic:
      return getNextSemanticRevisionCode(revisionsNumber, currentRevisionCode, semanticUpdateType);
    case RevisionCodeType.Letters:
      return numberToRevisionCode(revisionsNumber + 1);
    default:
      return `${revisionsNumber + 1}`;
  }
};

export const getCatalogItemPath = (item: ICatalogItem): string[] => {
  if (!item.parentItem) {
    return [item.id];
  }

  const path: string[] = [];

  const getParentId = (sourceItem: ICatalogItem) => {
    path.unshift(sourceItem.id);
    const parentItem = sourceItem.parentItem as ICatalogItem;

    if (!parentItem) {
      return;
    }

    getParentId(parentItem);
  };

  getParentId(item);
  return path;
};

export const getCatalogItemById = (id: string) => {
  return resolveEntity<ICatalogItem>(appStore?.orgModel.catalogItems.catalogItemsMap, id);
};
