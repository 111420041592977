import { MutableRefObject, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  MeasuringStrategy,
  PointerSensor,
  SensorOptions,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { DragStartEvent } from "@dnd-kit/core/dist/types";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import isEqual from "lodash/isEqual";
import { observer } from "mobx-react";

import Item, { TItemRendererCallBack } from "./Components/Item/Item";
import { DroppableContainer, SortableItem } from "./Components";

import "./SortableLinkedLists.module.scss";
import styles from "./SortableLinkedLists.module.scss";

interface Props<ItemComponentCustomProps extends object | undefined> {
  items: string[];
  itemComponent: TItemRendererCallBack<ItemComponentCustomProps>;
  itemComponentCustomProps?: ItemComponentCustomProps;
  wrapperRef?: MutableRefObject<HTMLDivElement | null>;
  defaultSelectedIds?: string[];
  onMultipleItemsDragEnd(sourceIds: string[], targetId: string): void;
}

const SimpleSortableList = <ItemComponentCustomProps extends object | undefined = undefined>(props: Props<ItemComponentCustomProps>) => {
  const { defaultSelectedIds, items, itemComponent, itemComponentCustomProps, wrapperRef, onMultipleItemsDragEnd } = props;
  const [activeId, setActiveId] = useState<string | null>(null);
  const [isDropAbove, setIsDropAbove] = useState<boolean>(false);
  const [overId, setOverId] = useState<string | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    // prevent from blocks re-render while dragging on new selections passed
    if (activeId || !defaultSelectedIds || isEqual(defaultSelectedIds, selectedIds)) {
      return;
    }

    setSelectedIds(defaultSelectedIds);
  }, [activeId, defaultSelectedIds, selectedIds]);

  const resetOverAndActiveIds = () => {
    setOverId(null);
    setActiveId(null);
  };

  const options: SensorOptions = { activationConstraint: { distance: 15 } };
  const sensors = useSensors(useSensor(PointerSensor, options), useSensor(TouchSensor, options));

  const onDragCancel = () => {
    resetOverAndActiveIds();
  };

  const handleDragStart = (dragStartEvent: DragStartEvent) => {
    const { active } = dragStartEvent;
    // clear selected items if we start dragging unselected one
    setSelectedIds(selected => (selected.includes(`${active.id}`) ? selected : []));
    setActiveId(`${active.id}`);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const overId = over?.id as string | undefined;
    const activeId = active.id as string;

    if (overId === null || overId === undefined) {
      setOverId(null);
      return;
    }

    setOverId(`${overId}`);

    const overIndex = items.indexOf(overId);
    const activeIndex = items.indexOf(activeId);

    setIsDropAbove(activeIndex > overIndex);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const activeId = `${active.id}`;
    const overId = over ? `${over.id}` : undefined;

    if (!overId || activeId === overId || selectedIds.includes(overId)) {
      resetOverAndActiveIds();
      setSelectedIds([]);
      return;
    }

    const ids = selectedIds.length ? selectedIds : [`${active.id}`];

    onMultipleItemsDragEnd(ids, overId);
    resetOverAndActiveIds();
  };

  const renderDragOverlay = () => {
    if (!activeId) {
      return null;
    }
    return <Item value={activeId} dragOverlay itemComponent={itemComponent as TItemRendererCallBack} />;
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={onDragCancel}
    >
      <DroppableContainer id="simple-sortable-list" items={items}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <div className={styles.sortableLinkedListsSortableItemsContainer} ref={wrapperRef}>
            {items.map(id => (
              <SortableItem
                selected={selectedIds.includes(id)}
                handle
                id={id}
                key={id}
                newItemAbove={isDropAbove}
                isOver={overId === id && overId !== activeId}
                itemComponent={itemComponent as TItemRendererCallBack}
                itemComponentCustomProps={itemComponentCustomProps}
              />
            ))}
          </div>
        </SortableContext>
      </DroppableContainer>
      {createPortal(<DragOverlay>{renderDragOverlay()}</DragOverlay>, document.body)}
    </DndContext>
  );
};

export default observer(SimpleSortableList);
