import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Icon, Intent, Spinner } from "@blueprintjs/core";
import { TextColor } from "@ui/Text";
import capitalize from "lodash/capitalize";
import { observer } from "mobx-react";

import RollupLogoDark from "@assets/img/Logo_Dark.svg?react";
import RollupLogoLight from "@assets/img/Logo_Light.svg?react";
import MsTeamsLogo from "@assets/img/ms-teams-logo.webp";
import { Button } from "@components/Button";
import { ThemedCustomIcon } from "@components/Shared/LegacyCustomIcon/ThemedCustomIcon";
import { IntegrationProvider } from "@rollup-api/models";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";
import { rollupClient } from "src/core/api";

import styles from "./IntegrationConnect.module.scss";

const IntegrationConnect = () => {
  const { integrationId } = useParams();
  const { navigateToIntegrationConfig } = useAppNavigate();
  const [searchParams] = useSearchParams("teamId");
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState("");
  const teamId = searchParams.get("teamId");
  const tenantId = searchParams.get("tenantId");
  const code = searchParams.get("code");
  const integration: IIntegration | undefined = appStore.settingsModel?.integrations.find(i => i.slug === integrationId);

  const handleInitiateConnection = useCallback(() => {
    if (integrationId !== IntegrationProvider.MicrosoftTeams) {
      setError("Unsupported integration id");
      return;
    }

    if (!teamId || !tenantId || !code) {
      setError("Missing required parameters");
      return;
    }

    setError("");
    setConnecting(true);
    rollupClient.integrations
      .finishMicrosoftTeamsConnection(tenantId, teamId, code)
      .then(r => {
        appStore.orgModel?.createIntegration(r.data);
        setConnected(true);
      })
      .catch(e => {
        const errorDetailedMessage = Array.isArray(e?.response?.data?.message)
          ? e.response.data.message.join(", ")
          : e.response?.data?.message;
        setError(capitalize(errorDetailedMessage || e.message || "A server error occurred"));
      })
      .finally(() => setConnecting(false));
  }, [code, integrationId, teamId, tenantId]);

  useEffect(() => {
    handleInitiateConnection();
  }, [handleInitiateConnection]);

  return (
    <div className={styles.integrationConnect}>
      <div className={styles.integrationConnectLogos}>
        <ThemedCustomIcon lightIcon={<RollupLogoLight />} darkIcon={<RollupLogoDark />} />
        <Icon className={styles.integrationConnectIcon} icon="arrows-horizontal" />
        <img src={MsTeamsLogo} alt="" height={35} />
      </div>
      {error && <div className={styles.integrationConnectError}>{error}</div>}
      {connecting && (
        <div className={styles.integrationConnectSpinner}>
          <Spinner size={20} />
          <span>Connecting...</span>
        </div>
      )}
      {connected && (
        <div className={styles.integrationConnectSuccessWrap}>
          <div className={styles.integrationConnectSuccess}>
            <Icon icon="tick-circle" color={TextColor.Success} />
            <span>Connected successfully!</span>
          </div>
          {integration && (
            <Button
              e2eIdentifiers="visit-integration"
              intent={Intent.PRIMARY}
              onClick={() => navigateToIntegrationConfig(`${integration.id}`)}
            >
              Visit integration page
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(IntegrationConnect);
