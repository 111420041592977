import { useState } from "react";
import { Intent } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import FilePanel from "@components/CreateCatalogItemDialog/Modes/Csv/Steps/File";
import HeadersPanel from "@components/CreateCatalogItemDialog/Modes/Csv/Steps/Headers";

import styles from "./CsvImport.module.scss";

interface ICsvImportProps<T extends string> {
  columnMap: Record<T, string>;
  columnNameMap: Record<T, string>;
  footerClassName?: string;
  onClose(): void;
  onChangeColumnMap(columnsMap: Record<T, string>): void;
  onUpload(file: File): void;
}

enum CsvImportStep {
  File = "File",
  Columns = "Columns",
}

const CsvImport = <CsvColumn extends string>(props: ICsvImportProps<CsvColumn>) => {
  const { columnMap, columnNameMap, footerClassName, onClose, onChangeColumnMap, onUpload } = props;
  const [step, setStep] = useState<CsvImportStep>(CsvImportStep.File);
  const [file, setFile] = useState<File | null>(null);

  const renderStep = () => {
    switch (step) {
      case CsvImportStep.File:
        return <FilePanel file={file} setFile={setFile} onNextStep={() => setStep(CsvImportStep.Columns)} />;
      case CsvImportStep.Columns:
        return <HeadersPanel<CsvColumn> file={file} onChange={onChangeColumnMap} columnNameMap={columnNameMap} columnsMap={columnMap} />;
      default:
        return null;
    }
  };

  const handleBackClick = () => {
    if (step === CsvImportStep.Columns) {
      setStep(CsvImportStep.File);
    } else {
      onClose();
    }
  };

  const handleNextClick = async () => {
    if (step === CsvImportStep.File) {
      setStep(CsvImportStep.Columns);
    } else {
      if (file) {
        onUpload(file);
        onClose();
      }
    }
  };

  const getButtonDisabled = () => {
    if (step === CsvImportStep.File) {
      return !file;
    }

    return false;
  };

  return (
    <div className={styles.csvImport}>
      <div className={styles.csvImportContent}>{renderStep()}</div>
      <div className={classNames(styles.csvImportFooter, footerClassName)}>
        <Button onClick={handleBackClick} large minimal e2eIdentifiers="cancel-catalog-item-creation">
          {step === CsvImportStep.File ? "Cancel" : "Back"}
        </Button>
        <Button
          disabled={getButtonDisabled()}
          large
          style={{ width: 80 }}
          intent={Intent.PRIMARY}
          onClick={handleNextClick}
          e2eIdentifiers="csv-submit-catalog-item"
        >
          {step === CsvImportStep.File ? "Next" : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default observer(CsvImport);
