import { useEffect } from "react";
import { Outlet, useMatch } from "react-router-dom";
import { H5 } from "@blueprintjs/core";
import { useBool } from "@hooks/useBool/useBool";
import { observer } from "mobx-react";
import { useToggle } from "usehooks-ts";

import { CollapseButton } from "@components/CollapseButton";
import { FeatureLayout } from "@components/FeatureLayout";
import DebuggerPane from "@components/Modeling/ModelerSidebar/DebuggerPane";
import ModelTree from "@components/Modeling/ModelerSidebar/ModelTree";
import { getModelingLayoutMenuItems } from "@constants/modeling-navigation";
import { TNavigationLinkConfig } from "@constants/navigation";
import MenuPane, { MenuPaneProps } from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import appStore from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";

function ModelingLayout() {
  const [expanded, toggleExpanded] = useToggle(true);
  const { isSubNavPaneOpen, toggleSubNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isSubNavPaneOpen, toggleIsOpen: toggleSubNavPane });
  const [menuHovered, { set: hoverMenu, unset: unhoverMenu }] = useBool();
  const linksMapper = (link: TNavigationLinkConfig): TNavigationLinkConfig & { selected: boolean } => ({
    ...link,
    ...(link.nestedLinks && { nestedLinks: link.nestedLinks.map(linksMapper) }),
    selected: navMatch?.params?.view === link.to || [nestedNavMatch?.params?.view, nestedNavMatch?.params?.subView].join("/") === link.to,
  });
  const navMatch = useMatch(`/workspaces/:workspaceId/modeling/:view/*`);
  const nestedNavMatch = useMatch(`/workspaces/:workspaceId/modeling/:view/:subView/*`);

  const hoverMenuMenuItems = getModelingLayoutMenuItems("hover-menu").map(linksMapper) as MenuPaneProps["menuItems"];
  const menuItems = getModelingLayoutMenuItems().map(linksMapper);

  const [isBlockView, isTableView] = hoverMenuMenuItems?.map(i => i.selected) ?? [];

  useEffect(() => {
    if (!isBlockView) {
      appStore.env.clearActiveBlock();
    }
  }, [isBlockView]);

  if (appStore.env.isSinglePaneMode) {
    return <Outlet />;
  }

  const topElement = (!!isBlockView || !!isTableView) && (
    <div className="menu-content--header">
      {!!isBlockView && (
        <div className="flex items-center justify-between min-h-[30px] pl-1">
          <H5 className="m-0">Blocks</H5>
          <CollapseButton isExpanded={expanded} onClick={toggleExpanded} />
        </div>
      )}
      {!!isTableView && (
        <div className="flex items-center justify-between min-h-[30px] pl-1">
          <H5 className="m-0">Table</H5>
        </div>
      )}
    </div>
  );

  const sidePanelContent = (
    <>
      {isBlockView && <ModelTree showSearch expanded={expanded} />}
      {isTableView && <DebuggerPane />}
    </>
  );

  return (
    <FeatureLayout
      paneWidth={appStore.env.paneWidth.subNav}
      menuHovered={menuHovered}
      dynamicPanelContent={sidePanelContent}
      hoverMenuMenuItems={hoverMenuMenuItems}
      topElement={topElement}
      minSize={minSize}
      maxSize={maxSize}
      onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.SUB_NAV, newSize)}
      onHoverMenu={hoverMenu}
      onUnhoverMenu={unhoverMenu}
      isSidePanelOpen={open}
    >
      <MenuPane
        hideScroll
        open={open}
        toggle={toggle}
        title="Modeling"
        menuItems={menuItems}
        topElement={topElement}
        onMouseEnter={hoverMenu}
        onMouseLeave={unhoverMenu}
      >
        {sidePanelContent}
      </MenuPane>

      <Outlet />
    </FeatureLayout>
  );
}

export default observer(ModelingLayout);
