import { mergeEntities } from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { IPropertyListItem } from "@components/Shared/ScalarExpressionEditor/PropertyListTypes";
import { PropertyTagType } from "@components/Shared/ScalarExpressionEditor/tags/PropertyTag";
import { isValidPropertyEntry } from "@components/Shared/ScalarExpressionEditor/utils";
import { DataSource } from "@rollup-api/models/data-sources";
import { IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";
import appStore from "@store/AppStore";
import { IDataSinkEntry } from "@store/DataConnection/DataSinkEntryStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { IWorkspace } from "@store/WorkspaceStore";
import { isDefined, isFieldDefined } from "@utilities/TypeGuards";

const createPropertyEntries = (propertyInstances: IPropertyInstance[], params: IPropertyReferenceSuggestionParams): IPropertyListItem[] => {
  const { referenceProperty, showDetachedProperties } = params;

  return propertyInstances.reduce((acc: IPropertyListItem[], p) => {
    const isDetached = p.parentBlock.isDetachedBlock;
    if (p.propertyDefinition && p.path && p !== referenceProperty && (showDetachedProperties || !isDetached)) {
      acc.push({
        id: p.id,
        type: PropertyTagType.PropertyInstance,
        queryString: p.path,
        extendedLabel: p.path,
        icon: "property",
        label: p.propertyDefinition.label,
      });
    }
    return acc;
  }, []);
};

const createDataSourceEntries = (dataSources: DataSource[]): IPropertyListItem[] => {
  return dataSources.map(ds => ({ id: ds.id, type: PropertyTagType.DataLink, queryString: ds.label, label: ds.label }));
};

const createDataSinkEntries = (dataSinkEntries: IDataSinkEntry[]): IPropertyListItem[] => {
  return dataSinkEntries.map(ds => {
    const dataSinkLabel = ds.dataSink?.label ?? "";
    return {
      id: ds.id,
      type: PropertyTagType.DataSinkEntry,
      queryString: `${dataSinkLabel ? dataSinkLabel + " " : ""}${ds.key}`,
      label: `${dataSinkLabel ? dataSinkLabel + ":" : ""}${ds.key}`,
    };
  });
};

const createAnalysisOutputEntries = (analysisOutputs: IAnalysisOutput[]): IPropertyListItem[] => {
  return analysisOutputs.filter(isFieldDefined("analysis")).map(analysisOutput => {
    const label = `${analysisOutput.analysis.label}.${analysisOutput.label}`;
    return {
      id: analysisOutput.id,
      type: PropertyTagType.AnalysisOutput,
      queryString: `${analysisOutput.analysis.label} ${analysisOutput.label}`,
      label: label,
    };
  });
};

export const createListItems = (workspace: IWorkspace, params: IPropertyReferenceSuggestionParams): IPropertyListItem[] => {
  return [
    ...createPropertyEntries(workspace.propertyInstances, params),
    ...createDataSourceEntries(workspace.dataSources),
    ...createDataSinkEntries(workspace.dataConnection.dataSinkEntries),
    ...createAnalysisOutputEntries(workspace.analysis.analysisOutputs),
  ];
};

const createSamplePropertyEntries = (workspace: IWorkspace, params: IPropertyReferenceSuggestionParams): IPropertyListItem[] => {
  const { referenceProperty, showDetachedProperties } = params;
  const recentProperties = appStore.env.recentPropertyIds.map(id => workspace.propertyInstanceMap.get(id)).filter(isDefined);
  const isValid = (p: IPropertyInstance) => isValidPropertyEntry(p, referenceProperty, showDetachedProperties);
  const entries = mergeEntities({ recentEntities: recentProperties, allEntities: workspace.propertyInstances, isValid });
  return createPropertyEntries(entries, params);
};

const createSampleDataSourceEntries = (workspace: IWorkspace): IPropertyListItem[] => {
  const recentDataSources = appStore.env.recentDataSourceIds.map(id => workspace.dataConnection.dataSourceMap.get(id)).filter(isDefined);
  const entries = mergeEntities({ recentEntities: recentDataSources, allEntities: workspace.dataSources });
  return createDataSourceEntries(entries);
};

const createSampleDataSinkEntries = (workspace: IWorkspace): IPropertyListItem[] => {
  const { dataSinkEntryMap, dataSinkEntries } = workspace.dataConnection;
  const recentDataSinkEntries = appStore.env.recentDataSinkEntryIds.map(id => dataSinkEntryMap.get(id)).filter(isDefined);
  const entries = mergeEntities({ recentEntities: recentDataSinkEntries, allEntities: dataSinkEntries });
  return createDataSinkEntries(entries);
};

const createSampleAnalysisOutputEntries = (workspace: IWorkspace): IPropertyListItem[] => {
  const recentDataSources = appStore.env.recentAnalysisOutputIds.map(id => workspace.analysis.analysisOutputMap.get(id)).filter(isDefined);
  const entries = mergeEntities({ recentEntities: recentDataSources, allEntities: workspace.analysis.analysisOutputs });
  return createAnalysisOutputEntries(entries);
};

export const createSampleEntries = (workspace: IWorkspace, params: IPropertyReferenceSuggestionParams): IPropertyListItem[] => {
  return [
    ...createSamplePropertyEntries(workspace, params),
    ...createSampleDataSourceEntries(workspace),
    ...createSampleDataSinkEntries(workspace),
    ...createSampleAnalysisOutputEntries(workspace),
  ];
};

export interface IPropertyReferenceSuggestionParams {
  referenceProperty?: IPropertyInstance;
  showDetachedProperties?: boolean;
  onSuggestOpen?: () => void;
  onSuggestClosed?: () => void;
  onDataSourceSelect: (dataSourceId: string) => void;
}
