import { observer } from "mobx-react";

import CommentsPanel from "@components/CommentsPanel/CommentsPanel";
import { RightSidePanels } from "@components/RightSidePanels";
import { ICommentFeed } from "@store/CommentFeedStore";
import { FeatureSidePanel } from "@store/SidePanelStore";

interface IFeatureRightSidePanelsProps {
  commentFeed: ICommentFeed;
  childrenIds: string[];
  pinnedSidePanels: FeatureSidePanel[];
  activeSidePanel?: FeatureSidePanel;
  onClearCommentHistory(): void;
}

const FeatureRightSidePanels = (props: IFeatureRightSidePanelsProps) => {
  const { commentFeed, activeSidePanel, pinnedSidePanels, childrenIds, onClearCommentHistory } = props;

  const renderPanel = (panel?: FeatureSidePanel) => {
    switch (panel) {
      case FeatureSidePanel.Comments:
        return <CommentsPanel commentFeed={commentFeed} additionalParentIds={childrenIds} onClearCommentHistory={onClearCommentHistory} />;
      default:
        return null;
    }
  };
  return (
    <RightSidePanels<FeatureSidePanel> activeSidePanel={activeSidePanel} pinnedSidePanels={pinnedSidePanels} renderPanel={renderPanel} />
  );
};

export default observer(FeatureRightSidePanels);
