import { CustomCellRendererProps } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";

import RichTextCell from "@components/Table/CellRenderers/RichTextCell";
import { withObservedValueGetter } from "@utilities/AgGridUtils";
import { mergeE2EIdentifiers } from "@utilities/E2EUtils";

import styles from "./TextCellRenderer.module.scss";

export interface ITextCellRendererOwnProps {
  className?: string;
  useRichText?: boolean;
  e2eIdentifiers?: string[];
}

interface ITextCellRendererProps extends ITextCellRendererOwnProps, CustomCellRendererProps<any, string> {}

export const TextCellRenderer = (props: ITextCellRendererProps) => {
  const { className, useRichText, value, e2eIdentifiers } = props;

  if (useRichText) {
    return <RichTextCell className={className} content={value ?? ""} e2eIdentifiers={mergeE2EIdentifiers(e2eIdentifiers, value ?? "")} />;
  }

  return <p className={classNames(styles.textCellRenderer, className)}>{value}</p>;
};

export default withObservedValueGetter(observer(TextCellRenderer));
