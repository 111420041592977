import { useCallback, useEffect } from "react";
import { GridApi } from "ag-grid-community";

import appStore from "@store/AppStore";

// Hooks that scrolls the grid to a specific column when the grid columns change
export const useScrollGridToColumn = (gridApi?: GridApi) => {
  const { setColIdToBeScrolledTo, colIdToBeScrolledTo } = appStore.ui;

  const handleGridColumnsChanged = useCallback(() => {
    const targetColumn = gridApi?.getColumns()?.find(column => column.getColId() === colIdToBeScrolledTo);
    if (targetColumn) {
      gridApi?.ensureColumnVisible(targetColumn, "end");
      setColIdToBeScrolledTo("");
    }
  }, [colIdToBeScrolledTo, gridApi, setColIdToBeScrolledTo]);

  useEffect(() => {
    if (gridApi && !gridApi.isDestroyed()) {
      gridApi.addEventListener("gridColumnsChanged", handleGridColumnsChanged);
    }
    return () => {
      if (gridApi && !gridApi.isDestroyed()) {
        gridApi?.removeEventListener("gridColumnsChanged", handleGridColumnsChanged);
      }
    };
  }, [gridApi, handleGridColumnsChanged]);

  return setColIdToBeScrolledTo;
};
