import { useEffect, useState } from "react";
import { FormGroup, TextArea } from "@blueprintjs/core";
import { Editor, useMonaco } from "@monaco-editor/react";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";
import type * as Monaco from "monaco-editor";
import { KeyCode, KeyMod } from "monaco-editor";

import { ExecutionTypeIcon } from "@components/Analysis";
import ExecutionArtifactThumbnail from "@components/Analysis/ExecutionArtifactThumbnail";
import { Button } from "@components/Button";
import Collapsible from "@components/Collapsible/Collapsible";
import { DialogLegacy } from "@components/Dialog";
import { DialogBody } from "@components/DialogBody";
import { DialogFooter } from "@components/DialogFooter";
import { ExecutionEnvironment, TestArtifactInfo } from "@rollup-api/models/execution-environments";
import appStore from "@store/AppStore";
import { getDefaultCode, getLanguageFromType } from "@utilities";
import { rollupClient } from "src/core/api";

import "./TestExecutionDialog.scss";

type TestExecutionDialogProps = {
  environment: ExecutionEnvironment;
  onClose: () => void;
};

const TestExecutionDialog = ({ environment, onClose }: TestExecutionDialogProps) => {
  const [isRunning, setIsRunning] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [inputCode, setInputCode] = useState<string>(getDefaultCode(environment.type));
  const [outputStdOut, setOutputStdOut] = useState<string>("");
  const [outputStdErr, setOutputStdErr] = useState<string>("");
  const [outputExitCode, setOutputExitCode] = useState<number | undefined>();
  const [outputArtifacts, setOutputArtifacts] = useState<TestArtifactInfo[]>([]);

  const monaco = useMonaco();
  useEffect(() => {
    if (monaco) {
      console.debug("Monaco editor loaded successfully");
    }
  }, [monaco]);

  const executeCode = async (code: string) => {
    if (isRunning || !code) {
      return;
    }

    setErrorMessage(undefined);
    setOutputStdOut("");
    setOutputStdErr("");
    setOutputExitCode(undefined);
    setOutputArtifacts([]);
    setIsRunning(true);
    try {
      const response = await rollupClient.analysisModule.executionEnvironments.test(environment.id, { code, logOutput: true });
      setOutputExitCode(response.data?.statusCode);
      setOutputStdOut(response.data?.stdout || "");
      setOutputStdErr(response.data?.stderr || "");
      setOutputArtifacts(response.data?.artifacts || []);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setIsRunning(false);
  };

  const handleRunClicked = () => {
    executeCode(inputCode);
  };

  const onEditorMounted = (editor: Monaco.editor.IStandaloneCodeEditor) => {
    editor.addCommand(KeyMod.CtrlCmd | KeyCode.Enter, () => {
      const value = editor.getValue();
      setInputCode(value);
      executeCode(value);
    });
    editor.focus();
  };

  return (
    <DialogLegacy
      className="test-execution-dialog"
      isOpen
      onClose={onClose}
      title={environment.label}
      icon={<ExecutionTypeIcon type={environment.type} />}
    >
      <DialogBody>
        <FormGroup label="Input code">
          <Editor
            options={{
              minimap: { enabled: false },
              fontFamily: "JetBrains Mono",
              fontLigatures: true,
              scrollBeyondLastLine: false,
              wordWrap: "on",
              automaticLayout: true,
              lineNumbers: "on",
            }}
            height="300px"
            onMount={onEditorMounted}
            language={getLanguageFromType(environment.type)}
            value={inputCode}
            onChange={value => setInputCode(value ?? "")}
            theme={appStore.env.themeIsDark ? "vs-dark" : "light"}
          />
        </FormGroup>
        <FormGroup label="Output">
          <TextArea small autoResize className="test-execution-dialog--output-stdout" value={outputStdOut} readOnly fill />
        </FormGroup>
        <FormGroup label="Error log">
          <TextArea small autoResize className="test-execution-dialog--output-stderr" value={errorMessage ?? outputStdErr} readOnly fill />
        </FormGroup>
        {!!outputExitCode && <Text variant={TextVariant.Body}>Exited with code {outputExitCode}</Text>}
        {outputArtifacts.length > 0 && (
          <Collapsible title="Output images" icon="scatter-plot">
            <div className="test-execution-dialog--output-artifacts">
              {outputArtifacts.map((artifact, index) => (
                <ExecutionArtifactThumbnail artifact={artifact} key={`${artifact.filename}-${index}`} />
              ))}
            </div>
          </Collapsible>
        )}
      </DialogBody>
      <DialogFooter>
        <Button onClick={onClose} e2eIdentifiers="close-test-dialog">
          Close
        </Button>
        <Button
          intent="primary"
          icon="play"
          e2eIdentifiers="run-test"
          onClick={handleRunClicked}
          disabled={isRunning || !inputCode}
          loading={isRunning}
        >
          Run
        </Button>
      </DialogFooter>
    </DialogLegacy>
  );
};

export default observer(TestExecutionDialog);
