export type MicrosoftTeamsChannel = {
  id: string;
  name: string;
};

export type MicrosoftTeamsChannelSubscription = {
  id: string;
  name?: string;
  entityTypes: MicrosoftTeamsEventTypes[];
};

export type MicrosoftTeam = {
  teamId: string;
  teamName: string;
  channels: MicrosoftTeamsChannel[];
};

export type MicrosoftTeamsLinkedTeam = {
  channels: MicrosoftTeamsChannelSubscription[];
  teamId: string;
  teamName: string;
};

export type MicrosoftTeamsLinkChannelDto = {
  channelId: string;
  teamId: string;
  entityTypes: MicrosoftTeamsEventTypes[];
};

export enum MicrosoftTeamsEventTypes {
  BLOCK = "block",
  CHILD_BLOCK = "child-block",
  PROPERTY_DEFINITION = "property-definition",
  PROPERTY_INSTANCE = "property-instance",
  STATUS_DEFINITION = "status-definition",
  STATUS_INSTANCE = "status-instance",
  COMMENT = "comment",
  ATTACHMENT = "cloud-storage",
  REPORT = "report",
  REPORT_BLOCK = "report-block",
}

export type MsTeamsIntegrationMetaData = {
  tenantId: string;
  teams: Array<MicrosoftTeamsLinkedTeam>;
};

export const supportMsTeamsEventTypes = Object.values(MicrosoftTeamsEventTypes);

export type MicrosoftTeamsIntegrationResponse = {
  metadata: MsTeamsIntegrationMetaData;
};
