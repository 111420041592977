import { InputRule } from "@tiptap/core";

import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { getContentWithoutOuterTag, getNewBlockContent } from "@utilities/TipTap";

import { focusReportBlockById } from "../../utils";

const regExp = /^> /g;

const quoteInputRule = (reportBlockId: string) => {
  return new InputRule({
    find: regExp,
    handler: props => {
      const { commands } = props;
      const reportBlock = appStore.workspaceModel?.reportBlocksMap.get(reportBlockId);
      if (!reportBlock) {
        return;
      }
      const currentContent = reportBlock.label;
      const contentWithoutOuterTag = getContentWithoutOuterTag(currentContent);
      const newContent = getNewBlockContent(contentWithoutOuterTag.replace(/^> /, ""), RollupEditorType.quote);
      commands.setContent(newContent);
      reportBlock.updateText(newContent);
      reportBlock.updateType(RollupEditorType.quote);
      focusReportBlockById(reportBlockId);
    },
  });
};

export default quoteInputRule;
