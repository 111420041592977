import classNames from "classnames";
import { observer } from "mobx-react";

import type { IBlock } from "@store/BlockStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";

import StatusTypeCheckEditor from "./StatusTypeCheckEditor";
import StatusTypeDateEditor from "./StatusTypeDateEditor";
import StatusTypeMentionEditor from "./StatusTypeMentionEditor";
import StatusTypeMultiSelectEditor from "./StatusTypeMultiSelectEditor";
import StatusTypeNumberEditor from "./StatusTypeNumberEditor";
import StatusTypeTextEditor from "./StatusTypeTextEditor";
import StatusTypeUrlEditor from "./StatusTypeUrlEditor";

import "./StatusInstanceEditor.scss";

type StatusInstanceEditorProps = {
  block?: IBlock | null;
  statusDefinition: IStatusDefinition;
  statusInstance?: IStatusInstance;
  disabled?: boolean;
  readOnly?: boolean;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): Promise<IStatusInstance | undefined>;
  onDeleteStatusInstance(statusInstance: IStatusInstance): void;
};

function StatusInstanceEditor(props: StatusInstanceEditorProps) {
  const { statusDefinition, block, disabled, readOnly } = props;

  if (!block) {
    return null;
  }

  const renderEditor = () => {
    switch (statusDefinition.type) {
      case StatusType.text:
        return <StatusTypeTextEditor {...props} disabled={readOnly} />;
      case StatusType.number:
        return <StatusTypeNumberEditor {...props} readOnly={readOnly} />;
      case StatusType.check:
        return <StatusTypeCheckEditor {...props} disabled={readOnly} />;
      case StatusType.date:
        return <StatusTypeDateEditor {...props} disabled={readOnly} />;
      case StatusType.mention:
        return <StatusTypeMentionEditor {...props} disabled={readOnly} />;
      case StatusType.singleSelect:
        return <StatusTypeMultiSelectEditor {...props} singleSelect disabled={readOnly} />;
      case StatusType.multiSelect:
        return <StatusTypeMultiSelectEditor {...props} disabled={readOnly} />;
      case StatusType.url:
        return <StatusTypeUrlEditor disabled={disabled} block={block} statusDefinition={statusDefinition} readOnly={readOnly} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames("status-instance-editor", {
        "status-instance-editor--hover": !readOnly,
        "status-instance-editor--active": statusDefinition.type === StatusType.text,
      })}
    >
      {renderEditor()}
    </div>
  );
}

export type { StatusInstanceEditorProps };
export default observer(StatusInstanceEditor);
