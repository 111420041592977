import { KeyboardEvent } from "react";
import { EditableText, Intent, Menu } from "@blueprintjs/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { InputGroup } from "@components/InputGroup";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { ENTER_KEY } from "@constants/keys";
import { IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";

import styles from "./AnalysisOutputListItem.module.scss";

type AnalysisOutputListItemProps = {
  analysisOutput: IAnalysisOutput;
  isDragging?: boolean;
  dragListeners?: SyntheticListenerMap;
};

function AnalysisOutputListItem({ analysisOutput, isDragging, dragListeners }: AnalysisOutputListItemProps) {
  const handleDelete = () => {
    analysisOutput.analysis?.deleteOutput(analysisOutput);
  };

  const contextMenu = (
    <Menu>
      <MenuItemDelete onDelete={handleDelete} />
    </Menu>
  );

  const handleKeyDownUnit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      analysisOutput.setUnit(event.currentTarget.value);
    }
  };

  return (
    <div className={classNames("dnd-drag-handle-item", styles.analysisOutputListItem, { [styles.dragging]: isDragging })}>
      <Popover placement="bottom-start" content={contextMenu}>
        <Button {...dragListeners} minimal intent={Intent.NONE} small icon="more" e2eIdentifiers="open-menu" />
      </Popover>
      <EditableText
        key={`${analysisOutput.id}-${analysisOutput.label}`}
        placeholder="Output variable label"
        defaultValue={analysisOutput.label}
        onConfirm={analysisOutput.setLabel}
        confirmOnEnterKey
      />
      <InputGroup
        key={`${analysisOutput.id}-${analysisOutput.unit}`}
        placeholder="Set Unit"
        defaultValue={analysisOutput.unit ?? ""}
        onKeyDown={handleKeyDownUnit}
        e2eIdentifiers="unit"
      />
      <div className={styles.spacer} />
      <InputGroup value={analysisOutput.value ?? ""} placeholder="No output value" e2eIdentifiers="output" readOnly />
    </div>
  );
}

export default observer(AnalysisOutputListItem);
