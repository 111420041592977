import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState } from "react";
import { InputGroupProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Icon } from "@components/Icon";
import { InputGroup } from "@components/InputGroup";
import { Popover } from "@components/Popover";
import { IIcon } from "@rollup-types/icons";

import styles from "./DocumentNameInput.module.scss";

interface IDocumentNameInputProps {
  defaultValue: string;
  className?: string;
  inputClassName?: string;
  iconClassName?: string;
  icon?: IIcon;
  moreNav?: ReactElement;
  inputGroupProps?: InputGroupProps;
  onUpdate(value: string): void;
  onEnter?(): void;
  onEscape?(): void;
  onBlur?(): void;
}

const DocumentNameInput = (props: IDocumentNameInputProps) => {
  const { defaultValue, className, inputClassName, iconClassName, icon, moreNav, inputGroupProps } = props;
  const { onUpdate, onEnter, onEscape, onBlur } = props;
  const [nameInputValue, setNameInputValue] = useState(defaultValue);

  const resetValue = () => setNameInputValue(defaultValue);

  useEffect(() => {
    if (nameInputValue !== defaultValue) {
      resetValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setNameInputValue(event.target.value);

  const handleInputFocus = (event: ChangeEvent<HTMLInputElement>) => event.target.select();

  const updateLabel = () => {
    const trimmedValue = nameInputValue.trim();

    if (trimmedValue) {
      onUpdate(trimmedValue);
    } else {
      resetValue();
    }
  };

  const handleNameInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateLabel();
      onEnter?.();
    } else if (event.key === "Escape") {
      resetValue();
      onEscape?.();
    }
  };

  const handleBlur = () => {
    updateLabel();
    onBlur?.();
  };

  return (
    <div className={classNames(styles.documentNameInput, className)}>
      {icon && <Icon className={iconClassName} icon={icon} />}
      <InputGroup
        className={classNames(styles.documentNameInputInputGroup, inputClassName)}
        onKeyUp={handleNameInputKeyUp}
        onBlur={handleBlur}
        value={nameInputValue}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        e2eIdentifiers="document-name-input"
        {...inputGroupProps}
      />
      {moreNav && (
        <Popover content={moreNav} position="bottom-right">
          <Button small minimal icon="more" e2eIdentifiers={["nav-link-as-menu-item", "more"]} />
        </Popover>
      )}
    </div>
  );
};

export default observer(DocumentNameInput);
