import debounce from "lodash/debounce";
import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import {
  RequirementAutoNumbering,
  RequirementAutoNumberingFormat,
  RequirementNumberingType,
  RequirementsSettingsModel,
} from "@rollup-api/models/workspace-settings/requirementSettings.model";
import { updateRequirementsSettings } from "@rollup-api/utils";

const DEBOUNCE_DELAY = 1000;
export const DOC_ID_PLACEHOLDER = "{{docId}}";
// TODO remove this in future, as this is only backwards compatibility
//  https://linear.app/rollup/issue/ENG-4617/remove-logic-to-sanitize-numberingschema-in
export const DOC_NUMBER_PLACEHOLDER = "{{docNumber}}";
export const REQ_NUMBER_PLACEHOLDER = "{{reqNumber}}";

export const RequirementsSettingsStore = types
  .model("RequirementsSettings", {
    numberingType: types.optional(types.enumeration(Object.values(RequirementNumberingType)), RequirementNumberingType.Static),
    autoNumbering: types.optional(types.enumeration(Object.values(RequirementAutoNumbering)), RequirementAutoNumbering.Enabled),
    autoNumberingFormat: types.optional(
      types.enumeration(Object.values(RequirementAutoNumberingFormat)),
      RequirementAutoNumberingFormat.IntegerIncrement
    ),
    numberingSchema: types.optional(types.string, ""),
  })
  .views(self => ({
    get isStaticNumberingType() {
      return self.numberingType === RequirementNumberingType.Static;
    },
    get isDynamicNumberingType() {
      return self.numberingType === RequirementNumberingType.Dynamic;
    },
    get isAutoNumberingDisabled() {
      return self.autoNumbering === RequirementAutoNumbering.Disabled;
    },
  }))
  .actions(() => ({
    updateRequirementsSettingsDebounced: debounce((dto: RequirementsSettingsModel) => {
      updateRequirementsSettings(dto);
    }, DEBOUNCE_DELAY),
  }))
  .actions(self => ({
    setAutoNumbering(autoNumbering: RequirementAutoNumbering, disableNotification?: boolean) {
      self.autoNumbering = autoNumbering;

      if (!disableNotification) {
        updateRequirementsSettings({ autoNumbering });
      }
    },
    setAutoNumberingFormat(autoNumberingFormat: RequirementAutoNumberingFormat, disableNotification?: boolean) {
      self.autoNumberingFormat = autoNumberingFormat;

      if (!disableNotification) {
        updateRequirementsSettings({ autoNumberingFormat });
      }
    },
    setNumberingType(numberingType: RequirementNumberingType, disableNotification?: boolean) {
      self.numberingType = numberingType;

      if (!disableNotification) {
        updateRequirementsSettings({ numberingType });
      }
    },
    setNumberingSchema(numberingSchema: string, disableNotification?: boolean) {
      self.numberingSchema = numberingSchema;

      if (!disableNotification) {
        self.updateRequirementsSettingsDebounced({ numberingSchema });
      }
    },
  }));

export interface IRequirementsSettings extends Instance<typeof RequirementsSettingsStore> {}
export interface IRequirementsSettingsSnapshotIn extends SnapshotIn<typeof RequirementsSettingsStore> {}
interface IRequirementsSettingsSnapshotOut extends SnapshotOut<typeof RequirementsSettingsStore> {}
export interface IRequirementsSettingsMobxType
  extends IType<IRequirementsSettingsSnapshotIn, IRequirementsSettingsSnapshotOut, IRequirementsSettings> {}
