import { Attribute, AttributeUpdateDto, CreateAttributeDto } from "@rollup-api/models";

import { HttpClient, ParentClient } from "./client";

export class Attributes extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/attributes`;
  }

  public create = (dto: CreateAttributeDto) => {
    return this.instance.post<Attribute>(`${this.endpoint}`, dto);
  };

  public update = (id: string, dto: AttributeUpdateDto) => {
    return this.instance.patch<Attribute>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<Attribute>(`${this.endpoint}/${id}`);
  };

  public reorder = (id: string, destinationId: string) => {
    return this.instance.patch(`${this.endpoint}/${id}/reorder`, { destinationId });
  };
}
