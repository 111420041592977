import { ChangeEvent, DragEvent, MouseEvent, useRef, useState } from "react";
import { Icon } from "@blueprintjs/core";
import useFileUploadMessage from "@hooks/useFileUploadMessage";
import classNames from "classnames";
import { observer } from "mobx-react";

import { ALLOWED_FILE_UPLOAD_SIZE, validateFiles } from "@utilities";
import { createDataTestId, ElementType } from "@utilities/E2EUtils";

import { IFileUploadProps } from "./types";

import "./FileDropZone.scss";

const FileDropZone = (props: IFileUploadProps) => {
  const { format, children, clickToSelect, onChange, multiple = false, disabled, sizeLimit } = props;
  const { showSizeErrorMessage, showFormatErrorMessage } = useFileUploadMessage();
  const [files, setFiles] = useState<FileList>();
  const [isOver, setIsOver] = useState(false);
  const fileDropZoneRef = useRef<HTMLDivElement>(null);

  const handleDragEnter = () => {
    if (!disabled) {
      setIsOver(true);
    }
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    // check if we are leaving the file drop zone entirely
    if (!disabled) {
      const isChildElement = fileDropZoneRef.current?.contains(e.relatedTarget as Node);
      if (!isChildElement) {
        setIsOver(false);
      }
    }
  };

  const handleChangeEvent = (fileList: FileList) => {
    const allowedFiles = validateFiles(
      fileList,
      format || [],
      sizeLimit || ALLOWED_FILE_UPLOAD_SIZE,
      showSizeErrorMessage,
      showFormatErrorMessage
    );

    if (allowedFiles.length) {
      if (multiple) {
        setFiles(allowedFiles);
        onChange?.(allowedFiles);
      } else {
        const singleFileList = new DataTransfer();
        singleFileList.items.add(allowedFiles[0]);
        setFiles(singleFileList.files);
        onChange?.(singleFileList.files);
      }
    }
  };

  const handleInputClick = (e: MouseEvent<HTMLInputElement>) => {
    if (!clickToSelect) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleChangeEvent(e.target.files);
      e.target.value = "";
    }
  };

  const handleFileDrop = (e: DragEvent<HTMLDivElement>) => {
    if (!disabled) {
      handleChangeEvent(e.dataTransfer.files);
      e.preventDefault();
      e.stopPropagation();
      setIsOver(false);
    }
  };

  const renderOverlap = () => {
    return (
      <div className="file-drop-zone--overlap">
        <Icon size={props.dropIconSize || 70} icon="document-open" />
      </div>
    );
  };

  const renderContent = () => (
    <div
      className="file-drop-zone"
      onDrop={handleFileDrop}
      onDragOver={handleDragEnter}
      onDragLeave={e => handleDragLeave(e)}
      ref={fileDropZoneRef}
    >
      {isOver && renderOverlap()}
      <input
        onClick={handleInputClick}
        onChange={handleInputChange}
        {...createDataTestId(ElementType.Input, "file-drop-zone")}
        {...(format && { accept: format?.join(", ") })}
        className={classNames("file-drop-zone--input", {
          ["cursor-pointer"]: clickToSelect,
          ["file-drop-zone--input-active"]: isOver || clickToSelect,
        })}
        type="file"
        multiple={multiple}
      />
      {typeof children === "function" ? children({ isOver, files }) : children}
    </div>
  );

  return renderContent();
};

export default observer(FileDropZone);
