import { useState } from "react";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";
import { v4 as uuidV4 } from "uuid";

import { Button } from "@components/Button";
import CollapsibleCard from "@components/CatalogItemPreview/Components/CollapsibleCard";
import { EFileFormat } from "@components/FileDropZone";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { PdmCard } from "@store/PdmCardsVisibilityStore";
import { showFileBrowser } from "@utilities";
import { isFieldDefined } from "@utilities/TypeGuards";

import { AttachmentCardItem } from "../AttachmentCardItem";

import styles from "./AttachmentsCard.module.scss";

type Props = {
  catalogItem: ICatalogItem;
  onVersionChange: (versionId: string) => void;
  isVertical?: boolean;
  disabled?: boolean;
};

const AttachmentsCard = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { catalogItem, isVertical, onVersionChange, disabled } = props;

  const handleFileSelect = async () => {
    const file = await showFileBrowser([EFileFormat.PRT, EFileFormat.SLDASM]);

    if (file) {
      setLoading(true);
      const attachmentId = await appStore.orgModel.attachments.add({ label: file.name }, file);
      if (attachmentId) {
        const id = uuidV4();
        catalogItem.setMainAttachmentId(attachmentId);
        await appStore.orgModel.catalogItems.createCatalogItemVersion({ id, catalogItemId: catalogItem.id, attachmentId });
        onVersionChange(id);
      }
      setLoading(false);
    }
  };

  return (
    <CollapsibleCard
      minWidth={240}
      visible={isVertical ? true : appStore.env.pdmCardVisibility.attachments}
      onToggle={() => appStore.env.pdmCardVisibility.toggleCard(PdmCard.Attachments)}
      height={isVertical ? 275 : undefined}
      className={styles.attachmentsCard}
      disabled={isVertical}
      title="Attachments"
      rightActions={
        disabled ? undefined : (
          <Button
            disabled={loading}
            loading={loading}
            onClick={handleFileSelect}
            className={styles.attachmentsCardAdd}
            minimal
            e2eIdentifiers="add-attachment"
            icon="add"
          >
            Add
          </Button>
        )
      }
    >
      <>
        {loading && <Text variant={TextVariant.Body}>Uploading new version...</Text>}
        {catalogItem.sortedVersions.filter(isFieldDefined("attachment")).map((version, index) => {
          const isMainAttachment = catalogItem.mainAttachmentId ? version.attachment.id === catalogItem.mainAttachmentId : index === 0;
          return (
            <AttachmentCardItem
              key={version.id}
              isMainAttachment={isMainAttachment}
              attachment={version.attachment}
              versionIndex={version.index}
              onMarkAsMain={catalogItem.setMainAttachmentId}
            />
          );
        })}
      </>
    </CollapsibleCard>
  );
};

export default observer(AttachmentsCard);
