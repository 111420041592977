import { useCallback, useEffect, useState } from "react";
import { InputGroup, Label, Radio, RadioGroup, TextArea } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import useCatalogItemPartNumber from "@hooks/useCatalogItemPartNumber";
import { SelectItem } from "@ui/Select/Item/SelectItem";
import Text, { TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import CollapsibleCard from "@components/CatalogItemPreview/Components/CollapsibleCard";
import { useCatalogItemImageUrl } from "@components/CatalogItems/useCatalogItemImage";
import CatalogItemStatusSelect from "@components/CatalogItemStatusSelect";
import ImageUploaderCard from "@components/ImageUploaderCard";
import { Loading } from "@components/Loading";
import PartNumber from "@components/PartNumber";
import StatusTag from "@components/StatusTag";
import { CatalogItemSource, CatalogItemType } from "@rollup-api/models/catalogItem/catalogItemDtos";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { PdmCard } from "@store/PdmCardsVisibilityStore";
import { getAssetLink } from "@utilities/LinkUtils";

import styles from "./OverviewCard.module.scss";

type Props = {
  catalogItem: ICatalogItem;
  isVertical?: boolean;
  disabled?: boolean;
};

const OverviewCard = (props: Props) => {
  const { catalogItem, isVertical, disabled } = props;
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(catalogItem.name);
  const [type, setType] = useState(catalogItem.type);
  const [imageUrl, imageLoading] = useCatalogItemImageUrl(catalogItem);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [status, setStatus] = useState(catalogItem.status);
  const [source, setSource] = useState(catalogItem.source || CatalogItemSource.InHouse);
  const [description, setDescription] = useState(catalogItem.description || "");
  const {
    setPartNumber,
    partNumber,
    partNumberSchemaId,
    handleDeleteSchema,
    handleSetFromSchema,
    handleSetFromString,
    handleAddNewSchema,
  } = useCatalogItemPartNumber(catalogItem.partNumber || "");

  const setDefaults = useCallback(() => {
    if (catalogItem) {
      setName(catalogItem.name);
      setType(catalogItem.type);
      setStatus(catalogItem.status);
      setSource(catalogItem.source || CatalogItemSource.InHouse);
      setPartNumber(catalogItem.partNumber || "");
      setDescription(catalogItem.description || "");
      setUploadedImageUrl(catalogItem.imageUrl || "");
    }
  }, [catalogItem, setPartNumber]);

  useEffect(() => {
    setDefaults();
  }, [setDefaults, catalogItem]);

  useEffect(() => {
    setDescription(catalogItem.description || "");
  }, [catalogItem.description]);

  useEffect(() => {
    setPartNumber(catalogItem.partNumber || "");
  }, [setPartNumber, catalogItem.partNumber]);

  useEffect(() => {
    setType(catalogItem.type);
  }, [catalogItem.type]);

  useEffect(() => {
    setStatus(catalogItem.status);
  }, [catalogItem.status]);

  useEffect(() => {
    setSource(catalogItem.source || CatalogItemSource.InHouse);
  }, [catalogItem.source]);

  const toggleEditMode = () => setEditMode(!editMode);

  const handleCancel = () => {
    setDefaults();
    toggleEditMode();
  };

  const handleSave = () => {
    appStore.orgModel.catalogItems.updateCatalogItem(catalogItem.id, {
      name: name || catalogItem.name,
      type,
      ...(status && { status }),
      partNumber,
      partNumberSchemaId,
      source,
      description,
      imageUrl: uploadedImageUrl,
    });
    if (partNumberSchemaId) {
      const partNumberSchema = appStore.orgModel.partNumberSchemas.get(partNumberSchemaId);
      partNumberSchema && partNumberSchema.patch({ currentCount: (partNumberSchema.currentCount || 0) + 1 });
    }
    toggleEditMode();
  };

  const renderImage = () => {
    if (imageLoading) {
      return <Loading className={styles.overviewCardImage} size={96} />;
    } else if (imageUrl) {
      return <div className={styles.overviewCardImage} style={{ backgroundImage: `url("${getAssetLink(imageUrl)}")` }} />;
    }

    return null;
  };

  const renderContent = () => {
    if (editMode) {
      return (
        <div className={classNames(styles.overviewCardContent)}>
          <div className={styles.overviewCardEditData}>
            <Label>
              <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
                Part number
              </Text>
              <PartNumber
                asInput
                onSchemaDelete={handleDeleteSchema}
                partNumberSchemas={appStore.orgModel.partNumberSchemas.values}
                partNumber={partNumber}
                onAddNewSchema={handleAddNewSchema}
                onUpdateFromString={handleSetFromString}
                onUpdateFromSchema={handleSetFromSchema}
              />
            </Label>
            <Label>
              <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
                Part name
              </Text>
              <InputGroup value={name} onChange={e => setName(e.target.value)} />
            </Label>
            <div className={styles.overviewCardRow}>
              <Label>
                <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
                  Part type
                </Text>
                <Select<CatalogItemType>
                  filterable={false}
                  items={Object.values(CatalogItemType)}
                  popoverProps={{ minimal: true }}
                  onItemSelect={item => setType(item)}
                  itemRenderer={(item, { handleClick }) => (
                    <SelectItem slug={item} key={item} label={item} onClick={handleClick} active={type === item} />
                  )}
                >
                  <Button
                    alignText="left"
                    fill
                    rightIcon="chevron-down"
                    e2eIdentifiers="part-type-select"
                    className={styles.overviewCardType}
                  >
                    {type}
                  </Button>
                </Select>
              </Label>
              <CatalogItemStatusSelect status={status} onStatusChange={setStatus} />
            </div>
            <RadioGroup
              className={styles.overviewCardRadioGroup}
              onChange={e => setSource(e.currentTarget.value as CatalogItemSource)}
              selectedValue={source}
            >
              {Object.values(CatalogItemSource).map(source => (
                <Radio key={source} label={source} value={source} />
              ))}
            </RadioGroup>
            <Label>
              <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
                Description
              </Text>
              <TextArea
                className={styles.overviewCardTextArea}
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Enter a description..."
              />
            </Label>
          </div>
          <ImageUploaderCard
            className={styles.overviewCardImageUploader}
            height={214}
            src={uploadedImageUrl || imageUrl}
            onSrcChange={setUploadedImageUrl}
          />
        </div>
      );
    }

    return (
      <div className={styles.overviewCardContent}>
        <div className={styles.overviewCardData}>
          <div className={styles.overviewCardRow}>
            <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
              Part number:
            </Text>
            <Text variant={TextVariant.Body}>{partNumber}</Text>
          </div>
          <div className={styles.overviewCardRow}>
            <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
              Part name:
            </Text>
            <Text variant={TextVariant.Body}>{name}</Text>
          </div>
          <div className={styles.overviewCardRow}>
            <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
              Part type:
            </Text>
            <Text variant={TextVariant.Body}>{type}</Text>
          </div>
          {status && (
            <div className={styles.overviewCardRow}>
              <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
                Status:
              </Text>
              <StatusTag status={status} />
            </div>
          )}
          <div className={styles.overviewCardRow}>
            <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
              Made:
            </Text>
            <Text variant={TextVariant.Body}>{source}</Text>
          </div>
          <div className={classNames(styles.overviewCardRow, styles.overviewCardDescription)}>
            <Text className={styles.overviewCardLabel} variant={TextVariant.Caption}>
              Description:
            </Text>
            <Text variant={TextVariant.Body}>{description}</Text>
          </div>
        </div>
        {renderImage()}
      </div>
    );
  };

  const renderRightActions = () => {
    if (disabled) {
      return;
    }

    if (editMode) {
      return (
        <>
          <Button onClick={handleCancel} minimal e2eIdentifiers="cancel-overview">
            Cancel
          </Button>
          <Button onClick={handleSave} intent="primary" e2eIdentifiers="save-overview">
            Save
          </Button>
        </>
      );
    }

    return (
      <Button onClick={toggleEditMode} className={styles.overviewCardEdit} minimal e2eIdentifiers="edit-overview" icon="edit">
        Edit
      </Button>
    );
  };

  return (
    <CollapsibleCard
      visible={isVertical ? true : appStore.env.pdmCardVisibility.overview}
      onToggle={() => appStore.env.pdmCardVisibility.toggleCard(PdmCard.Overview)}
      disabled={isVertical}
      className={classNames(styles.overviewCard, { [styles.overviewCardVertical]: isVertical, [styles.overviewCardEditMode]: editMode })}
      rightActions={renderRightActions()}
      title="Overview"
      tooltip="This Column is an item's overview. Changing fields here will not result in a new version or revision."
    >
      {renderContent()}
    </CollapsibleCard>
  );
};

export default observer(OverviewCard);
