import { useUser } from "@frigade/react";
import { useOnMount } from "@hooks/useOnMount";
import { useSingleFileUpload } from "@hooks/useSingleFileUpload";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import FileDropZone, { EFileFormat } from "@components/FileDropZone";
import { Loading } from "@components/Loading";
import { useBlockViewRTSubscriptions } from "@router/components/BlockView/useBlockViewRTSubscriptions";
import appStore from "@store/AppStore";
import { EBlockTab, IBlock } from "@store/BlockStore";
import { FRIGADE_API_KEY } from "src/lib/Frigade/constants";
import FrigadeSurvey from "src/lib/Frigade/Survey";
import { GOVCLOUD_MODE } from "src/lib/GovCloud";

import BlockViewTabs from "./BlockViewTabs/BlockViewTabs";
import BlockViewHeader from "./BlockViewHeader";

import "./BlockView.scss";

/** Type defs. */
type BlockViewProps = {
  block: IBlock;
};

/** Main function. */
function BlockView({ block }: BlockViewProps) {
  const { track } = useUser();
  const isCommentsTabActive = appStore.env.currentBlockTab === EBlockTab.COMMENTS;
  const workspace = useWorkspace();

  const { isLoading, handleFileUpload: handleCommentImageUpload } = useSingleFileUpload({
    blockId: block.id,
    workspaceId: workspace.id,
    onUploadSuccess: link => block.ui.setDiscussionDraggedImageLink(link),
  });

  const handleDraggedFile = (files: FileList) => {
    if (isCommentsTabActive) {
      handleCommentImageUpload(files);
    } else {
      appStore.workspaceModel?.initiateFileUploadOnDrop(files, block.id);
    }
  };

  useOnMount(() => {
    if (FRIGADE_API_KEY && !GOVCLOUD_MODE) {
      track("BlockView", { blockId: block.id });
    }
  });

  useBlockViewRTSubscriptions({ workspaceId: workspace.id, blockId: block.id, propertyInstanceIds: block.propertyInstanceIds });

  const renderBlockViewContent = () => (
    <div className="component--block-view">
      <BlockViewHeader block={block} />
      <BlockViewTabs block={block} />
    </div>
  );

  return (
    <div className="block-view--container">
      {isLoading && <Loading overlay />}
      <FrigadeSurvey />
      {/* we allow only block view to accept files, so we do not conflict with PM statuses image file drop zone */}
      <FileDropZone
        multiple
        disabled={!block.ui.blockViewFileDropZoneEnabled}
        onChange={handleDraggedFile}
        format={isCommentsTabActive ? [EFileFormat.JPEG, EFileFormat.JPG, EFileFormat.PNG] : undefined}
      >
        {renderBlockViewContent()}
      </FileDropZone>
    </div>
  );
}

export default observer(BlockView);
