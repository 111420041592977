import { BlockIcon, ICustomIconName, ModuleMonochromeIcon } from "@components/CustomIcon/CustomIcon.types";

type IActiveOnlyIcon = ModuleMonochromeIcon.Chat | ModuleMonochromeIcon.Feedback | ModuleMonochromeIcon.Documentation;
const activeOnlyIcons: IActiveOnlyIcon[] = [ModuleMonochromeIcon.Chat, ModuleMonochromeIcon.Feedback, ModuleMonochromeIcon.Documentation];
// these are the icons that have an active icon version
type IActiveIcon = BlockIcon | IActiveOnlyIcon;
const activeIcons: IActiveIcon[] = [...Object.values(BlockIcon), ...activeOnlyIcons];

export const hasActiveIcon = (icon: ICustomIconName): icon is IActiveIcon => {
  return activeIcons.includes(icon as IActiveIcon);
};

export const isActiveOnlyIcon = (icon: ICustomIconName): icon is IActiveOnlyIcon => {
  return activeOnlyIcons.includes(icon as IActiveOnlyIcon);
};
