import { ChangeEvent } from "react";
import { Alignment, Checkbox, Menu, MenuDivider } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { BlockIcon, CustomIcon } from "@components/CustomIcon";
import { Tooltip } from "@components/Tooltip";
import { BlockType, IBlock } from "@store/BlockStore";

import styles from "./BlockTypeSelectorMenu.module.scss";

type BlockIconSelectorMenuProps = {
  block: IBlock;
};

function BlockTypeSelectorMenu({ block }: BlockIconSelectorMenuProps) {
  return (
    <Menu className={styles.blockTypeSelectorMenu}>
      <MenuDivider title="Set Block type" />
      <Tooltip
        popoverClassName={styles.blockTypeSelectorMenuTooltip}
        content="Products are self-contained things that can be packaged and distributed"
      >
        <Checkbox
          className={styles.blockTypeSelectorMenuCheckbox}
          alignIndicator={Alignment.RIGHT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => block.toggleType(BlockType.Product, e.target.checked)}
          checked={block.type.includes(BlockType.Product)}
        >
          <div className={styles.blockTypeSelectorMenuCheckboxContent}>
            <CustomIcon icon={BlockIcon.Product} />
            <Text className={styles.blockTypeSelectorMenuLabel} variant={TextVariant.BodyDimmed}>
              Product
            </Text>
          </div>
        </Checkbox>
      </Tooltip>
      <Tooltip
        popoverClassName={styles.blockTypeSelectorMenuTooltip}
        content="Assemblies are physical systems composed of parts and sub-assemblies"
      >
        <Checkbox
          className={styles.blockTypeSelectorMenuCheckbox}
          alignIndicator={Alignment.RIGHT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => block.toggleType(BlockType.Assembly, e.target.checked)}
          checked={block.type.includes(BlockType.Assembly)}
        >
          <div className={styles.blockTypeSelectorMenuCheckboxContent}>
            <CustomIcon icon={BlockIcon.Assembly} />
            <Text className={styles.blockTypeSelectorMenuLabel} variant={TextVariant.BodyDimmed}>
              Assembly
            </Text>
          </div>
        </Checkbox>
      </Tooltip>
      <Tooltip
        popoverClassName={styles.blockTypeSelectorMenuTooltip}
        content="Subsystems are composed of smaller elements, that are related"
      >
        <Checkbox
          className={styles.blockTypeSelectorMenuCheckbox}
          alignIndicator={Alignment.RIGHT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => block.toggleType(BlockType.Subsystem, e.target.checked)}
          checked={block.type.includes(BlockType.Subsystem)}
        >
          <div className={styles.blockTypeSelectorMenuCheckboxContent}>
            <CustomIcon icon={BlockIcon.Subsystem} />
            <Text className={styles.blockTypeSelectorMenuLabel} variant={TextVariant.BodyDimmed}>
              Subsystem
            </Text>
          </div>
        </Checkbox>
      </Tooltip>
      <Tooltip
        popoverClassName={styles.blockTypeSelectorMenuTooltip}
        content="A part is an atomic physical object. They cannot have children."
      >
        <Checkbox
          className={styles.blockTypeSelectorMenuCheckbox}
          disabled={!!block.children?.length}
          alignIndicator={Alignment.RIGHT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => block.toggleType(BlockType.Part, e.target.checked)}
          checked={block.type.includes(BlockType.Part)}
        >
          <div className={styles.blockTypeSelectorMenuCheckboxContent}>
            <CustomIcon icon={BlockIcon.Part} disabled />
            <Text className={styles.blockTypeSelectorMenuLabel} variant={TextVariant.BodyDimmed}>
              Part
            </Text>
          </div>
        </Checkbox>
      </Tooltip>
    </Menu>
  );
}

export default observer(BlockTypeSelectorMenu);
