import { Tag as BPTag, TagProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Color } from "@rollup-types/colors";
import { getBgColorClassName } from "@utilities";

import styles from "./Tag.module.scss";

interface ITagProps extends TagProps {
  color?: Color;
}

const Tag = (props: ITagProps) => {
  const { className, color, ...restProps } = props;
  const bgColorClassName = getBgColorClassName(color);

  return <BPTag {...restProps} className={classNames(styles.tag, className, bgColorClassName)} />;
};

export default observer(Tag);
