export type IE2EIdentifiers = Array<string | number> | string | number;

export interface IE2EIdentifiersParam {
  e2eIdentifiers: IE2EIdentifiers;
}

export type IE2EDataTag = { ["data-testid"]: string };

export enum ElementType {
  Button = "button",
  ControlOption = "control-option",
  NavLink = "nav-link",
  MenuItem = "menu-item",
  Input = "input",
  EditorContent = "editor-content",
  Icon = "icon",
  TableRow = "table-row",
  // TODO create local Switch and Checkbox components to enforce that all their usages in Rollup provide e2eIdentifiers (ENG-1798)
  Switch = "switch",
  Checkbox = "checkbox",
  AgCell = "ag-cell",
  SidePanel = "side-panel",
  Tab = "tab",
  Text = "text",
}

export function createTestId(elementType: ElementType, e2eIdentifiers: IE2EIdentifiers): string {
  const e2eIdentifiersArr = Array.isArray(e2eIdentifiers) ? e2eIdentifiers : [e2eIdentifiers];
  return [elementType, ...e2eIdentifiersArr]
    .filter(id => id !== null && id !== undefined && id !== "" && id !== " ")
    .map(id => id.toString().toLowerCase().trim())
    .map(id => id.replaceAll(" ", "-"))
    .join("_");
}

export const dataTestIdTag = "data-testid";

export function createDataTestId(elementType: ElementType, e2eIdentifiers: IE2EIdentifiers): IE2EDataTag {
  const testId = createTestId(elementType, e2eIdentifiers);
  return { [dataTestIdTag]: testId };
}

export function prefixIdentifiers(prefix: string, e2eIdentifiers: IE2EIdentifiers = []) {
  const e2eIdentifiersArr = Array.isArray(e2eIdentifiers) ? e2eIdentifiers : [e2eIdentifiers];
  return [prefix, ...e2eIdentifiersArr];
}

export const mergeE2EIdentifiers = (e2eIdentifiers1: IE2EIdentifiers = [], e2eIdentifiers2: IE2EIdentifiers = []): IE2EIdentifiers => {
  const arr1 = Array.isArray(e2eIdentifiers1) ? e2eIdentifiers1 : [e2eIdentifiers1];
  const arr2 = Array.isArray(e2eIdentifiers2) ? e2eIdentifiers2 : [e2eIdentifiers2];
  return arr1.concat(arr2).filter(Boolean);
};

export const addPrefixToE2EIdentifiers = (e2eIdentifiers: IE2EIdentifiers, e2ePrefix?: string): IE2EIdentifiers => {
  if (e2ePrefix) {
    return [e2ePrefix, ...(Array.isArray(e2eIdentifiers) ? e2eIdentifiers : [e2eIdentifiers])];
  }
  return e2eIdentifiers;
};
