import { BomColumn, BomTable, CreateBomColumnDto, CreateBomTableDto, UpdateBomColumnDto, UpdateBomTableDto } from "../models/bom";

import { HttpClient, ParentClient } from "./client";

export class BomTables extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/bom`;
  }

  public create = (dto: CreateBomTableDto) => {
    return this.instance.post<BomTable>(`${this.endpoint}`, dto);
  };

  public get = (id: string) => {
    return this.instance.get<BomTable>(`${this.endpoint}/${id}`);
  };

  public update = (id: string, dto: UpdateBomTableDto) => {
    return this.instance.patch<BomTable>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<BomTable>(`${this.endpoint}/${id}`);
  };

  public createColumn(tableId: string, createBomColumnDto: CreateBomColumnDto) {
    return this.instance.post<BomColumn>(`${this.endpoint}/${tableId}/column`, createBomColumnDto);
  }

  public removeColumn(id: string, columnId: string) {
    return this.instance.delete<BomColumn>(`${this.endpoint}/${id}/column/${columnId}`);
  }

  public reorderColumn = (tableId: string, colId: string, destinationId: string) => {
    return this.instance.patch(`${this.endpoint}/${tableId}/column/${colId}/reorder`, { destinationId });
  };

  public updateColumn = (tableId: string, colId: string, updateBomColumnDto: UpdateBomColumnDto) => {
    return this.instance.patch(`${this.endpoint}/${tableId}/column/${colId}`, updateBomColumnDto);
  };
}
