import { useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { observer } from "mobx-react";

import Item, { TItemRendererCallBack } from "./Item/Item";

interface SortableItemProps {
  id: string;
  handle: boolean;
  itemComponent: TItemRendererCallBack;
  itemComponentCustomProps?: any;
  isOver?: boolean;
  newItemAbove?: boolean;
  selected?: boolean;
}

const SortableItem = (props: SortableItemProps) => {
  const { id, handle, selected, isOver, newItemAbove, itemComponent, itemComponentCustomProps } = props;
  const { setNodeRef, setActivatorNodeRef, listeners, isDragging } = useSortable({
    id,
  });
  const memoizedListeners = useMemo(
    () => (isDragging ? undefined : listeners),
    // listeners was purposefully omitted from the dependency array, so it doesn't trigger a re-render when adding a new item
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragging]
  );
  const memoizedHandleProps = useMemo(
    () => (handle && !isDragging ? { ref: setActivatorNodeRef } : undefined),
    // setActivatorNodeRef was purposefully omitted from the dependency array, so it doesn't trigger a re-render when adding a new item
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handle, isDragging]
  );

  return (
    <Item
      isOver={isOver}
      newItemAbove={newItemAbove}
      selected={selected}
      ref={setNodeRef}
      value={id}
      dragging={isDragging}
      handleProps={memoizedHandleProps}
      listeners={memoizedListeners}
      itemComponent={itemComponent}
      itemComponentCustomProps={itemComponentCustomProps}
    />
  );
};

export default observer(SortableItem);
