import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useWorkspace } from "@hooks/useWorkspace";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import ReportsTree from "@components/ReportsTree";
import { reportNodeList } from "@components/ReportsTree/constants";
import ReportsTreeMinimal from "@components/ReportsTree/ReportsTreeMinimal";
import { ReportTreeNodeInfo } from "@components/ReportsTree/types";
import { SearchInput } from "@components/SearchInput";
import SearchResults from "@components/SearchResults";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { getReportById, getReportLabelsPath } from "@utilities";

import styles from "./ReportsSidebar.module.scss";

interface IReportsSidebarProps {
  minimal?: boolean;
}

function ReportsSidebar(props: IReportsSidebarProps) {
  const { minimal } = props;
  const [searchValue, setSearchValue] = useState("");
  const { reportId } = useParams();
  const { navigateToReport } = useAppNavigate();
  const workspace = useWorkspace();

  useEffect(() => {
    if (!reportId) {
      const { activeReportId } = appStore.env;
      const targetReportId = activeReportId ? activeReportId : workspace.rootReports[0]?.id;
      const report = targetReportId ? getReportById(targetReportId) : undefined;

      if (report) {
        navigateToReport(report.id);
        appStore.env.setActiveReport(report.id);
      }
    }
  }, [navigateToReport, reportId, workspace.rootReports]);

  const filterByReportLabel = (nodeInfo: ReportTreeNodeInfo) => nodeInfo.report?.label.toLowerCase().includes(searchValue.toLowerCase());

  const handleAddNewReport = async () => {
    if (!appStore.workspaceModel) {
      return;
    }

    const newReport = await appStore.workspaceModel.createReport();
    appStore.env.setActiveReport(newReport.id);
    navigateToReport(newReport.id);
  };

  const searchResult = reportNodeList().filter(filterByReportLabel) as Array<{ report: IReport }>;
  const formattedSearchResult = searchResult.map(r => ({ id: r.report.id, path: getReportLabelsPath(r.report), label: r.report.label }));

  const renderSearch = () => {
    if (minimal) {
      return <BlueprintIcon className={styles.reportsSidebarSearchIcon} icon="search" outline />;
    }

    return <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />;
  };

  return (
    <div className={styles.reportsSidebar}>
      {renderSearch()}
      {searchValue ? (
        <SearchResults activeId={reportId} onClick={navigateToReport} items={formattedSearchResult} />
      ) : (
        <div className={styles.reportsSidebarItemsContainer}>
          {minimal ? <ReportsTreeMinimal reports={workspace.rootReports} /> : <ReportsTree />}
          <Button
            onClick={handleAddNewReport}
            className={styles.reportsSidebarBtn}
            minimal
            alignText="left"
            fill
            icon="add"
            e2eIdentifiers="reports-add-new-page-btn"
          >
            {minimal ? undefined : "Add a page"}
          </Button>
          <Button
            className={styles.reportsSidebarBtn}
            minimal
            alignText="left"
            fill
            onClick={appStore.ui.showReportTemplatesDialog}
            icon="search-template"
            e2eIdentifiers="reports-teamplates-btn"
          >
            {minimal ? undefined : "Templates"}
          </Button>
        </div>
      )}
    </div>
  );
}

/** Exports. */
export default observer(ReportsSidebar);
