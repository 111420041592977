import { MouseEvent } from "react";
import { observer } from "mobx-react";

import { ExecutionTypeIcon } from "@components/Analysis";
import DataSourceIcon from "@components/DataSources/DataSourceIcon/DataSourceIcon";
import { Icon } from "@components/Icon";
import { MenuItem } from "@components/MenuItem";
import { IPropertyListItem } from "@components/Shared/ScalarExpressionEditor/PropertyListTypes";
import appStore from "@store/AppStore";
import { getDataSinkEntryById, getDataSourceById } from "@utilities";

import { PropertyTagType } from "./tags/PropertyTag";

interface IPropertyListItemProps {
  item: IPropertyListItem;
  selectedItem?: IPropertyListItem;
  onClick(event: MouseEvent, item: IPropertyListItem): void;
}

const PropertyListItem = (props: IPropertyListItemProps) => {
  const { item, selectedItem, onClick } = props;
  const workspace = appStore.workspaceModel;

  const renderIcon = () => {
    switch (item.type) {
      case PropertyTagType.DataLink: {
        const dataSource = getDataSourceById(item.id);
        return dataSource ? <DataSourceIcon dataSource={dataSource} /> : null;
      }
      case PropertyTagType.DataSinkEntry: {
        const dataSinkEntry = getDataSinkEntryById(item.id);
        const dataSink = dataSinkEntry?.dataSink;
        return dataSink?.icon ? <Icon icon={dataSink.icon} /> : null;
      }
      case PropertyTagType.AnalysisOutput: {
        const analysisOutput = workspace?.analysis.analysisOutputMap.get(item.id);
        if (!analysisOutput?.analysis) {
          return null;
        }
        return <ExecutionTypeIcon type={analysisOutput.analysis.type} />;
      }
      default:
        return item.icon;
    }
  };

  return (
    <MenuItem
      className="property-menu-item"
      active={item === selectedItem}
      key={item.id}
      onMouseDown={e => onClick(e, item)}
      icon={renderIcon()}
      text={item.extendedLabel ?? item.label}
      e2eIdentifiers={item.label}
    />
  );
};

export default observer(PropertyListItem);
