import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";

interface IUseInfiniteScrollFetchParams {
  onLoadMore: VoidFunction;
  hasMore?: boolean;
  isFetching?: boolean;
  scrollThreshold?: number;
  debounceWait?: number;
  autoFetchOnMount?: boolean;
}

export const useInfiniteScrollFetch = (params: IUseInfiniteScrollFetchParams) => {
  const { onLoadMore, isFetching, hasMore = true, scrollThreshold = 100, debounceWait = 200 } = params;
  const scrollContainerRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);

  const fetchMoreIfAnchorVisible = useCallback(() => {
    if (!scrollContainerRef.current || isFetching || !hasMore) {
      return;
    }
    const { scrollHeight, scrollTop, offsetHeight } = scrollContainerRef.current;
    if (scrollHeight - (scrollTop + offsetHeight) <= scrollThreshold) {
      onLoadMore();
    }
  }, [hasMore, isFetching, onLoadMore, scrollThreshold]);

  const fetchMoreIfNecessaryThrottled = useDebouncedCallback(fetchMoreIfAnchorVisible, debounceWait);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const listRef = scrollContainerRef.current;
      listRef.addEventListener("scroll", fetchMoreIfNecessaryThrottled);

      return () => {
        listRef.removeEventListener("scroll", fetchMoreIfNecessaryThrottled);
      };
    }
  }, [scrollContainerRef, fetchMoreIfNecessaryThrottled]);

  // Load more on mount if scroll is not visible yet
  useEffect(() => {
    if (!scrollContainerRef.current || isFetching || !hasMore) {
      return;
    }

    const { scrollHeight, clientHeight } = scrollContainerRef.current;
    if (scrollHeight <= clientHeight) {
      onLoadMore();
    }
  }, [hasMore, isFetching, onLoadMore]);

  return { scrollContainerRef };
};
