import { Menu, MenuDivider } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { BomMetaColumn } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { getBomColumnLabelFromMetaColumn, getBomMetaColumns } from "@utilities";

import "./CreateNewColumnNav.scss";

const CreateNewColumnNav = () => {
  const workspace = useWorkspace();
  const table = workspace.bomTablesMap.get(`${appStore.env.activeBomTableId}`);

  const handleToggleColumn = (metaColumn: BomMetaColumn) => {
    if (table?.metaColumns.includes(metaColumn)) {
      const columnId = table?.columns.find(c => c.metaColumn === metaColumn)?.id;
      columnId && table.removeColumn(columnId);
    } else {
      table?.addColumn({ metaColumn });
    }
  };

  return (
    <Menu>
      <MenuDivider title="BOM Properties" />
      <MenuItem icon="new-layer" text="Add existing Property" e2eIdentifiers="add-meta-column">
        {getBomMetaColumns().map(metaColumn => (
          <MenuItem
            roleStructure="listoption"
            selected={table?.metaColumns.includes(metaColumn)}
            key={metaColumn}
            text={getBomColumnLabelFromMetaColumn(metaColumn)}
            onClick={e => {
              e.stopPropagation();
              handleToggleColumn(metaColumn);
            }}
            e2eIdentifiers={[getBomColumnLabelFromMetaColumn(metaColumn)]}
          />
        ))}
      </MenuItem>
    </Menu>
  );
};

export default observer(CreateNewColumnNav);
