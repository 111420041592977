import { observer } from "mobx-react";

import CommentsPanel from "@components/CommentsPanel/CommentsPanel";
import { Selector, SelectorProps } from "@components/Selector";
import HistoryPanel from "@router/components/BlockView/Feed/HistoryPanel";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { FeedSidePanelState } from "@store/EnvironmentStore";

import styles from "./FeedPanel.module.scss";

type FeedPanelProps = {
  block: IBlock;
};

const selectorOptions: SelectorProps["options"] = [
  {
    id: FeedSidePanelState.Comments,
    label: "Comments",
    e2eIdentifiers: "comments",
  },
  {
    id: FeedSidePanelState.ActionHistory,
    label: "Action history",
    e2eIdentifiers: "action-history",
  },
];

const FeedPanel = ({ block }: FeedPanelProps) => {
  const renderContent = () => {
    switch (appStore.env.feedSidePanelState) {
      case FeedSidePanelState.Comments:
        return (
          <CommentsPanel
            commentFeed={block.commentFeed}
            additionalParentIds={block.allPropertyIds}
            onClearCommentHistory={block.clearCommentHistory}
          />
        );
      case FeedSidePanelState.ActionHistory:
        return <HistoryPanel block={block} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.feedPanel}>
      <div className={styles.feedPanelHeader}>
        <Selector
          className={styles.feedPanelHeaderSelector}
          options={selectorOptions}
          selected={appStore.env.feedSidePanelState}
          onSelected={state => appStore.env.setFeedSidePanelState(state as FeedSidePanelState)}
        />
      </div>
      <div className={styles.feedPanelContent}>{renderContent()}</div>
    </div>
  );
};

export default observer(FeedPanel);
