import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, InputGroup, Radio } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { RevisionCodeType, SemanticRevisionCodeType } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { Text, TextColor, TextVariant } from "src/ui/Text";

import "./SettingsPdmRevisionCode.scss";

const SettingsPdmRevisionCode = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<RevisionCodeType>(appStore.orgModel.catalogItems.catalogItemRevisionCodeType);
  const [catalogItemWorkspaceLevel, setCatalogItemWorkspaceLevel] = useState<boolean>(
    appStore.orgModel.catalogItems.catalogItemWorkspaceLevel
  );

  const handleSelectType = (type: RevisionCodeType) => {
    setSelectedType(type);
    appStore.orgModel.updateSettings({ catalogItemRevisionCodeType: type });
  };

  const toggleCatalogItemWorkspaceLevel = () => {
    setCatalogItemWorkspaceLevel(!catalogItemWorkspaceLevel);
    appStore.orgModel.updateSettings({ catalogItemWorkspaceLevel: !catalogItemWorkspaceLevel });
  };

  const renderRevisionCodeTypeContent = (type: RevisionCodeType) => {
    switch (type) {
      case RevisionCodeType.Incremental:
        return (
          <div className="revision-code-settings--row">
            <Radio onChange={() => handleSelectType(type)} value={RevisionCodeType.Incremental} checked={type === selectedType} />
            <div className="revision-code-settings--row-content">
              <Text variant={TextVariant.H3}>Incremental</Text>
              <Text variant={TextVariant.Body} color={TextColor.Secondary}>
                The revision code will be incremented by one for each new version:
              </Text>
              <InputGroup fill className="revision-code-settings--example" disabled value="1, 2, 3, 4, ..." />
            </div>
          </div>
        );
      case RevisionCodeType.Letters:
        return (
          <div className="revision-code-settings--row">
            <Radio onChange={() => handleSelectType(type)} value={RevisionCodeType.Letters} checked={type === selectedType} />
            <div>
              <Text variant={TextVariant.H3}>Letters</Text>
              <Text variant={TextVariant.Body} color={TextColor.Secondary}>
                The revision code will be incremented by one letter for each new version:
              </Text>
              <InputGroup fill className="revision-code-settings--example" disabled value="A, B, C, ... Z, AA, AB, AC" />
            </div>
          </div>
        );
      case RevisionCodeType.Semantic:
        return (
          <div className="revision-code-settings--row">
            <Radio onChange={() => handleSelectType(type)} value={RevisionCodeType.Semantic} checked={type === selectedType} />
            <div>
              <Text variant={TextVariant.H3}>Semantic</Text>
              <Text variant={TextVariant.Body} color={TextColor.Secondary}>
                The revision code will be one of the options below:
              </Text>
              <div className="revision-code-settings--semantic">
                {Object.values(SemanticRevisionCodeType).map(semanticType => (
                  <Radio checked={SemanticRevisionCodeType.Major === semanticType} disabled key={semanticType} value={semanticType}>
                    {semanticType}
                  </Radio>
                ))}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="revision-code-settings">
      <div className="settings-layout--content">
        <Button
          className="revision-code-settings--back-button"
          minimal
          icon="arrow-left"
          e2eIdentifiers="pdm-config-back"
          onClick={handleBack}
        >
          Back
        </Button>
        <div className="settings-layout--header">
          <Text variant={TextVariant.H2}>PDM Revision code</Text>
        </div>
        <div>
          {Object.values(RevisionCodeType).map(type => (
            <Fragment key={type}>{renderRevisionCodeTypeContent(type)}</Fragment>
          ))}
        </div>
        {appStore.env.featureFlags.enabled(FeatureFlag.CATALOG_ITEMS_WORKSPACE_LEVEL) ? (
          <>
            <div className="settings-layout--header">
              <Text variant={TextVariant.H2}>Item catalog level</Text>
            </div>
            <div className="revision-code-settings--row">
              <Checkbox checked={catalogItemWorkspaceLevel} onChange={toggleCatalogItemWorkspaceLevel} />
              <div className="revision-code-settings--row-content">
                <Text variant={TextVariant.H3}>Workspace-level HW Catalogs</Text>
                <Text variant={TextVariant.Body} color={TextColor.Secondary}>
                  PDM items created under specific workspace will be available only from it
                </Text>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

/** Exports. */
export default observer(SettingsPdmRevisionCode);
