import { useRef, useState } from "react";
import { ContextMenu, Menu, Tooltip } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useOnClickOutside } from "usehooks-ts";

import AttachmentPopup from "@components/AttachmentPopup";
import { Button } from "@components/Button";
import useThumbnailActions from "@components/CatalogItems/Cells/PdmThumbnailCell/useThumbnailActions";
import { getRowId } from "@components/CatalogItems/Table/utils";
import { useCatalogItemImageUrl } from "@components/CatalogItems/useCatalogItemImage";
import { Loading } from "@components/Loading";
import { MenuItem } from "@components/MenuItem";
import { IPopoverRefType, Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { getAssetLink } from "@utilities/LinkUtils";

import { TCellRendererProps } from "../types";

import styles from "./PdmThumbnailCell.module.scss";

const PdmThumbnailCell = (props: TCellRendererProps) => {
  const { data } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { catalogItem } = data ?? {};
  const popoverRef = useRef<IPopoverRefType>(null);
  const attachmentPopupRef = useRef<HTMLDivElement>(null);
  const [image, loading] = useCatalogItemImageUrl(catalogItem);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [replacingImage, setReplacingImage] = useState(false);
  const assetLink = getAssetLink(image) ?? "";
  const handleImageAction = () => {
    setReplacingImage(false);
    setPopoverOpen(false);
  };
  const { onCopy, onDownload } = useThumbnailActions(assetLink, handleImageAction);
  const rowId = data ? getRowId(data) : "";
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(attachmentPopupRef, () => {
    setReplacingImage(false);
  });

  useOnClickOutside(ref, () => {
    setPopoverOpen(false);
  });

  const handleUpdateImage = (link: string) => {
    catalogItem?.setImageUrl(link);
    popoverRef.current?.setState({ isOpen: false });
    setReplacingImage(false);
  };

  const handleReplaceImage = () => {
    setReplacingImage(true);
    setPopoverOpen(false);
  };

  if (loading) {
    return <Loading size={16} />;
  } else if (!image) {
    return (
      <Popover ref={popoverRef} position="bottom-right" content={<AttachmentPopup hideRemove onLinkChange={handleUpdateImage} />}>
        <Button className={styles.pdmThumbnailCellAddNew} minimal icon="add" e2eIdentifiers="add-image" />
      </Popover>
    );
  }

  const backgroundImageStyle = { backgroundImage: `url(${assetLink})` };

  const renderTooltipContent = () =>
    replacingImage ? (
      <AttachmentPopup containerRef={attachmentPopupRef} hideRemove onLinkChange={handleUpdateImage} />
    ) : (
      <div className={classNames(styles.pdmThumbnailCellImage, styles.pdmThumbnailCellImageLarge)} style={backgroundImageStyle} />
    );

  const renderContextMenu = () => (
    <div ref={ref}>
      <Menu>
        <MenuItem icon="repeat" text="Update thumbnail" onClick={handleReplaceImage} e2eIdentifiers="update" />
        <MenuItem icon="duplicate" text="Copy thumbnail png" onClick={onCopy} e2eIdentifiers="copy" />
        <MenuItem icon="download" text="Download thumbnail" onClick={onDownload} e2eIdentifiers="download" />
      </Menu>
    </div>
  );

  const renderThumbnail = () => {
    const { showPdmThumbnailForIds } = appStore.ui;
    const { showPdmThumbnail } = appStore.env;
    const showThumbnail = showPdmThumbnail || (catalogItem && showPdmThumbnailForIds.includes(rowId));
    return (
      <div>
        {showThumbnail ? (
          <div className={styles.pdmThumbnailCellImage} style={backgroundImageStyle} />
        ) : (
          <BlueprintIcon icon="media" size={20} />
        )}
      </div>
    );
  };

  return (
    <Tooltip
      position="right"
      className={styles.pdmThumbnailCellPopoverTarget}
      popoverClassName={styles.pdmThumbnailCellPopover}
      content={renderTooltipContent()}
      isOpen={tooltipOpen || replacingImage}
      onInteraction={setTooltipOpen}
      hoverCloseDelay={100}
      minimal
    >
      <Popover isOpen={popoverOpen} position="left" minimal content={renderContextMenu()}>
        <ContextMenu onClick={() => setPopoverOpen(!popoverOpen)} content={renderContextMenu}>
          {renderThumbnail()}
        </ContextMenu>
      </Popover>
    </Tooltip>
  );
};

export default observer(PdmThumbnailCell);
