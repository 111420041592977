import { ReactNode, useRef, useState } from "react";
import { EditableText, Intent } from "@blueprintjs/core";
import { useClickOutside } from "@hooks/useClickOutside/useClickOutside";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DateSelector } from "@components/DateSelector";
import { IconSelector } from "@components/IconSelector";
import { Popover } from "@components/Popover";
import { ProjectStatusSelector } from "@components/ProjectManagementSystem/StatusSelector";
import { ProjectStatus } from "@components/ProjectManagementSystem/Types/ProjectStatus";
import UserSelectorMenu from "@components/SelectorMenus/UserSelector";
import UserSelectorTargetButton from "@components/SelectorMenus/UserSelector/UserSelectorTargetButton";
import { Switch } from "@components/Switch";
import UserInfo from "@components/UserInfo/UserInfo";
import { WorkspaceSelector } from "@components/WorkspaceSelector";
import { ICreateProjectDto } from "@rollup-api/models/pm/project.dto";
import appStore from "@store/AppStore";

import { DialogSize } from "./Dialog";
import DialogWithRightPane from "./DialogWithRightPane";

import styles from "./DialogCreateProject.module.scss";

export interface DialogCreateProjectProps {
  isOpen: boolean;
  onCancel(): void;
  onCreate(project: ICreateProjectDto): void;
}

const DialogCreateProject = (props: DialogCreateProjectProps) => {
  const { isOpen, onCancel, onCreate } = props;
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>(appStore.userModel?.mostRecentWorkspace || "");
  const [_selectedIcon, setSelectedIcon] = useState<string>();
  const [projectName, setProjectName] = useState<string>("");
  const [projectDescription, setProjectDescription] = useState<string>("");
  const [isDesignReview, setIsDesignReview] = useState<boolean>(true);
  const [projectStatus, setProjectStatus] = useState<ProjectStatus>(ProjectStatus.Backlog);
  const [startDate, setStartDate] = useState<Date>();
  const [targetDate, setTargetDate] = useState<Date>();
  const [projectLeadId, setProjectLeadId] = useState<string>();
  const [projectMemberIds, setProjectMemberIds] = useState<string[]>([]);
  const [isProjectMemberSelectorOpen, setIsProjectMemberSelectorOpen] = useState<boolean>(false);

  const projectMemberSelectorRef = useRef(null);

  const refWrapper = (node: ReactNode) => {
    return <span ref={projectMemberSelectorRef}>{node}</span>;
  };

  useClickOutside(projectMemberSelectorRef, () => {
    setIsProjectMemberSelectorOpen(false);
  });

  const handleCancel = () => {
    onCancel();
  };

  const handleCreate = () => {
    onCreate({
      label: projectName,
      description: projectDescription,
      workspaceId: selectedWorkspaceId,
      designReview: isDesignReview,
      status: projectStatus,
      startedAt: startDate,
      targetDate: targetDate,
      leadId: projectLeadId,
      memberIds: projectMemberIds,
    });
  };

  const handleProjectLeadChange = (leadId?: string[]) => {
    setProjectLeadId(leadId?.[0]);
  };

  const handleProjectMembersChange = (memberIds?: string[]) => {
    setProjectMemberIds(memberIds || []);
  };

  const dialogFooterButtons = [
    <Button large key="cancel" intent={Intent.NONE} e2eIdentifiers={["dialog-add-new-project", "cancel"]} onClick={handleCancel}>
      Cancel
    </Button>,
    <Button large key="Action" intent={Intent.PRIMARY} e2eIdentifiers={["dialog-add-new-project", "create"]} onClick={handleCreate}>
      Create project
    </Button>,
  ];

  const renderPropertyLabel = (name: string) => (
    <div className={styles.dialogCreateProjectRightPanePropertyLabel}>
      <Text variant={TextVariant.Caption}>{name}</Text>
    </div>
  );

  const renderRightPane = (
    <div className={styles.dialogCreateProjectRightPane}>
      <Text variant={TextVariant.BodyDimmed}>Details</Text>
      <div className={styles.dialogCreateProjectRightPanePropertyGroup}>
        {renderPropertyLabel("Design review")}
        <div className={styles.dialogCreateProjectRightPanePropertyLabel}>
          <Switch checked={isDesignReview} onChange={e => setIsDesignReview(e.target.checked)} e2eIdentifiers="design-review-switch" />
        </div>

        {renderPropertyLabel("Status")}
        <ProjectStatusSelector currentStatus={projectStatus} onChange={status => setProjectStatus(status)} />

        {renderPropertyLabel("Lead")}
        <Popover
          content={
            <UserSelectorMenu singleSelect onChange={handleProjectLeadChange} selectedUserIds={projectLeadId ? [projectLeadId] : []} />
          }
          position="bottom"
        >
          {projectLeadId ? (
            <Button small minimal className={styles.dialogCreateProjectRightPaneUserButton} e2eIdentifiers="project-lead-name-button">
              <UserInfo size="extra-small" userId={projectLeadId} />
            </Button>
          ) : (
            <UserSelectorTargetButton />
          )}
        </Popover>

        {renderPropertyLabel("Members")}
        <div className={styles.dialogCreateProjectRightPaneUserList}>
          {projectMemberIds.length > 0 &&
            projectMemberIds.map(memberId => (
              <div key={memberId} className={styles.dialogCreateProjectRightPaneUserInfoWrapper}>
                <UserInfo size="extra-small" userId={memberId} />
              </div>
            ))}
          <Popover
            content={refWrapper(<UserSelectorMenu onChange={handleProjectMembersChange} selectedUserIds={projectMemberIds} />)}
            position="bottom"
            isOpen={isProjectMemberSelectorOpen}
          >
            <UserSelectorTargetButton onClick={() => setIsProjectMemberSelectorOpen(true)} />
          </Popover>
        </div>

        {renderPropertyLabel("Start date")}
        <DateSelector value={startDate} onChange={newStartDate => setStartDate(newStartDate)} />

        {renderPropertyLabel("Target date")}
        <DateSelector value={targetDate} onChange={newTargetDate => setTargetDate(newTargetDate)} />
      </div>
    </div>
  );

  return (
    <DialogWithRightPane
      size={DialogSize.Medium}
      isOpen={isOpen}
      title="Create new project"
      rightPane={renderRightPane}
      footerButtons={dialogFooterButtons}
    >
      <div className={styles.dialogCreateProjectBody}>
        <WorkspaceSelector
          showAllWorkspacesOption={false}
          selectedWorkspaceId={selectedWorkspaceId}
          onChange={workspaceId => setSelectedWorkspaceId(workspaceId)}
        />
        <div className={styles.dialogCreateProjectBodyContent}>
          <div className={styles.dialogCreateProjectBodyIconAndName}>
            <IconSelector onChange={icon => setSelectedIcon(icon.name)} hideColorPicker />
            <EditableText
              className={styles.dialogCreateProjectBodyNameInput}
              maxLength={64}
              placeholder="Project name"
              onConfirm={setProjectName}
              confirmOnEnterKey
              multiline
              maxLines={2}
              defaultValue={projectName}
            />
          </div>
          <div className={styles.dialogCreateProjectBodyDescription}>
            <EditableText
              className={styles.dialogCreateProjectBodyDescriptionInput}
              maxLength={2048}
              placeholder="Project description"
              onConfirm={setProjectDescription}
              multiline
              maxLines={16}
              defaultValue={projectDescription}
            />
          </div>
        </div>
      </div>
    </DialogWithRightPane>
  );
};

export default observer(DialogCreateProject);
