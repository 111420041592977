import { Intent } from "@blueprintjs/core";
import { toArray } from "@rollup-io/engineering";
import { AxiosResponse } from "axios";
import { destroy, flow, Instance, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { ICreateProjectDto, IUpdateProjectDto } from "@rollup-api/models/pm/project.dto";
import { Project } from "@rollup-api/models/pm/project.model";
import { NoReturnGenerator } from "@rollup-types/typeUtils";
import appStore from "@store/AppStore";
import { convertTimestamp } from "@utilities";

import { rollupClient } from "../../core/api";
import { mapRtoToSnapshot } from "../../services/services.utils";

import { IProject, IProjectSnapshotIn, ProjectStore } from "./ProjectStore";

export const ProjectManagementModuleStore = types
  .model("ProjectManagementModule", {
    projectMap: types.map(types.late(() => ProjectStore)),
  })
  .views(self => ({
    get projects() {
      return toArray(self.projectMap);
    },
    get(id: string) {
      return self.projectMap.get(id);
    },
  }))
  .views(self => ({
    getLabelValidationErrorMessage(label: string): string {
      if (!label || label === "<p></p>") {
        return "Cannot add a project with an empty name";
      } else if (self.projects.some(project => project.label.toLowerCase() === label.toLowerCase())) {
        return "A project with this name already exists";
      }
      return "";
    },
  }))
  .actions(self => ({
    afterCreate: flow(function* (): NoReturnGenerator<AxiosResponse<Project[]>> {
      const res = yield rollupClient.pmModule.projects.getAll();
      const projects = res.data;
      projects.forEach(project => self.projectMap.put(mapRtoToSnapshot(project)));
    }),
    setProjectLabel(id: string, label: string) {
      const validationErrorMessage = self.getLabelValidationErrorMessage(label);

      if (validationErrorMessage) {
        console.error(validationErrorMessage);
        showToast(validationErrorMessage, Intent.WARNING);
        return;
      }
      self.projectMap.get(id)?.setLabel(label);
    },
    deleteProject(id: string, disableNotify?: boolean) {
      const projectToDelete = self.get(id);
      if (!projectToDelete) {
        return;
      }

      if (!disableNotify) {
        rollupClient.pmModule.projects.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting project", err);
        });
      }

      if (self.projectMap.delete(id)) {
        destroy(projectToDelete);
        return true;
      }
      return false;
    },
  }))
  .actions(self => ({
    createProject: flow(function* (dto: ICreateProjectDto): Generator<any, IProject | undefined, AxiosResponse<Project>> {
      const userId = appStore.userModel?.id;
      const snapshotIn: IProjectSnapshotIn = mapRtoToSnapshot({
        id: uuidv4(),
        createdBy: userId,
        updatedBy: userId,
        ...dto,
      });
      const project = self.projectMap.put(snapshotIn);

      const res = yield rollupClient.pmModule.projects.create(dto);
      if (res.status !== 201) {
        showApiErrorToast("Error creating project", new Error());
        self.deleteProject(project.id, false);
        return undefined;
      }
      project.patch(res.data);
      return project;
    }),
    addProject(project: Project) {
      self.projectMap.put(mapRtoToSnapshot(project));
    },
    updateProject(id: string, IUpdateProjectDto: IUpdateProjectDto) {
      const project = self.projectMap.get(id);
      project?.patch(IUpdateProjectDto);
    },
  }));

export function subscribeToProjectEvents(socket: Socket) {
  socket.on("CreateProject", (data: { project: Project }) => {
    if (data.project?.id) {
      appStore.projectManagement.addProject(data.project);
    }
  });

  socket.on("UpdateProject", (data: { id: string; updateProjectDto: IUpdateProjectDto; project: Project }) => {
    const dto = {
      ...data.updateProjectDto,
      updatedAt: convertTimestamp(data.project.updatedAt),
      updatedBy: data.project.updatedBy,
    };
    appStore.projectManagement.updateProject(data.id, dto);
  });

  socket.on("DeleteProject", (data: { id: string }) => {
    if (data.id) {
      appStore.projectManagement.deleteProject(data.id, true);
    }
  });
}

export interface IProjectManagementModule extends Instance<typeof ProjectManagementModuleStore> {}
