import { ModelingIcon } from "@components/CustomIcon";
import { TNavigationLinkConfig, TNavigationLinkConfigs } from "@constants/navigation";
import { IconSource } from "@rollup-types/icons";
import { EPageName } from "@router/hooks/useAppNavigate";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { addPrefixToE2EIdentifiers } from "@utilities/E2EUtils";
import { removeDisabledMenuItems } from "@utilities/NavigationUtils";

const getBlockNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Blocks",
  icon: { source: IconSource.Custom, name: ModelingIcon.BlockTree },
  to: EPageName.Blocks,
  e2eIdentifiers: addPrefixToE2EIdentifiers("blocks", e2ePrefix),
});

const getTableNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Table",
  icon: { source: IconSource.Custom, name: ModelingIcon.Table },
  to: EPageName.Table,
  e2eIdentifiers: addPrefixToE2EIdentifiers("table", e2ePrefix),
});

const getPropertiesNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Properties",
  icon: { source: IconSource.Custom, name: ModelingIcon.PropertyDefinitions },
  to: EPageName.Properties,
  e2eIdentifiers: addPrefixToE2EIdentifiers("properties", e2ePrefix),
});

const getStatusesNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Statuses",
  icon: { source: IconSource.Custom, name: ModelingIcon.StatusDefinitions },
  to: EPageName.Statuses,
  e2eIdentifiers: addPrefixToE2EIdentifiers("statuses", e2ePrefix),
});

const getCustomUnitsNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Custom Units",
  icon: { source: IconSource.Custom, name: ModelingIcon.CustomUnits },
  to: EPageName.CustomUnits,
  e2eIdentifiers: addPrefixToE2EIdentifiers("statuses", e2ePrefix),
  isDisabled: () => !appStore.env.featureFlags.enabled(FeatureFlag.CUSTOM_UNITS),
});

const getModelConfigurationNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Model Configuration",
  icon: { source: IconSource.Custom, name: ModelingIcon.ModelConfiguration },
  to: "#",
  nestedLinks: [getPropertiesNavItem(e2ePrefix), getStatusesNavItem(e2ePrefix), getCustomUnitsNavItem(e2ePrefix)],
  e2eIdentifiers: addPrefixToE2EIdentifiers("model-configuration", e2ePrefix),
});

const getDagNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Property Relations",
  icon: { source: IconSource.Custom, name: ModelingIcon.PropertyRelations },
  to: `${EPageName.Threads}/${EPageName.PropertyRelations}`,
  e2eIdentifiers: addPrefixToE2EIdentifiers("property-relations", e2ePrefix),
});

const getHierarchyNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Hierarchy",
  icon: { source: IconSource.Custom, name: ModelingIcon.Hierarchy },
  to: `${EPageName.Threads}/${EPageName.Hierarchy}`,
  e2eIdentifiers: addPrefixToE2EIdentifiers("hierarchy", e2ePrefix),
});

const getThreadsNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Digital Threads",
  icon: { source: IconSource.Custom, name: ModelingIcon.DigitalThreads },
  to: EPageName.Threads,
  nestedLinks: [getDagNavItem(e2ePrefix), getHierarchyNavItem(e2ePrefix)],
  e2eIdentifiers: addPrefixToE2EIdentifiers("threads", e2ePrefix),
});

export const getModelingLayoutMenuItems = (e2ePrefix = ""): TNavigationLinkConfigs => {
  const menuItems = [
    getBlockNavItem(e2ePrefix),
    getTableNavItem(e2ePrefix),
    getThreadsNavItem(e2ePrefix),
    getModelConfigurationNavItem(e2ePrefix),
  ];
  return removeDisabledMenuItems(menuItems);
};
