import { ChangeEvent, useEffect, useState } from "react";
import { InputGroup, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";

import { DialogLegacy } from "../index";

export enum DialogExtendBomTableType {
  New = "new",
  Edit = "edit",
}

import { Select } from "@blueprintjs/select";
import { SelectItem } from "@ui/Select/Item/SelectItem";

import { showToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";
import { IBomTable } from "@store/BomTable/BomTableStore";

import styles from "./DialogExtendBomTable.module.scss";

const DialogExtendBomTable = () => {
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const isOpen = !!appStore.ui.extendBomTableDialog;
  const existingTables = appStore.workspaceModel?.bomTables || [];
  const addNewTable = appStore.ui.extendBomTableDialog === DialogExtendBomTableType.New || !existingTables.length;
  const [table, setTable] = useState<IBomTable>();
  const [name, setName] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setSelectedIds([]);
      setName("");
      setTable(undefined);
    }

    const ids = [...appStore.ui.selectedCatalogItemIds];
    appStore.ui.selectedCatalogItemReferenceIds.forEach(id => {
      const catalogItemReference = appStore.orgModel.catalogItems.getCatalogItemReference(id);
      if (catalogItemReference) {
        ids.push(catalogItemReference.id);
      }
    });
    setSelectedIds(Array.from(new Set(ids)));
  }, [isOpen]);

  const handleClose = () => {
    appStore.ui.setExtendBomTableDialog();
    setLoading(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      if (addNewTable) {
        await appStore.workspaceModel?.createBomTable(name, selectedIds);
        showToast("BOM table created", Intent.SUCCESS);
      } else if (table) {
        table.setRows(Array.from(new Set([...table.rowIds, ...selectedIds])));
        showToast("BOM table updated", Intent.SUCCESS);
      }
      handleClose();
    } catch (error) {
      showToast(`Failed to ${addNewTable ? "create" : "update"} BOM table`, Intent.DANGER);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const title = addNewTable ? "Create new BOM table" : "Extend existing BOM table";

  const renderContent = () => {
    if (addNewTable) {
      return (
        <>
          <div className={styles.dialogExtendBomTableLabel}>BOM table name</div>
          <InputGroup autoFocus required value={name} onChange={handleInputChange} large placeholder="Enter table name..." />
        </>
      );
    }

    return (
      <Select<IBomTable>
        itemPredicate={(query, item) => item.label.toLowerCase().includes(query.toLowerCase())}
        items={existingTables}
        popoverContentProps={{ className: "headers-panel--select" }}
        popoverProps={{ minimal: true, matchTargetWidth: true }}
        onItemSelect={setTable}
        itemRenderer={(item, { handleClick }) => (
          <SelectItem slug={item.label} key={item.id} label={item.label} onClick={handleClick} active={table?.id === item.id} />
        )}
      >
        <Button
          large
          alignText="left"
          fill
          text={table ? table.label : "Select table"}
          rightIcon="double-caret-vertical"
          e2eIdentifiers="select-table"
        />
      </Select>
    );
  };

  return (
    <DialogLegacy className={styles.dialogExtendBomTable} isOpen={isOpen} title={title} onClose={handleClose}>
      <div className={styles.dialogExtendBomTableBody}>{renderContent()}</div>
      <div className={styles.dialogExtendBomTableFooter}>
        <Button disabled={loading} outlined large onClick={handleClose} e2eIdentifiers={["dialog-extend-bom-table", "cancel"]}>
          Cancel
        </Button>
        <Button
          disabled={addNewTable ? !name : !table}
          large
          loading={loading}
          intent={Intent.PRIMARY}
          onClick={handleConfirm}
          e2eIdentifiers={["dialog-extend-bom-table", "confirm"]}
        >
          {addNewTable ? "Create" : "Update"}
        </Button>
      </div>
    </DialogLegacy>
  );
};

export default observer(DialogExtendBomTable);
