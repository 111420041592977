import { User as DatadogUser } from "@datadog/browser-core";
import { datadogRum } from "@datadog/browser-rum";

import packageInfo from "src/../package.json";
import { GOVCLOUD_MODE } from "src/lib/GovCloud";

const DATADOG_APP_ID = import.meta.env.VITE_DATADOG_APP_ID;
const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;

export function initDatadog() {
  const queryParams = new URLSearchParams(window.location.search);
  const skipRum = queryParams.get("skipRum");

  if (GOVCLOUD_MODE) {
    console.debug("Skipping Datadog in GovCloud mode");
    return;
  } else if (skipRum) {
    console.debug("Skipping Datadog init due to skipRum query param");
    return;
  } else if (import.meta.env.VITE_ENV_TYPE !== "production" && import.meta.env.VITE_ENV_TYPE !== "staging") {
    console.debug("Skipping Datadog init in non-production environment");
    return;
  } else if (window.location.hostname.startsWith("rollup-testing")) {
    console.debug("Skipping Datadog init in testing environment");
    return;
  }

  datadogRum.init({
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    proxy: "https://analytics.rollup.ai/datadog",
    site: "datadoghq.com",
    service: "rollupweb",
    env: import.meta.env.VITE_ENV_TYPE ?? "debug",
    version: packageInfo?.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

export function setDataDogUser(user: DatadogUser) {
  datadogRum.setUser(user);
}
