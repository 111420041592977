import { Menu } from "@blueprintjs/core";
import useReportExport from "@hooks/useReportExport/useReportExport";

import { MenuItem } from "@components/MenuItem";
import { PopupMenu } from "@components/Shared/PopupMenu";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";

type Props = {
  report: IReport;
};

const ReportExportButton = (props: Props) => {
  const { report } = props;
  const { exportToPdf, exportToMarkdown } = useReportExport(report);
  const { isPrintingPage } = appStore.ui;

  return (
    <PopupMenu
      buttonIcon="export"
      buttonText="Export"
      e2eIdentifiers="export-menu"
      content={
        <Menu>
          <MenuItem disabled={isPrintingPage} text="Export to PDF" e2eIdentifiers="export-to-pdf" onClick={exportToPdf} />
          <MenuItem text="Export to Markdown" e2eIdentifiers="export-to-markdown" onClick={exportToMarkdown} />
        </Menu>
      }
    />
  );
};

export default ReportExportButton;
