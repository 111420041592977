import { Analysis, CreateAnalysisDto, ExecutionResult, UpdateAnalysisDto } from "@rollup-api/models/analysis";

import { HttpClient, ParentClient } from "../client";

export class Analyses extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  // TODO: change the endpoint to `workspace/${this.parent.workspaceId}/analysis/` together with the backend
  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/code-blocks`;
  }

  public create = (dto: CreateAnalysisDto) => {
    return this.instance.post<Analysis>(this.endpoint, dto);
  };

  public retrieve = (id: string) => {
    return this.instance.get<Analysis>(`${this.endpoint}/${id}`);
  };

  public getAssetUrl = (analysisId: string, executionResultId: string, assetId: string) => {
    return this.instance.get<{ url: string }>(`${this.endpoint}/${analysisId}/asset/${executionResultId}/${assetId}`);
  };

  public getTextAsset = async (analysisId: string, executionResultId: string, assetId: string) => {
    return this.getAssetUrl(analysisId, executionResultId, assetId)
      .then(urlRes => fetch(urlRes.data.url))
      .then(res => res.text());
  };

  public update = (id: string, dto: UpdateAnalysisDto) => {
    return this.instance.patch<Analysis>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<Analysis>(`${this.endpoint}/${id}`);
  };

  public run = (id: string) => {
    return this.instance.post<ExecutionResult>(`${this.endpoint}/${id}/run`);
  };
}
