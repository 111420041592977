import { AnalysisOutput, CreateAnalysisOutputDto, UpdateAnalysisOutputDto } from "@rollup-api/models/analysis";

import { HttpClient, ParentClient } from "../client";

export class AnalysisOutputs extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/code-blocks/output`;
  }

  public create = (dto: CreateAnalysisOutputDto) => {
    return this.instance.post<AnalysisOutput>(this.endpoint, dto);
  };

  public update = (id: string, dto: UpdateAnalysisOutputDto) => {
    return this.instance.patch<AnalysisOutput>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<AnalysisOutput>(`${this.endpoint}/${id}`);
  };
}
