import { AnalysisInput, CreateAnalysisInputDto, UpdateAnalysisInputDto } from "@rollup-api/models/analysis";

import { HttpClient, ParentClient } from "../client";

export class AnalysisInputs extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `workspace/${this.parent.workspaceId}/code-blocks/input`;
  }

  public create = (dto: CreateAnalysisInputDto) => {
    return this.instance.post<AnalysisInput>(this.endpoint, dto);
  };

  public update = (id: string, dto: UpdateAnalysisInputDto) => {
    return this.instance.patch<AnalysisInput>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<AnalysisInput>(`${this.endpoint}/${id}`);
  };
}
