import { CustomCellEditorProps } from "ag-grid-react";
import { observer } from "mobx-react";

import { CatalogItemNode } from "@components/CatalogItems/Table/types";
import PartNumber from "@components/Table/CellEditors/PartNumber";
import appStore from "@store/AppStore";

const PartNumberCellEditor = (props: CustomCellEditorProps<CatalogItemNode, string>) => {
  const { data, api, onValueChange } = props;
  const catalogItem = data?.catalogItem;

  if (!catalogItem) {
    return null;
  }

  const handleAddNewSchema = (label: string, schema: string, leadingZeroes?: number) => {
    appStore.orgModel.partNumberSchemas.addPartNumberSchema(label, schema, leadingZeroes).then(newSchema => {
      if (newSchema) {
        catalogItem.setPartNumberFromSchema(newSchema);
      }
    });
  };

  const handleSetFromString = (partNumber: string) => {
    api.stopEditing();
    catalogItem.setPartNumber(partNumber);
  };

  return (
    <PartNumber
      onUpdatePartNumber={handleSetFromString}
      onSetPartNumber={catalogItem.setPartNumberFromSchema}
      partNumberSchemas={appStore.orgModel.partNumberSchemas.values}
      onDeletePartNumberSchema={appStore.orgModel.partNumberSchemas.deletePartNumberSchema}
      api={api}
      value={catalogItem.partNumber}
      onAddNewSchema={handleAddNewSchema}
      onValueChange={onValueChange}
    />
  );
};

PartNumberCellEditor.displayName = "PartNumberCellEditor";

export default observer(PartNumberCellEditor);
