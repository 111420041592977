import { useMemo } from "react";
import { observer } from "mobx-react";

import BaseSelectorMenu from "@components/SelectorMenus/BaseSelectorMenu";
import { ItemInfo } from "@components/SelectorMenus/ItemInfo";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";

interface UserSelectorMenuProps {
  title?: string;
  showSearchBox?: boolean;
  searchBoxPlaceholder?: string;
  singleSelect?: boolean;
  selectedUserIds?: string[];
  onChange?: (selectedUserIds?: string[]) => void;
  maxHeight?: number;
}

const UserSelectorMenu = ({
  title,
  maxHeight,
  showSearchBox = true,
  searchBoxPlaceholder,
  singleSelect,
  selectedUserIds: selectedUserIdsProp = [],
  onChange,
}: UserSelectorMenuProps) => {
  const users = appStore.orgModel.info.userInfoSummaries.map(user => ({ id: user.id, name: user.name, imageUrl: user.avatarUrl }));

  const selectedItemIds = useMemo(() => {
    return users.filter(user => selectedUserIdsProp.includes(user.id)).map(user => user.id);
  }, [selectedUserIdsProp, users]);

  const handleChange = (selectedUserIds: string[]) => {
    onChange?.(selectedUserIds);
  };

  const itemRenderer = (item: ItemInfo) => {
    return <UserInfo size="small" userName={item.name} avatarUrl={item.imageUrl} />;
  };

  return (
    <BaseSelectorMenu
      title={title}
      showSearchBox={showSearchBox}
      searchBoxPlaceholder={searchBoxPlaceholder}
      singleSelect={singleSelect}
      items={users}
      maxHeight={maxHeight}
      selectedItemIds={selectedItemIds}
      itemRenderer={itemRenderer}
      onChange={handleChange}
    />
  );
};

export default observer(UserSelectorMenu);
