import { CELL_MOCK } from "@components/Modeling/ModelingFrame/Table/TableComponent/constants";
import appStore from "@store/AppStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusOption } from "@store/StatusOptionStore";
import { IUser } from "@store/UserStore";

export const getMultiSelectValue = (statusDefinition: IStatusDefinition, value?: string): IStatusOption[] => {
  if (!value || !statusDefinition.statusOptions?.length || value === CELL_MOCK) {
    return [];
  }

  try {
    const idArray = JSON.parse(value) as string[];
    if (Array.isArray(idArray) && idArray.length) {
      return idArray
        .map(id => statusDefinition.statusOptions.find(o => o.id === id))
        .filter(o => o)
        .sort(o => o.label);
    }
  } catch (err) {
    console.warn(err);
  }
  return [];
};
export const getUserValues = (value = ""): IUser[] => {
  const users: IUser[] = appStore.orgModel?.info?.orgMembers || [];

  if (!value || !users.length) {
    return [];
  }

  try {
    const object = JSON.parse(value);
    let idArray: string[];
    if (Array.isArray(object)) {
      idArray = object;
    } else if (typeof object === "string") {
      idArray = object.split(",");
    } else {
      return [];
    }

    if (idArray.length) {
      return idArray
        .reduce((result: IUser[], id) => {
          const user = users.find(user => user.id === id);
          return user ? [...result, user] : result;
        }, [])
        .sort((a, b) => a.displayName.localeCompare(b.displayName));
    }
  } catch (err) {
    console.warn(err);
  }
  return [];
};

export const getUrlValue = (value: string | null): IUrl | undefined => {
  try {
    const { url, label } = JSON.parse(value ?? "");
    return { url, label };
  } catch (err) {
    return undefined;
  }
};

export interface IUrl {
  url: string;
  label?: string;
}
