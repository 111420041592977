import { useEffect, useState } from "react";
import { Checkbox, Icon, InputGroup, Label, Radio, Tooltip } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { AnchorButton } from "@components/AnchorButton";
import { Button } from "@components/Button";
import CatalogItemCostPicker from "@components/CatalogItemCostPicker";
import CatalogItemWeightPicker from "@components/CatalogItemWeightPicker";
import { FILE_IMPORT_LIMIT } from "@components/CreateCatalogItemDialog/constants";
import FileDropZone, { EFileFormat } from "@components/FileDropZone";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import { RevisionCodeType, SemanticRevisionCodeType } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { ICatalogItemVersion } from "@store/CatalogItem/CatalogItemVersionStore";
import { getMimeType } from "@utilities";

import styles from "./PdmVersionEditor.module.scss";

type PdmVersionEditorProps = {
  comment: string;
  setComment: (comment: string) => void;
  material: string;
  setMaterial: (material: string) => void;
  cost: string;
  setCost: (cost: string) => void;
  costCurrency: string;
  setCostCurrency: (costCurrency: string) => void;
  weight: string;
  setWeight: (weight: string) => void;
  weightUnit: string;
  setWeightUnit: (weightUnit: string) => void;
  file: File | null;
  setFile: (file: File | null) => void;
  version?: ICatalogItemVersion;
  catalogItem: ICatalogItem;
  revisionName: string;
  setRevisionName: (revisionName: string) => void;
  className?: string;
  addRevision: boolean;
  isSaving?: boolean;
  setAddRevision: (addRevision: boolean) => void;
  setRevisionCode: (revisionCode: string | undefined) => void;
  onSettingsClick?: () => void;
};

const PdmVersionEditor = (props: PdmVersionEditorProps) => {
  const {
    onSettingsClick,
    className,
    isSaving,
    comment,
    setComment,
    material,
    setMaterial,
    cost,
    setCost,
    costCurrency,
    setCostCurrency,
    weight,
    setWeight,
    weightUnit,
    setWeightUnit,
    file,
    setFile,
    version,
    catalogItem,
    revisionName,
    setRevisionName,
    addRevision,
    setAddRevision,
    setRevisionCode,
  } = props;
  const { navigateToPdmSettings } = useAppNavigate();
  const [revisionPatchType, setRevisionPatchType] = useState(SemanticRevisionCodeType.Patch);
  const nextRevisionCode = version
    ? version.nextRevisionCode(revisionPatchType)
    : catalogItem.latestVersion?.nextRevisionCode(revisionPatchType);

  useEffect(() => {
    setRevisionCode(addRevision ? nextRevisionCode : undefined);
  }, [nextRevisionCode, addRevision, setRevisionCode]);

  const handleFileDrop = (fileList: FileList) => {
    if (fileList.length) {
      setFile(fileList[0]);
    } else {
      setFile(null);
    }
  };

  const renderFileContent = () => (
    <div>
      <div className={styles.pdmVersionEditorFileDropzone}>
        <Icon icon="paperclip" size={18} />
        <div>Select CAD file to upload</div>
      </div>
      {file && (
        <div className={styles.pdmVersionEditorFile}>
          <div className={styles.pdmVersionEditorFileLabel}>
            <AttachmentIcon mimeType={getMimeType(file)} />
            <div className={styles.pdmVersionEditorFileName}>{file.name}</div>
          </div>
          <Button minimal icon="cross" onClick={() => setFile(null)} e2eIdentifiers="remove-file" />
        </div>
      )}
    </div>
  );

  const handleOpenSettings = () => {
    navigateToPdmSettings();
    onSettingsClick?.();
  };

  return (
    <div className={classNames(styles.pdmVersionEditor, { [styles.pdmVersionEditorLoading]: isSaving }, className)}>
      <div className={styles.pdmVersionEditorHeader}>
        <div className={styles.pdmVersionEditorIndex}>
          <span className={styles.pdmVersionEditorLabel}>Version index:</span> {version ? version.index : catalogItem.versions.length + 1}
          <Tooltip className={styles.pdmVersionEditorTooltip} content="This is incremented on every new version. It's cannot be changed">
            <Icon icon="info-sign" size={12} />
          </Tooltip>
        </div>
        <AnchorButton icon="cog" minimal e2eIdentifiers="pdm-configurations" onClick={handleOpenSettings}>
          PDM Configurations
        </AnchorButton>
      </div>
      <Label>
        <Text className={styles.pdmVersionEditorLabel} variant={TextVariant.Caption}>
          Primary material
        </Text>
        <InputGroup value={material} onChange={e => setMaterial(e.target.value)} />
      </Label>
      <div className={styles.pdmVersionEditorEditRow}>
        <CatalogItemCostPicker cost={cost} onCurrencyChange={setCostCurrency} currency={costCurrency} onCostChange={setCost} />
        <CatalogItemWeightPicker onWeightChange={setWeight} onUnitChange={setWeightUnit} weight={weight} unit={weightUnit} />
      </div>
      <Label>
        <Text className={styles.pdmVersionEditorLabel} variant={TextVariant.Caption}>
          Comment
        </Text>
        <InputGroup value={comment} onChange={e => setComment(e.target.value)} />
      </Label>
      <FileDropZone sizeLimit={FILE_IMPORT_LIMIT} format={[EFileFormat.PRT, EFileFormat.SLDASM]} clickToSelect onChange={handleFileDrop}>
        {renderFileContent}
      </FileDropZone>
      <Checkbox label="Add revision" checked={addRevision} onChange={e => setAddRevision(e.target.checked)} />
      {addRevision && (
        <div>
          <div className={styles.pdmVersionEditorHeader}>
            <div className={styles.pdmVersionEditorIndex}>
              <div>
                <Text className={styles.pdmVersionEditorLabel} variant={TextVariant.Caption}>
                  Revision code:{" "}
                </Text>
                {nextRevisionCode}
              </div>
              <Tooltip
                className={styles.pdmVersionEditorTooltip}
                content="This is incremented on every new version. It's cannot be changed"
              >
                <Icon icon="info-sign" size={12} />
              </Tooltip>
            </div>
            {appStore.orgModel.catalogItems.catalogItemRevisionCodeType === RevisionCodeType.Semantic && (
              <div className={styles.pdmVersionEditorPatchType}>
                {Object.values(SemanticRevisionCodeType).map(revisionCodeType => (
                  <Radio
                    onChange={() => setRevisionPatchType(revisionCodeType)}
                    key={revisionCodeType}
                    value={revisionCodeType}
                    label={revisionCodeType}
                    checked={revisionPatchType === revisionCodeType}
                  />
                ))}
              </div>
            )}
          </div>
          <Label>
            <Text className={styles.pdmVersionEditorLabel} variant={TextVariant.Caption}>
              Revision name
            </Text>
            <InputGroup value={revisionName} onChange={e => setRevisionName(e.target.value)} />
          </Label>
        </div>
      )}
    </div>
  );
};

export default observer(PdmVersionEditor);
