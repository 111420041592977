import * as Frigade from "@frigade/react";

import { FRIGADE_API_KEY } from "src/lib/Frigade/constants";
import { GOVCLOUD_MODE } from "src/lib/GovCloud";

const FrigadeProvider =
  FRIGADE_API_KEY && !GOVCLOUD_MODE
    ? ({ children }: { children: React.ReactNode }) => <Frigade.Provider apiKey={FRIGADE_API_KEY}>{children}</Frigade.Provider>
    : ({ children }: { children: React.ReactNode }) => <>{children}</>;

export default FrigadeProvider;
