import { Intent } from "@blueprintjs/core";
import { toArray } from "@rollup-io/engineering";
import { AxiosProgressEvent, AxiosResponse } from "axios";
import { IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { uuid } from "short-uuid";

import { showToast } from "@components/UiLayers/toaster";
import { TUploadLinkResponse, TUploadTags } from "@rollup-api/api/cloudStorage";
import { Attachment } from "@rollup-api/models/cloudStorage";
import { updateAttachment } from "@rollup-api/utils";
import { IAttachment } from "@store/AttachmentStore";
import { formatFileSize } from "@utilities";
import { rollupClient } from "src/core/api";
import { trackSegmentEvent } from "src/lib/Segment";

import appStore from "./AppStore";
import { BlockStore } from "./BlockStore";
import { EFileChunkUploadStatus, FileChunkStore, IFileChunkStore } from "./FileChunkStore";

export enum EFileUploadStatus {
  PAUSED = "paused",
  PENDING = "pending",
  CANCELED = "canceled",
  INITIALIZING = "initializing",
  UPLOADING = "uploading",
  FINALIZING = "finalizing",
  UPLOADED = "uploaded",
  FAILED = "failed",
}

export const UploadStore = types
  .model("UploadStore", {
    id: types.identifier,
    label: types.string,
    type: types.optional(types.string, ""),
    numParts: types.optional(types.number, 1),
    uploadId: types.optional(types.string, ""),
    urlId: types.optional(types.string, ""),
    attachmentId: types.optional(types.string, ""),
    status: types.optional(types.enumeration("EFileUploadStatus", [...Object.values(EFileUploadStatus)]), EFileUploadStatus.PENDING),
    block: types.maybeNull(types.safeReference(types.late((): IAnyModelType => BlockStore))),
    createdAt: types.optional(types.number, Date.now()),
    removeOnUpload: types.optional(types.boolean, false),
    skipCadConversion: types.optional(types.boolean, false),
    workspaceId: types.optional(types.string, ""),
    chunks: types.map(types.late((): IAnyModelType => FileChunkStore)),
  })
  .volatile(() => ({
    file: undefined as unknown as File,
    uploadedSize: 0,
    abortController: undefined as unknown as AbortController,
    onUpload: undefined as unknown as (attachmentId: string, file: File, workspaceId?: string) => void,
  }))
  .views(self => ({
    get chunksArray(): Array<IFileChunkStore> {
      return toArray<IFileChunkStore>(self.chunks);
    },
  }))
  .views(self => ({
    get failedChunks(): Array<IFileChunkStore> {
      return self.chunksArray.filter(c => c.status === EFileChunkUploadStatus.FAILED);
    },
    get uploadedChunks(): Array<IFileChunkStore> {
      return self.chunksArray.filter(c => c.status === EFileChunkUploadStatus.UPLOADED);
    },
    get uploadingChunks(): Array<IFileChunkStore> {
      return self.chunksArray.filter(c => c.status === EFileChunkUploadStatus.UPLOADING);
    },
    get pendingChunks(): Array<IFileChunkStore> {
      return self.chunksArray.filter(c => c.status === EFileChunkUploadStatus.PENDING);
    },
    get eTags(): TUploadTags {
      return self.chunksArray.map(chunk => ({ ETag: chunk.eTag, PartNumber: chunk.chunkNumber }));
    },
  }))
  .actions(self => ({
    setStatus(status: EFileUploadStatus) {
      self.status = status;
    },
    setLabel(label: string) {
      self.label = label;
      if (self.urlId) {
        updateAttachment(self.urlId, { label });
        if (self.status === EFileUploadStatus.UPLOADED) {
          const attachment = appStore.workspaceModel?.attachments.get(self.urlId);
          attachment?.setLabel(label);
        }
      }
    },
  }))
  .actions(self => ({
    setUploadSize(size: number) {
      self.uploadedSize = size;
    },
    callCompleteCallbacks(response: AxiosResponse<Attachment> | undefined, disableAddToBlock?: boolean) {
      const attachmentId = response?.data.id || "";
      self.setStatus(EFileUploadStatus.UPLOADED);
      // Add attachment to the workspace
      let attachment: IAttachment | undefined;
      if (response?.data.workspaceId) {
        attachment = appStore.workspaceModel?.attachments.addUploadedAttachment(response?.data);

        // Add attachment to the block if needed
        if (response?.data.blockId && attachment && !disableAddToBlock) {
          appStore.workspaceModel?.attachments.addUploadedAttachmentsToBlock(attachment.id, response.data.blockId);
        }
      } else {
        attachment = appStore.orgModel.attachments.addUploadedAttachment(response?.data);
      }

      // this case covers versions upload originally initiated with attachmentId
      if (!self.attachmentId) {
        self.attachmentId = attachmentId;
      }
      self.onUpload && self.onUpload(attachmentId, self.file, self.workspaceId);
      appStore.orgModel.uploads.triggerNextUpload();

      if (self.removeOnUpload) {
        appStore.orgModel.uploads.removeFileUploadStore(self as IUploadStore);
      }
      trackSegmentEvent("file-upload:success", { label: self.label });
      showToast("File uploaded successfully", Intent.SUCCESS);
    },
    callFailedCallbacks() {
      self.setStatus(EFileUploadStatus.FAILED);
      appStore.orgModel.uploads.triggerNextUpload();
      trackSegmentEvent("file-upload:failed", { label: self.label });
      showToast(`${self.label} upload failed`, Intent.DANGER);
    },
  }))
  .actions(self => ({
    generateFileChunks(data: TUploadLinkResponse, disableAddToBlock?: boolean) {
      const { numParts, urlList, uploadId, id, type } = data;
      self.abortController = new AbortController();
      self.urlId = id;
      self.numParts = numParts;

      if (numParts === 1) {
        const handleProgress = (ev: AxiosProgressEvent) => {
          self.setUploadSize(ev.loaded);
          if (ev.loaded === self.file.size) {
            self.setStatus(EFileUploadStatus.FINALIZING);
          }
        };

        rollupClient.attachments
          .singleFileUrlUpload(
            urlList[0],
            self.file,
            id,
            type,
            self.workspaceId,
            self.abortController.signal,
            handleProgress,
            self.skipCadConversion
          )
          .then(data => self.callCompleteCallbacks(data, disableAddToBlock))
          .catch(err => {
            console.error("There was an error while uploading the file", err);
            self.setStatus(EFileUploadStatus.FAILED);
            appStore.orgModel.uploads.triggerNextUpload();
            showToast("File upload failed", Intent.DANGER);
          });
        return;
      }

      self.uploadId = uploadId;
      self.type = type || "";

      if (numParts > 1) {
        urlList.forEach((url, index) => {
          const newChunk: IFileChunkStore = self.chunks.put({
            id: uuid(),
            url,
            chunkNumber: index + 1,
            numParts,
            uploadStore: self.id,
          });
          newChunk.startUpload();
        });
      }
    },
    uploadComplete() {
      if (!self.failedChunks.length) {
        self.setStatus(EFileUploadStatus.FINALIZING);
        rollupClient.attachments
          .completeFileUploadByLinkId(
            self.urlId,
            {
              uploadId: self.uploadId,
              tags: self.eTags,
            },
            self.abortController.signal,
            self.workspaceId,
            self.skipCadConversion
          )
          .then(self.callCompleteCallbacks)
          .catch(self.callFailedCallbacks);
      } else {
        self.callFailedCallbacks();
      }
    },
  }))
  .actions(self => ({
    pauseUpload() {
      self.status = EFileUploadStatus.PAUSED;
    },
    resumeUpload() {
      self.pendingChunks.forEach(chunk => chunk.startUpload());
    },
    cancelUpload() {
      self.status = EFileUploadStatus.CANCELED;
      self.uploadingChunks.forEach(chunk => chunk.cancelUpload());
      self.abortController.abort();
      rollupClient.attachments.completeFileUploadByLinkId(
        self.urlId,
        {
          uploadId: self.uploadId,
          tags: [],
          success: false,
        },
        self.abortController.signal,
        self.workspaceId
      );
      appStore.orgModel.uploads.removeFileUploadStore(self as IUploadStore);
      appStore.orgModel.uploads.triggerNextUpload();
    },
    pendingUpload(file: File, onUpload?: (attachmentId: string, file: File, workspaceId?: string) => void) {
      self.status = EFileUploadStatus.PENDING;
      self.file = file;
      if (onUpload) {
        self.onUpload = onUpload;
      }
    },
    startUpload(disableAddToBlock?: boolean) {
      self.setStatus(EFileUploadStatus.INITIALIZING);
      rollupClient.attachments
        .getFileUploadLinks(
          {
            workspaceId: self.workspaceId || undefined,
            blockId: self.block?.id,
            label: self.label,
            attachmentId: self.attachmentId || undefined,
          },
          self.file
        )
        .then(r => {
          self.setStatus(EFileUploadStatus.UPLOADING);
          self.generateFileChunks(r, disableAddToBlock);
        })
        .catch(self.callFailedCallbacks);
    },
  }))
  .actions(self => ({
    retryUploadChunks() {
      if (appStore.orgModel.uploads.uploadQueueBusy) {
        self.status = EFileUploadStatus.PENDING;
      } else {
        self.status = EFileUploadStatus.INITIALIZING;
        if (!self.urlId) {
          self.startUpload();
          return;
        }
        if (self.failedChunks.length) {
          self.failedChunks.forEach(chunk => chunk.startUpload());
        } else {
          self.uploadComplete();
        }
      }
    },
  }))
  .views(self => ({
    get isFailed(): boolean {
      return self.status === EFileUploadStatus.FAILED;
    },
    get isLabelEditable(): boolean {
      return self.status === EFileUploadStatus.PENDING || !!self.urlId;
    },
    get allowCancel(): boolean {
      if (self.status === EFileUploadStatus.FAILED) {
        return true;
      }

      return (
        (self.status === EFileUploadStatus.INITIALIZING ||
          self.status === EFileUploadStatus.UPLOADING ||
          self.status === EFileUploadStatus.PAUSED) &&
        !!self.urlId
      );
    },
    get totalUploaded(): number {
      if (self.chunksArray.length) {
        return self.chunksArray.map(c => c.uploadedSize).reduce((a, b) => a + b, 0);
      } else {
        return self.uploadedSize;
      }
    },
    get progressPercentage(): number {
      return this.totalUploaded / self.file.size;
    },
    get progressString(): string {
      if (self.status !== EFileUploadStatus.UPLOADING) {
        return "";
      }

      return `${formatFileSize(this.totalUploaded)} of ${formatFileSize(self.file.size)}`;
    },
  }));

export interface IUploadStore extends Instance<typeof UploadStore> {}
interface IUploadStoreSnapshotIn extends SnapshotIn<typeof UploadStore> {}
interface IUploadStoreSnapshotOut extends SnapshotOut<typeof UploadStore> {}
export interface IUploadStoreMobxType extends IType<IUploadStoreSnapshotIn, IUploadStoreSnapshotOut, IUploadStore> {}
