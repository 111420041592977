import { EntityModelBase } from "../../shared/entityModelBase";

export enum RequirementValidationOperation {
  Equals = "equals",
  GreaterThan = "greater-than",
  LessThan = "less-than",
  GreaterThanOrEqualTo = "greater-than-or-equal-to",
  LessThanOrEqualTo = "less-than-or-equal-to",
  NotEqualTo = "not-equal-to",
}

export enum RequirementVerificationMethod {
  Inspection = "inspection",
  Simulation = "simulation",
  Analysis = "analysis",
  Demonstration = "demonstration",
  Test = "test",
  Sample = "sample",
}

export enum ManualVerificationStatus {
  Undefined = "undefined",
  Verified = "verified",
  NotVerified = "not-verified",
}

export enum RequirementBlockType {
  requirement = "requirement",
  h1 = "H1",
  h2 = "H2",
  h3 = "H3",
}

export enum FunctionalType {
  Functional = "Functional",
  NonFunctional = "Non-Functional",
  Performance = "Performance",
  Interface = "Interface",
  Operational = "Operational",
  Security = "Security",
  Safety = "Safety",
  Regulatory = "Regulatory",
  User = "User",
  System = "System",
  Design = "Design",
}

export enum SuccessCriteriaType {
  Automatic = "Automatic",
  Manual = "Manual",
}

export class RequirementBlock extends EntityModelBase {
  public readonly parentPage!: string;
  public readonly type!: RequirementBlockType;
  public readonly functionalType?: FunctionalType;
  public readonly successCriteria?: SuccessCriteriaType;
  public readonly visibleId?: string;
  public readonly staticId?: string;
  public readonly label?: string;
  public readonly note?: string;
  public readonly description?: string;
  public readonly icon?: string;
  public readonly manualVerification?: ManualVerificationStatus;
  public readonly validationOperation?: RequirementValidationOperation;
  public readonly verificationMethods?: RequirementVerificationMethod[];
  public readonly rationale?: string;
  public readonly imageUrl?: string;
  public readonly linkedProperty?: string;
  public readonly validationFormula?: string;
  public readonly isValid?: string;
  public readonly level?: number | null;
  public readonly unit?: string;
}
