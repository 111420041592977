import {
  CreateExecutionEnvironmentDto,
  ExecutionEnvironment,
  TestExecutionEnvironmentDto,
  TestExecutionResult,
  UpdateExecutionEnvironmentDto,
} from "@rollup-api/models/execution-environments";

import { HttpClient, ParentClient } from "../client";

export class ExecutionEnvironments extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private endpoint = "/execution-environments";

  public create = (dto: CreateExecutionEnvironmentDto) => {
    return this.instance.post<ExecutionEnvironment>(this.endpoint, dto);
  };

  public retrieve = (id: string) => {
    return this.instance.get<ExecutionEnvironment>(`${this.endpoint}/${id}`);
  };

  public retrieveList = () => {
    return this.instance.get<ExecutionEnvironment[]>(this.endpoint);
  };

  public update = (id: string, dto: UpdateExecutionEnvironmentDto) => {
    return this.instance.patch<ExecutionEnvironment>(`${this.endpoint}/${id}`, dto);
  };

  public delete = (id: string) => {
    return this.instance.delete<ExecutionEnvironment>(`${this.endpoint}/${id}`);
  };

  public test = (id: string, dto: TestExecutionEnvironmentDto) => {
    return this.instance.post<TestExecutionResult>(`${this.endpoint}/${id}/test`, dto);
  };
}
