import { useEffect, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { UserTooltip } from "@components/UserTooltip";
import { EmptyUserProfile, Profile } from "@rollup-api/models/profiles";
import appStore from "@store/AppStore";
import { IUser } from "@store/UserStore";
import { Text, TextVariant } from "src/ui/Text";

import Avatar from "../Avatar/Avatar";

import "./UserInfo.scss";

interface UserInfoProps {
  userId?: string;
  user?: IUser;
  userName?: string;
  className?: string;
  email?: string;
  jobTitle?: string;
  department?: string;
  avatarUrl?: string;
  // TODO replace with enum
  size?: "extra-small" | "small" | "large";
  shortenName?: boolean;
  disableTooltip?: boolean;
  avatarOnly?: boolean;
  nowrap?: boolean;
}

const UserInfo = ({
  userId,
  user,
  userName,
  email,
  jobTitle,
  department,
  avatarUrl,
  className,
  size = "small",
  shortenName,
  disableTooltip,
  avatarOnly,
  nowrap = true,
}: UserInfoProps) => {
  const [userProfile, setUserProfile] = useState<Profile>(EmptyUserProfile);

  // set the user and subject profiles
  useEffect(() => {
    if (userId) {
      appStore.orgModel?.info.getUserProfile(userId).then(userProfile => setUserProfile(userProfile ?? EmptyUserProfile));
    } else if (user) {
      setUserProfile({
        ...EmptyUserProfile,
        name: user.name ?? "",
        email: user.email ?? "",
        jobTitle: user.jobTitle ?? "",
        department: user.department ?? "",
        avatarUrl: user.avatarUrl ?? "",
      });
    } else {
      setUserProfile({
        ...EmptyUserProfile,
        name: userName ?? "",
        email: email ?? "",
        jobTitle: jobTitle ?? "",
        department: department ?? "",
        avatarUrl: avatarUrl ?? "",
      });
    }
  }, [userId, user, userName, email, jobTitle, department, avatarUrl]);

  const getName = (userName2?: string, email2?: string): string => {
    let name = userName2 ? userName2 : (email2 ?? "");
    if (shortenName) {
      if (userName2) {
        // get the first name and the first letter of last name using regex. userName can be up to 10 words.
        const reg = /^(?:\w+\s+){0,9}(\w+)\s+(\w{1})/g;
        const match = reg.exec(userName2);
        if (match) {
          name = `${match[1]} ${match[2]}.`;
        }
      } else if (email2) {
        //get the user name part of the email
        const reg = /^(\w+)@/g;
        const match = reg.exec(email2);
        if (match) {
          name = match[1];
        }
      }
    }
    return name;
  };

  return (
    <div className={classNames("user-info", { "user-info--avatar-only": avatarOnly }, className)}>
      {size === "extra-small" && (
        <UserTooltip user={user} disable={disableTooltip}>
          <div className="user-info-extra-small">
            <Avatar source={userProfile.avatarUrl} size={18} shape="circle" />
            {!avatarOnly && (
              <Text variant={TextVariant.Button2} clamp={1} nowrap={nowrap} disableTooltip>
                {getName(userProfile.name, userProfile.email)}
              </Text>
            )}
          </div>
        </UserTooltip>
      )}
      {size === "small" && (
        <UserTooltip user={user} disable={disableTooltip}>
          <div className="user-info-small">
            <Avatar source={userProfile.avatarUrl} size={20} shape="circle" />
            {!avatarOnly && (
              <Text variant={TextVariant.Body} clamp={1} nowrap={nowrap} disableTooltip>
                {getName(userProfile.name, userProfile.email)}
              </Text>
            )}
          </div>
        </UserTooltip>
      )}
      {size === "large" && (
        <UserTooltip user={user} disable={disableTooltip}>
          <div className="user-info-large">
            <Avatar source={userProfile.avatarUrl} size={60} shape="square" />
            {!avatarOnly && (
              <>
                <div>
                  <Text variant={TextVariant.H2} clamp={1} nowrap={nowrap} disableTooltip>
                    {getName(userProfile.name, userProfile.email)}
                  </Text>
                  <span>
                    <Text variant={TextVariant.Body} color="var(--text-caption)" nowrap={nowrap} disableTooltip>
                      {userProfile.jobTitle}
                    </Text>
                    {userProfile.jobTitle && userProfile.department && <span> • </span>}
                    <Text variant={TextVariant.Body} color="var(--text-caption)" nowrap={nowrap} disableTooltip>
                      {userProfile.department}
                    </Text>
                  </span>
                </div>
                {userProfile.name && (
                  <Text variant={TextVariant.Caption} nowrap={nowrap} disableTooltip>
                    {userProfile.email}
                  </Text>
                )}
              </>
            )}
          </div>
        </UserTooltip>
      )}
    </div>
  );
};

export default observer(UserInfo);
