import { useNavigate } from "react-router-dom";
import { Intent, Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { AnalysisType } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

import styles from "./CreateAnalysisButton.module.scss";

interface CreateAnalysisButtonProps {
  text: string;
}

const CreateAnalysisButton = ({ text }: CreateAnalysisButtonProps) => {
  const navigate = useNavigate();
  const workspace = useWorkspace();

  const handleNewAnalysis = async (analysisType: AnalysisType) => {
    const newDoc = await workspace.analysis.createAnalysis({ analysisType });
    if (newDoc) {
      appStore.env.setActiveAnalysis(newDoc.id);
      navigate(newDoc.id);
    }
  };

  if (appStore.env.featureFlags.enabled(FeatureFlag.SPREADSHEET_SUPPORT)) {
    return (
      <Popover
        position="bottom-right"
        content={
          <Menu>
            <MenuItem
              icon="code"
              text="Code block"
              onClick={() => handleNewAnalysis(AnalysisType.CodeBlock)}
              e2eIdentifiers="create-code-block"
            />
            <MenuItem
              icon="code"
              text="Spreadsheet"
              onClick={() => handleNewAnalysis(AnalysisType.Spreadsheet)}
              e2eIdentifiers="create-spreadsheet"
            />
          </Menu>
        }
      >
        <Button
          fill
          intent={Intent.PRIMARY}
          icon="small-plus"
          text={text}
          className={styles.createAnalysisButton}
          e2eIdentifiers="create-analysis"
        />
      </Popover>
    );
  } else {
    return (
      <Button
        fill
        intent={Intent.PRIMARY}
        icon="small-plus"
        text={text}
        className={styles.createAnalysisButton}
        onClick={() => handleNewAnalysis(AnalysisType.CodeBlock)}
        e2eIdentifiers="create-code-block"
      />
    );
  }
};

export default observer(CreateAnalysisButton);
